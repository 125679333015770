import React, {useState} from 'react';
import {Logo} from "../../components-base/Logo";
import {Input} from "../../components-base/Input";
import {useNavigate} from "react-router-dom";
import {Button} from "../../components-base/Button";
import {BUTTON_TYPES} from "../../components-base/Button/interfaces";

export const Login: React.FC = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const login = () => {
        debugger
        if (username === 'admin' && password === '12345') {
            localStorage.setItem('storage-auth-status', 'auth');
            window.location.reload();
        }
    };

    return (
        <div className={'login-container'}>
            <div onClick={() => navigate('/')}>
                <Logo />
                <h3>Fruits Management</h3>
            </div>
            <div className="login-form">
                <Input placeholder={'Username'} className={'login-input'} value={username} handlerChange={setUsername}/>
                <Input placeholder={'Password'} className={'login-input'} value={password} handlerChange={setPassword}/>
                <Button name={'Log in'} handlerClick={login} type={BUTTON_TYPES.PRIMARY}/>
            </div>
        </div>
    );
};
