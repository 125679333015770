import React from 'react';

interface IIconProps {
    handlerClick: () => void;
}

export const IconFilterDelete: React.FC<IIconProps> = ({ handlerClick }) => {
    return (
        <div className={'app-icon-container'} onClick={() => handlerClick()}>
            <svg className={'app-icon'} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="20" height="20" rx="10" fill="#3972E1" fillOpacity="0.05"/>
                <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#3972E1" strokeOpacity="0.05"/>
                <path d="M7 7L10 10M13 13L10 10M10 10L13 7L7 13" stroke="#3972E1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
    );
};
