import React, {ReactNode, useMemo, useState} from "react";
import {IconSortTop} from "../../components-base/Icons/IconSortTop";
import {IconSortBottom} from "../../components-base/Icons/IconSortBottom";
import {SearchInput} from "../../components-base/SearchInput";
import {Checkbox} from "../../components-base/Checkbox";
import {FILTER_PANEL_ITEM_TYPES} from "../../interfaces";
import {FILTERS} from "./index";

interface IFilterPanelItemProps {
    title: string;
    type: FILTER_PANEL_ITEM_TYPES;
    options?: IFilterPanelItemOption[];
    name: FILTERS,
    values: string[],
    handlerChange: (values: string[]) => void,
    withSearch?: boolean;
    checked?: number; // For test
}

export interface IFilterPanelItemOption {
    key: string | number;
    label: string | ReactNode;
}

// interface IFilterPanelItemListProps {
//     withSearch?: boolean;
//     options: IFilterPanelItemOption[];
// }

export const FilterPanelItem: React.FC<IFilterPanelItemProps> = (
    {
        title,
        options= [],
        checked,
        values,
        handlerChange,
        withSearch,
        type,
    }) => {
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [search, setSearch] = useState<string>('');
    const filteredOptions = useMemo(() => {
        if (search.length > 0) {
            return options.filter((option) => String(option?.key)?.indexOf(search) !== -1)
        }
        return options;
    }, [search, options])

    return (
        <div className="filter-panel-item">
            <div className="filter-panel-item-box" onClick={() => setIsOpen(!isOpen)}>
                <div className={'filter-panel-item-title'} >
                    <span>{title}</span>
                    {Boolean(checked) && <span className={'count-box'}>{checked}</span>}
                </div>
                <div className={'filter-panel-item-controls'} >
                    {/*<div>*/}
                    {/*    {checked && <span>Clear</span>}*/}
                    {/*</div>*/}
                    <div>
                        {isOpen
                            ? <IconSortTop opacity={1} />
                            : <IconSortBottom  opacity={1} />
                        }
                    </div>
                </div>
            </div>
            <div className={`filter-panel-item-options transition ${isOpen && 'open'} ${type}`}>
                {withSearch && <SearchInput handlerChange={setSearch} value={search}/>}
                {filteredOptions.map((option) => <Checkbox label={option.label}
                                                   key={option.key}
                                                   checked={values.includes(String(option.key))}
                                                   handlerChange={() => handlerChange(!values.includes(String(option.key))
                                                       ? [...values, String(option.key)]
                                                       : values.filter((e) => e !== String(option.key)))
                                                   }
                />)}
            </div>
        </div>
    );
};

// const List: React.FC<IFilterPanelItemListProps> = ({ withSearch, options }) => {
//     return (
//         <>
//             {withSearch && <SearchInput />}
//             {options.map((option) => <Checkbox label={option.label} key={option.key}/>)}
//         </>
//     );
// };
//
// const DateRange: React.FC = () => {
//     return (
//         <>
//             <div className="date-buttons-container">
//                 <div className="date-buttons">
//                     <Button name={'Last year'} type={BUTTON_TYPES.PRIMARY}/>
//                     <Button name={'Last 6 months'} />
//                     <Button name={'Last 3 months'} />
//                 </div>
//             </div>
//             <div className="date-inputs">
//                 <Input icon={<IconCalendar />} placeholder={'From'}/>
//                 <Input icon={<IconCalendar />} placeholder={'To'}/>
//             </div>
//         </>
//     );
// };
