import React, {useId, useRef, useState} from 'react';
import {IconFilter} from "../Icons/IconFilter";
import {IconArrowDown} from "../Icons/IconArrowDown";
import {Checkbox} from "../Checkbox";
import {ITableFilterOption} from "../../interfaces";
import {SearchInput} from "../SearchInput";
import useOutsideClick from "../../hooks/useOnClickOutside";

interface ITableFilterProps {
    options?: ITableFilterOption[];
    handlerChange: (values: any) => void;
    values: string[];
}

export const TableFilter: React.FC<ITableFilterProps> = ({ options, values, handlerChange }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const ref = useRef(null);
    const id = useId();

    useOutsideClick(ref, (event) => {
        const target = event.target as HTMLElement;
        const container = document.getElementById(id);
        const isChild = container?.contains(target);

        if (target && !isChild) {
            setIsOpen(false);
        }
    });

    return (
        <div className={'table-filter-container'} ref={ref} id={id}>
            <div className="table-filter-box" onClick={() => setIsOpen(!isOpen)}>
                <IconFilter/>
                <span className={'table-filter-title'}>Filter</span>
                <IconArrowDown color={'3C3C3C'} style={{ opacity: '0.7' }}/>
            </div>
            {isOpen && (
                <div className={'table-filter-options-container'}>
                    <SearchInput handlerChange={setSearch} value={search}/>
                    <div className="table-filter-options">
                        {options?.filter((option) => search ? String(option.id).indexOf(search) !== -1 : true )
                                 .map((option) => (
                                    <div className="table-filter-option">
                                        <Checkbox label={option.label}
                                                  handlerChange={() => handlerChange(!values.includes(String(option.id))
                                                    ? [...values, String(option.id)]
                                                    : values.filter((e) => e !== String(option.id)))
                                                  }
                                                  checked={values.includes(option.id)}
                                        />
                                    </div>
                                ))}
                    </div>
                </div>
            )}
        </div>
    );
};
