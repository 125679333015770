import React, {useState} from "react";
import {SearchInput} from "../../components-base/SearchInput";
import {FilterPanelItem, IFilterPanelItemOption} from "./FilterPanelItem";
import {FILTER_PANEL_ITEM_TYPES, ITableFilterOption, ITableFilterValue} from "../../interfaces";

interface IFilterPanelProps {
    isActive: boolean;
    setIsActive: (status: boolean) => void;
    filters: ITableFilterValue,
    changeFilter: (name: string, value: any) => void,
    gameTypes: ITableFilterOption[];
    gameBrands: ITableFilterOption[];
}

export enum FILTERS {
    TAGS = 'tags',
    BRANDS = 'brands',
    NAME = 'name',
    ID = 'id',
}

export const FilterPanel: React.FC<IFilterPanelProps> = ({ isActive, setIsActive, filters, changeFilter, gameBrands, gameTypes }) => {

    const types: IFilterPanelItemOption[] = gameTypes.map((type) => ({ key: type.id, label: type.label }));
    const brands: IFilterPanelItemOption[] = gameBrands.map((type) => ({ key: type.id, label: type.label }));
    const [search, setSearch] = useState<string>('');

    const globalFilter = (item: IFilterPanelItemOption) => search ? String(item.key).indexOf(search) !== -1 : true;

    return (
        <div className={`filter-panel-container transition ${isActive && 'open'}`}>
            <div className="filter-panel-header">
                <h4>Filter</h4>
                <SearchInput placeholder={'Search property'} handlerChange={setSearch} value={search}/>
            </div>
            <div className="filter-panel-content">
                <FilterPanelItem type={FILTER_PANEL_ITEM_TYPES.LIST}
                                 title={'Types'}
                                 values={filters[FILTERS.TAGS]}
                                 handlerChange={(values: string[]) => changeFilter(FILTERS.TAGS, values)}
                                 options={types.filter(globalFilter)}
                                 name={FILTERS.TAGS}
                                 checked={filters[FILTERS.TAGS]?.length}
                                 withSearch
                />
                <FilterPanelItem type={FILTER_PANEL_ITEM_TYPES.LIST}
                                 title={'Brands'}
                                 options={brands.filter(globalFilter)}
                                 handlerChange={(values: string[]) => changeFilter(FILTERS.BRANDS, values)}
                                 values={filters[FILTERS.BRANDS]}
                                 checked={filters[FILTERS.BRANDS]?.length}
                                 name={FILTERS.BRANDS}
                />

                {/*<FilterPanelItem type={FILTER_PANEL_ITEM_TYPES.LIST} title={'Status'} options={[*/}
                {/*    { key: 1, label: <Badge type={BADGE_TYPES.RELEASED} label={'Released'} />},*/}
                {/*    { key: 2, label: <Badge type={BADGE_TYPES.READY} label={'Ready'} />},*/}
                {/*    { key: 3, label: <Badge type={BADGE_TYPES.TEST} label={'Test'} />},*/}
                {/*    { key: 4, label: <Badge type={BADGE_TYPES.IN_PROGRESS} label={'In progress'} />},*/}
                {/*    { key: 5, label: <Badge type={BADGE_TYPES.REMOVED} label={'Removed'} />},*/}
                {/*    { key: 6, label: <Badge type={BADGE_TYPES.BACKLOG} label={'Backlog'} />},*/}
                {/*]}/>*/}
                {/*<FilterPanelItem type={FILTER_PANEL_ITEM_TYPES.DATE}  title={'Release date'}/>*/}
            </div>
            {/*<div className="filter-panel-footer">*/}
            {/*    <Button name={'Apply filter'} type={BUTTON_TYPES.PRIMARY}/>*/}
            {/*    <Button name={'Clear filter'} type={BUTTON_TYPES.DEFAULT}/>*/}
            {/*</div>*/}
        </div>
    );
};