import React from 'react';
import {BUTTON_TYPES} from "./interfaces";
import {IconButtonPlus} from "../Icons/IconButtonPlus";

interface IButtonProps {
    name: string;
    type?: BUTTON_TYPES;
    handlerClick?: () => void;
}

export const Button: React.FC<IButtonProps> = (
    {
        name,
        type = BUTTON_TYPES.DEFAULT,
        handlerClick
    }) => {

    const onClick = handlerClick ? handlerClick : () => console.log('click');
    return (
        <button className={`app-button ${type}`} onClick={onClick}>
            {type === BUTTON_TYPES.ADD && <IconButtonPlus/>}
            <span>{name}</span>
        </button>
    );
};
