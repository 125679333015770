import React from 'react';
import {IconArrowDown} from "../Icons/IconArrowDown";

interface ISelectProps {
    title: string;
}

export const Select: React.FC<ISelectProps> = ({ title }) => {
    return (
        <div className="app-select">
            <div className="app-select-box">
                <span>{title}</span>
                <IconArrowDown color={'3C3C3C'} />
            </div>
        </div>
    );
}