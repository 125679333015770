/*eslint-disable*/
(function(global, factory) { /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd)
        define(["protobufjs/minimal"], factory);

    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require("protobufjs/minimal"));

})(this, function($protobuf) {
    "use strict";
$protobuf.util.Long = require('long');
$protobuf.configure();

    // Common aliases
    var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;
    
    // Exported root namespace
    var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});
    
    $root.openGameApi = (function() {
    
        /**
         * Namespace openGameApi.
         * @exports openGameApi
         * @namespace
         */
        var openGameApi = {};
    
        /**
         * OpenGameApiProviderState enum.
         * @name openGameApi.OpenGameApiProviderState
         * @enum {number}
         * @property {number} TESTING=0 TESTING value
         * @property {number} IN_PRODUCTION=1 IN_PRODUCTION value
         * @property {number} BLOCKED=2 BLOCKED value
         */
        openGameApi.OpenGameApiProviderState = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "TESTING"] = 0;
            values[valuesById[1] = "IN_PRODUCTION"] = 1;
            values[valuesById[2] = "BLOCKED"] = 2;
            return values;
        })();
    
        openGameApi.ListProvidersRoyalties = (function() {
    
            /**
             * Properties of a ListProvidersRoyalties.
             * @memberof openGameApi
             * @interface IListProvidersRoyalties
             */
    
            /**
             * Constructs a new ListProvidersRoyalties.
             * @memberof openGameApi
             * @classdesc Represents a ListProvidersRoyalties.
             * @implements IListProvidersRoyalties
             * @constructor
             * @param {openGameApi.IListProvidersRoyalties=} [properties] Properties to set
             */
            function ListProvidersRoyalties(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new ListProvidersRoyalties instance using the specified properties.
             * @function create
             * @memberof openGameApi.ListProvidersRoyalties
             * @static
             * @param {openGameApi.IListProvidersRoyalties=} [properties] Properties to set
             * @returns {openGameApi.ListProvidersRoyalties} ListProvidersRoyalties instance
             */
            ListProvidersRoyalties.create = function create(properties) {
                return new ListProvidersRoyalties(properties);
            };
    
            /**
             * Encodes the specified ListProvidersRoyalties message. Does not implicitly {@link openGameApi.ListProvidersRoyalties.verify|verify} messages.
             * @function encode
             * @memberof openGameApi.ListProvidersRoyalties
             * @static
             * @param {openGameApi.IListProvidersRoyalties} message ListProvidersRoyalties message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListProvidersRoyalties.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified ListProvidersRoyalties message, length delimited. Does not implicitly {@link openGameApi.ListProvidersRoyalties.verify|verify} messages.
             * @function encodeDelimited
             * @memberof openGameApi.ListProvidersRoyalties
             * @static
             * @param {openGameApi.IListProvidersRoyalties} message ListProvidersRoyalties message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListProvidersRoyalties.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ListProvidersRoyalties message from the specified reader or buffer.
             * @function decode
             * @memberof openGameApi.ListProvidersRoyalties
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {openGameApi.ListProvidersRoyalties} ListProvidersRoyalties
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListProvidersRoyalties.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.ListProvidersRoyalties();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ListProvidersRoyalties message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof openGameApi.ListProvidersRoyalties
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {openGameApi.ListProvidersRoyalties} ListProvidersRoyalties
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListProvidersRoyalties.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ListProvidersRoyalties message.
             * @function verify
             * @memberof openGameApi.ListProvidersRoyalties
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ListProvidersRoyalties.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a ListProvidersRoyalties message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof openGameApi.ListProvidersRoyalties
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {openGameApi.ListProvidersRoyalties} ListProvidersRoyalties
             */
            ListProvidersRoyalties.fromObject = function fromObject(object) {
                if (object instanceof $root.openGameApi.ListProvidersRoyalties)
                    return object;
                return new $root.openGameApi.ListProvidersRoyalties();
            };
    
            /**
             * Creates a plain object from a ListProvidersRoyalties message. Also converts values to other types if specified.
             * @function toObject
             * @memberof openGameApi.ListProvidersRoyalties
             * @static
             * @param {openGameApi.ListProvidersRoyalties} message ListProvidersRoyalties
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListProvidersRoyalties.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this ListProvidersRoyalties to JSON.
             * @function toJSON
             * @memberof openGameApi.ListProvidersRoyalties
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListProvidersRoyalties.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            ListProvidersRoyalties.Req = (function() {
    
                /**
                 * Properties of a Req.
                 * @memberof openGameApi.ListProvidersRoyalties
                 * @interface IReq
                 */
    
                /**
                 * Constructs a new Req.
                 * @memberof openGameApi.ListProvidersRoyalties
                 * @classdesc Represents a Req.
                 * @implements IReq
                 * @constructor
                 * @param {openGameApi.ListProvidersRoyalties.IReq=} [properties] Properties to set
                 */
                function Req(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new Req instance using the specified properties.
                 * @function create
                 * @memberof openGameApi.ListProvidersRoyalties.Req
                 * @static
                 * @param {openGameApi.ListProvidersRoyalties.IReq=} [properties] Properties to set
                 * @returns {openGameApi.ListProvidersRoyalties.Req} Req instance
                 */
                Req.create = function create(properties) {
                    return new Req(properties);
                };
    
                /**
                 * Encodes the specified Req message. Does not implicitly {@link openGameApi.ListProvidersRoyalties.Req.verify|verify} messages.
                 * @function encode
                 * @memberof openGameApi.ListProvidersRoyalties.Req
                 * @static
                 * @param {openGameApi.ListProvidersRoyalties.IReq} message Req message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Req.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified Req message, length delimited. Does not implicitly {@link openGameApi.ListProvidersRoyalties.Req.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof openGameApi.ListProvidersRoyalties.Req
                 * @static
                 * @param {openGameApi.ListProvidersRoyalties.IReq} message Req message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Req.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Req message from the specified reader or buffer.
                 * @function decode
                 * @memberof openGameApi.ListProvidersRoyalties.Req
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {openGameApi.ListProvidersRoyalties.Req} Req
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Req.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.ListProvidersRoyalties.Req();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Req message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof openGameApi.ListProvidersRoyalties.Req
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {openGameApi.ListProvidersRoyalties.Req} Req
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Req.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Req message.
                 * @function verify
                 * @memberof openGameApi.ListProvidersRoyalties.Req
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Req.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a Req message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof openGameApi.ListProvidersRoyalties.Req
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {openGameApi.ListProvidersRoyalties.Req} Req
                 */
                Req.fromObject = function fromObject(object) {
                    if (object instanceof $root.openGameApi.ListProvidersRoyalties.Req)
                        return object;
                    return new $root.openGameApi.ListProvidersRoyalties.Req();
                };
    
                /**
                 * Creates a plain object from a Req message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof openGameApi.ListProvidersRoyalties.Req
                 * @static
                 * @param {openGameApi.ListProvidersRoyalties.Req} message Req
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Req.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this Req to JSON.
                 * @function toJSON
                 * @memberof openGameApi.ListProvidersRoyalties.Req
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Req.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Req;
            })();
    
            ListProvidersRoyalties.Resp = (function() {
    
                /**
                 * Properties of a Resp.
                 * @memberof openGameApi.ListProvidersRoyalties
                 * @interface IResp
                 * @property {Array.<openGameApi.ListProvidersRoyalties.Resp.IRoyaltyData>|null} [royalties] Resp royalties
                 */
    
                /**
                 * Constructs a new Resp.
                 * @memberof openGameApi.ListProvidersRoyalties
                 * @classdesc Represents a Resp.
                 * @implements IResp
                 * @constructor
                 * @param {openGameApi.ListProvidersRoyalties.IResp=} [properties] Properties to set
                 */
                function Resp(properties) {
                    this.royalties = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Resp royalties.
                 * @member {Array.<openGameApi.ListProvidersRoyalties.Resp.IRoyaltyData>} royalties
                 * @memberof openGameApi.ListProvidersRoyalties.Resp
                 * @instance
                 */
                Resp.prototype.royalties = $util.emptyArray;
    
                /**
                 * Creates a new Resp instance using the specified properties.
                 * @function create
                 * @memberof openGameApi.ListProvidersRoyalties.Resp
                 * @static
                 * @param {openGameApi.ListProvidersRoyalties.IResp=} [properties] Properties to set
                 * @returns {openGameApi.ListProvidersRoyalties.Resp} Resp instance
                 */
                Resp.create = function create(properties) {
                    return new Resp(properties);
                };
    
                /**
                 * Encodes the specified Resp message. Does not implicitly {@link openGameApi.ListProvidersRoyalties.Resp.verify|verify} messages.
                 * @function encode
                 * @memberof openGameApi.ListProvidersRoyalties.Resp
                 * @static
                 * @param {openGameApi.ListProvidersRoyalties.IResp} message Resp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Resp.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.royalties != null && message.royalties.length)
                        for (var i = 0; i < message.royalties.length; ++i)
                            $root.openGameApi.ListProvidersRoyalties.Resp.RoyaltyData.encode(message.royalties[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Resp message, length delimited. Does not implicitly {@link openGameApi.ListProvidersRoyalties.Resp.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof openGameApi.ListProvidersRoyalties.Resp
                 * @static
                 * @param {openGameApi.ListProvidersRoyalties.IResp} message Resp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Resp.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Resp message from the specified reader or buffer.
                 * @function decode
                 * @memberof openGameApi.ListProvidersRoyalties.Resp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {openGameApi.ListProvidersRoyalties.Resp} Resp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Resp.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.ListProvidersRoyalties.Resp();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.royalties && message.royalties.length))
                                message.royalties = [];
                            message.royalties.push($root.openGameApi.ListProvidersRoyalties.Resp.RoyaltyData.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Resp message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof openGameApi.ListProvidersRoyalties.Resp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {openGameApi.ListProvidersRoyalties.Resp} Resp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Resp.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Resp message.
                 * @function verify
                 * @memberof openGameApi.ListProvidersRoyalties.Resp
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Resp.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.royalties != null && message.hasOwnProperty("royalties")) {
                        if (!Array.isArray(message.royalties))
                            return "royalties: array expected";
                        for (var i = 0; i < message.royalties.length; ++i) {
                            var error = $root.openGameApi.ListProvidersRoyalties.Resp.RoyaltyData.verify(message.royalties[i]);
                            if (error)
                                return "royalties." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a Resp message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof openGameApi.ListProvidersRoyalties.Resp
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {openGameApi.ListProvidersRoyalties.Resp} Resp
                 */
                Resp.fromObject = function fromObject(object) {
                    if (object instanceof $root.openGameApi.ListProvidersRoyalties.Resp)
                        return object;
                    var message = new $root.openGameApi.ListProvidersRoyalties.Resp();
                    if (object.royalties) {
                        if (!Array.isArray(object.royalties))
                            throw TypeError(".openGameApi.ListProvidersRoyalties.Resp.royalties: array expected");
                        message.royalties = [];
                        for (var i = 0; i < object.royalties.length; ++i) {
                            if (typeof object.royalties[i] !== "object")
                                throw TypeError(".openGameApi.ListProvidersRoyalties.Resp.royalties: object expected");
                            message.royalties[i] = $root.openGameApi.ListProvidersRoyalties.Resp.RoyaltyData.fromObject(object.royalties[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Resp message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof openGameApi.ListProvidersRoyalties.Resp
                 * @static
                 * @param {openGameApi.ListProvidersRoyalties.Resp} message Resp
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Resp.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.royalties = [];
                    if (message.royalties && message.royalties.length) {
                        object.royalties = [];
                        for (var j = 0; j < message.royalties.length; ++j)
                            object.royalties[j] = $root.openGameApi.ListProvidersRoyalties.Resp.RoyaltyData.toObject(message.royalties[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this Resp to JSON.
                 * @function toJSON
                 * @memberof openGameApi.ListProvidersRoyalties.Resp
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Resp.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                Resp.RoyaltyData = (function() {
    
                    /**
                     * Properties of a RoyaltyData.
                     * @memberof openGameApi.ListProvidersRoyalties.Resp
                     * @interface IRoyaltyData
                     * @property {string|null} [providerPrefix] RoyaltyData providerPrefix
                     * @property {number|null} [accountId] RoyaltyData accountId
                     * @property {number|null} [royaltyPercent] RoyaltyData royaltyPercent
                     */
    
                    /**
                     * Constructs a new RoyaltyData.
                     * @memberof openGameApi.ListProvidersRoyalties.Resp
                     * @classdesc Represents a RoyaltyData.
                     * @implements IRoyaltyData
                     * @constructor
                     * @param {openGameApi.ListProvidersRoyalties.Resp.IRoyaltyData=} [properties] Properties to set
                     */
                    function RoyaltyData(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * RoyaltyData providerPrefix.
                     * @member {string} providerPrefix
                     * @memberof openGameApi.ListProvidersRoyalties.Resp.RoyaltyData
                     * @instance
                     */
                    RoyaltyData.prototype.providerPrefix = "";
    
                    /**
                     * RoyaltyData accountId.
                     * @member {number} accountId
                     * @memberof openGameApi.ListProvidersRoyalties.Resp.RoyaltyData
                     * @instance
                     */
                    RoyaltyData.prototype.accountId = 0;
    
                    /**
                     * RoyaltyData royaltyPercent.
                     * @member {number} royaltyPercent
                     * @memberof openGameApi.ListProvidersRoyalties.Resp.RoyaltyData
                     * @instance
                     */
                    RoyaltyData.prototype.royaltyPercent = 0;
    
                    /**
                     * Creates a new RoyaltyData instance using the specified properties.
                     * @function create
                     * @memberof openGameApi.ListProvidersRoyalties.Resp.RoyaltyData
                     * @static
                     * @param {openGameApi.ListProvidersRoyalties.Resp.IRoyaltyData=} [properties] Properties to set
                     * @returns {openGameApi.ListProvidersRoyalties.Resp.RoyaltyData} RoyaltyData instance
                     */
                    RoyaltyData.create = function create(properties) {
                        return new RoyaltyData(properties);
                    };
    
                    /**
                     * Encodes the specified RoyaltyData message. Does not implicitly {@link openGameApi.ListProvidersRoyalties.Resp.RoyaltyData.verify|verify} messages.
                     * @function encode
                     * @memberof openGameApi.ListProvidersRoyalties.Resp.RoyaltyData
                     * @static
                     * @param {openGameApi.ListProvidersRoyalties.Resp.IRoyaltyData} message RoyaltyData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RoyaltyData.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.providerPrefix != null && Object.hasOwnProperty.call(message, "providerPrefix"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerPrefix);
                        if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.accountId);
                        if (message.royaltyPercent != null && Object.hasOwnProperty.call(message, "royaltyPercent"))
                            writer.uint32(/* id 3, wireType 0 =*/24).uint32(message.royaltyPercent);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified RoyaltyData message, length delimited. Does not implicitly {@link openGameApi.ListProvidersRoyalties.Resp.RoyaltyData.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof openGameApi.ListProvidersRoyalties.Resp.RoyaltyData
                     * @static
                     * @param {openGameApi.ListProvidersRoyalties.Resp.IRoyaltyData} message RoyaltyData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RoyaltyData.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a RoyaltyData message from the specified reader or buffer.
                     * @function decode
                     * @memberof openGameApi.ListProvidersRoyalties.Resp.RoyaltyData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {openGameApi.ListProvidersRoyalties.Resp.RoyaltyData} RoyaltyData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RoyaltyData.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.ListProvidersRoyalties.Resp.RoyaltyData();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.providerPrefix = reader.string();
                                break;
                            case 2:
                                message.accountId = reader.uint32();
                                break;
                            case 3:
                                message.royaltyPercent = reader.uint32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a RoyaltyData message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof openGameApi.ListProvidersRoyalties.Resp.RoyaltyData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {openGameApi.ListProvidersRoyalties.Resp.RoyaltyData} RoyaltyData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RoyaltyData.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a RoyaltyData message.
                     * @function verify
                     * @memberof openGameApi.ListProvidersRoyalties.Resp.RoyaltyData
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RoyaltyData.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.providerPrefix != null && message.hasOwnProperty("providerPrefix"))
                            if (!$util.isString(message.providerPrefix))
                                return "providerPrefix: string expected";
                        if (message.accountId != null && message.hasOwnProperty("accountId"))
                            if (!$util.isInteger(message.accountId))
                                return "accountId: integer expected";
                        if (message.royaltyPercent != null && message.hasOwnProperty("royaltyPercent"))
                            if (!$util.isInteger(message.royaltyPercent))
                                return "royaltyPercent: integer expected";
                        return null;
                    };
    
                    /**
                     * Creates a RoyaltyData message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof openGameApi.ListProvidersRoyalties.Resp.RoyaltyData
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {openGameApi.ListProvidersRoyalties.Resp.RoyaltyData} RoyaltyData
                     */
                    RoyaltyData.fromObject = function fromObject(object) {
                        if (object instanceof $root.openGameApi.ListProvidersRoyalties.Resp.RoyaltyData)
                            return object;
                        var message = new $root.openGameApi.ListProvidersRoyalties.Resp.RoyaltyData();
                        if (object.providerPrefix != null)
                            message.providerPrefix = String(object.providerPrefix);
                        if (object.accountId != null)
                            message.accountId = object.accountId >>> 0;
                        if (object.royaltyPercent != null)
                            message.royaltyPercent = object.royaltyPercent >>> 0;
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a RoyaltyData message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof openGameApi.ListProvidersRoyalties.Resp.RoyaltyData
                     * @static
                     * @param {openGameApi.ListProvidersRoyalties.Resp.RoyaltyData} message RoyaltyData
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RoyaltyData.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.providerPrefix = "";
                            object.accountId = 0;
                            object.royaltyPercent = 0;
                        }
                        if (message.providerPrefix != null && message.hasOwnProperty("providerPrefix"))
                            object.providerPrefix = message.providerPrefix;
                        if (message.accountId != null && message.hasOwnProperty("accountId"))
                            object.accountId = message.accountId;
                        if (message.royaltyPercent != null && message.hasOwnProperty("royaltyPercent"))
                            object.royaltyPercent = message.royaltyPercent;
                        return object;
                    };
    
                    /**
                     * Converts this RoyaltyData to JSON.
                     * @function toJSON
                     * @memberof openGameApi.ListProvidersRoyalties.Resp.RoyaltyData
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RoyaltyData.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return RoyaltyData;
                })();
    
                return Resp;
            })();
    
            return ListProvidersRoyalties;
        })();
    
        openGameApi.ListProvidersData = (function() {
    
            /**
             * Properties of a ListProvidersData.
             * @memberof openGameApi
             * @interface IListProvidersData
             */
    
            /**
             * Constructs a new ListProvidersData.
             * @memberof openGameApi
             * @classdesc Represents a ListProvidersData.
             * @implements IListProvidersData
             * @constructor
             * @param {openGameApi.IListProvidersData=} [properties] Properties to set
             */
            function ListProvidersData(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new ListProvidersData instance using the specified properties.
             * @function create
             * @memberof openGameApi.ListProvidersData
             * @static
             * @param {openGameApi.IListProvidersData=} [properties] Properties to set
             * @returns {openGameApi.ListProvidersData} ListProvidersData instance
             */
            ListProvidersData.create = function create(properties) {
                return new ListProvidersData(properties);
            };
    
            /**
             * Encodes the specified ListProvidersData message. Does not implicitly {@link openGameApi.ListProvidersData.verify|verify} messages.
             * @function encode
             * @memberof openGameApi.ListProvidersData
             * @static
             * @param {openGameApi.IListProvidersData} message ListProvidersData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListProvidersData.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified ListProvidersData message, length delimited. Does not implicitly {@link openGameApi.ListProvidersData.verify|verify} messages.
             * @function encodeDelimited
             * @memberof openGameApi.ListProvidersData
             * @static
             * @param {openGameApi.IListProvidersData} message ListProvidersData message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ListProvidersData.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ListProvidersData message from the specified reader or buffer.
             * @function decode
             * @memberof openGameApi.ListProvidersData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {openGameApi.ListProvidersData} ListProvidersData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListProvidersData.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.ListProvidersData();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ListProvidersData message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof openGameApi.ListProvidersData
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {openGameApi.ListProvidersData} ListProvidersData
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ListProvidersData.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ListProvidersData message.
             * @function verify
             * @memberof openGameApi.ListProvidersData
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ListProvidersData.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a ListProvidersData message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof openGameApi.ListProvidersData
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {openGameApi.ListProvidersData} ListProvidersData
             */
            ListProvidersData.fromObject = function fromObject(object) {
                if (object instanceof $root.openGameApi.ListProvidersData)
                    return object;
                return new $root.openGameApi.ListProvidersData();
            };
    
            /**
             * Creates a plain object from a ListProvidersData message. Also converts values to other types if specified.
             * @function toObject
             * @memberof openGameApi.ListProvidersData
             * @static
             * @param {openGameApi.ListProvidersData} message ListProvidersData
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ListProvidersData.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this ListProvidersData to JSON.
             * @function toJSON
             * @memberof openGameApi.ListProvidersData
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ListProvidersData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            ListProvidersData.Req = (function() {
    
                /**
                 * Properties of a Req.
                 * @memberof openGameApi.ListProvidersData
                 * @interface IReq
                 */
    
                /**
                 * Constructs a new Req.
                 * @memberof openGameApi.ListProvidersData
                 * @classdesc Represents a Req.
                 * @implements IReq
                 * @constructor
                 * @param {openGameApi.ListProvidersData.IReq=} [properties] Properties to set
                 */
                function Req(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Creates a new Req instance using the specified properties.
                 * @function create
                 * @memberof openGameApi.ListProvidersData.Req
                 * @static
                 * @param {openGameApi.ListProvidersData.IReq=} [properties] Properties to set
                 * @returns {openGameApi.ListProvidersData.Req} Req instance
                 */
                Req.create = function create(properties) {
                    return new Req(properties);
                };
    
                /**
                 * Encodes the specified Req message. Does not implicitly {@link openGameApi.ListProvidersData.Req.verify|verify} messages.
                 * @function encode
                 * @memberof openGameApi.ListProvidersData.Req
                 * @static
                 * @param {openGameApi.ListProvidersData.IReq} message Req message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Req.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };
    
                /**
                 * Encodes the specified Req message, length delimited. Does not implicitly {@link openGameApi.ListProvidersData.Req.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof openGameApi.ListProvidersData.Req
                 * @static
                 * @param {openGameApi.ListProvidersData.IReq} message Req message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Req.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Req message from the specified reader or buffer.
                 * @function decode
                 * @memberof openGameApi.ListProvidersData.Req
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {openGameApi.ListProvidersData.Req} Req
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Req.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.ListProvidersData.Req();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Req message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof openGameApi.ListProvidersData.Req
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {openGameApi.ListProvidersData.Req} Req
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Req.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Req message.
                 * @function verify
                 * @memberof openGameApi.ListProvidersData.Req
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Req.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };
    
                /**
                 * Creates a Req message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof openGameApi.ListProvidersData.Req
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {openGameApi.ListProvidersData.Req} Req
                 */
                Req.fromObject = function fromObject(object) {
                    if (object instanceof $root.openGameApi.ListProvidersData.Req)
                        return object;
                    return new $root.openGameApi.ListProvidersData.Req();
                };
    
                /**
                 * Creates a plain object from a Req message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof openGameApi.ListProvidersData.Req
                 * @static
                 * @param {openGameApi.ListProvidersData.Req} message Req
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Req.toObject = function toObject() {
                    return {};
                };
    
                /**
                 * Converts this Req to JSON.
                 * @function toJSON
                 * @memberof openGameApi.ListProvidersData.Req
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Req.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Req;
            })();
    
            ListProvidersData.Resp = (function() {
    
                /**
                 * Properties of a Resp.
                 * @memberof openGameApi.ListProvidersData
                 * @interface IResp
                 * @property {Array.<openGameApi.ListProvidersData.Resp.IProviderData>|null} [providers] Resp providers
                 */
    
                /**
                 * Constructs a new Resp.
                 * @memberof openGameApi.ListProvidersData
                 * @classdesc Represents a Resp.
                 * @implements IResp
                 * @constructor
                 * @param {openGameApi.ListProvidersData.IResp=} [properties] Properties to set
                 */
                function Resp(properties) {
                    this.providers = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Resp providers.
                 * @member {Array.<openGameApi.ListProvidersData.Resp.IProviderData>} providers
                 * @memberof openGameApi.ListProvidersData.Resp
                 * @instance
                 */
                Resp.prototype.providers = $util.emptyArray;
    
                /**
                 * Creates a new Resp instance using the specified properties.
                 * @function create
                 * @memberof openGameApi.ListProvidersData.Resp
                 * @static
                 * @param {openGameApi.ListProvidersData.IResp=} [properties] Properties to set
                 * @returns {openGameApi.ListProvidersData.Resp} Resp instance
                 */
                Resp.create = function create(properties) {
                    return new Resp(properties);
                };
    
                /**
                 * Encodes the specified Resp message. Does not implicitly {@link openGameApi.ListProvidersData.Resp.verify|verify} messages.
                 * @function encode
                 * @memberof openGameApi.ListProvidersData.Resp
                 * @static
                 * @param {openGameApi.ListProvidersData.IResp} message Resp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Resp.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.providers != null && message.providers.length)
                        for (var i = 0; i < message.providers.length; ++i)
                            $root.openGameApi.ListProvidersData.Resp.ProviderData.encode(message.providers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Resp message, length delimited. Does not implicitly {@link openGameApi.ListProvidersData.Resp.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof openGameApi.ListProvidersData.Resp
                 * @static
                 * @param {openGameApi.ListProvidersData.IResp} message Resp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Resp.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Resp message from the specified reader or buffer.
                 * @function decode
                 * @memberof openGameApi.ListProvidersData.Resp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {openGameApi.ListProvidersData.Resp} Resp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Resp.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.ListProvidersData.Resp();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.providers && message.providers.length))
                                message.providers = [];
                            message.providers.push($root.openGameApi.ListProvidersData.Resp.ProviderData.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Resp message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof openGameApi.ListProvidersData.Resp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {openGameApi.ListProvidersData.Resp} Resp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Resp.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Resp message.
                 * @function verify
                 * @memberof openGameApi.ListProvidersData.Resp
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Resp.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.providers != null && message.hasOwnProperty("providers")) {
                        if (!Array.isArray(message.providers))
                            return "providers: array expected";
                        for (var i = 0; i < message.providers.length; ++i) {
                            var error = $root.openGameApi.ListProvidersData.Resp.ProviderData.verify(message.providers[i]);
                            if (error)
                                return "providers." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a Resp message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof openGameApi.ListProvidersData.Resp
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {openGameApi.ListProvidersData.Resp} Resp
                 */
                Resp.fromObject = function fromObject(object) {
                    if (object instanceof $root.openGameApi.ListProvidersData.Resp)
                        return object;
                    var message = new $root.openGameApi.ListProvidersData.Resp();
                    if (object.providers) {
                        if (!Array.isArray(object.providers))
                            throw TypeError(".openGameApi.ListProvidersData.Resp.providers: array expected");
                        message.providers = [];
                        for (var i = 0; i < object.providers.length; ++i) {
                            if (typeof object.providers[i] !== "object")
                                throw TypeError(".openGameApi.ListProvidersData.Resp.providers: object expected");
                            message.providers[i] = $root.openGameApi.ListProvidersData.Resp.ProviderData.fromObject(object.providers[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Resp message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof openGameApi.ListProvidersData.Resp
                 * @static
                 * @param {openGameApi.ListProvidersData.Resp} message Resp
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Resp.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.providers = [];
                    if (message.providers && message.providers.length) {
                        object.providers = [];
                        for (var j = 0; j < message.providers.length; ++j)
                            object.providers[j] = $root.openGameApi.ListProvidersData.Resp.ProviderData.toObject(message.providers[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this Resp to JSON.
                 * @function toJSON
                 * @memberof openGameApi.ListProvidersData.Resp
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Resp.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                Resp.PublicAccountData = (function() {
    
                    /**
                     * Properties of a PublicAccountData.
                     * @memberof openGameApi.ListProvidersData.Resp
                     * @interface IPublicAccountData
                     * @property {openGameApi.IProviderAccountSettings|null} [settings] PublicAccountData settings
                     * @property {Array.<openGameApi.IGame>|null} [games] PublicAccountData games
                     * @property {string|null} [comment] PublicAccountData comment
                     */
    
                    /**
                     * Constructs a new PublicAccountData.
                     * @memberof openGameApi.ListProvidersData.Resp
                     * @classdesc Represents a PublicAccountData.
                     * @implements IPublicAccountData
                     * @constructor
                     * @param {openGameApi.ListProvidersData.Resp.IPublicAccountData=} [properties] Properties to set
                     */
                    function PublicAccountData(properties) {
                        this.games = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * PublicAccountData settings.
                     * @member {openGameApi.IProviderAccountSettings|null|undefined} settings
                     * @memberof openGameApi.ListProvidersData.Resp.PublicAccountData
                     * @instance
                     */
                    PublicAccountData.prototype.settings = null;
    
                    /**
                     * PublicAccountData games.
                     * @member {Array.<openGameApi.IGame>} games
                     * @memberof openGameApi.ListProvidersData.Resp.PublicAccountData
                     * @instance
                     */
                    PublicAccountData.prototype.games = $util.emptyArray;
    
                    /**
                     * PublicAccountData comment.
                     * @member {string} comment
                     * @memberof openGameApi.ListProvidersData.Resp.PublicAccountData
                     * @instance
                     */
                    PublicAccountData.prototype.comment = "";
    
                    /**
                     * Creates a new PublicAccountData instance using the specified properties.
                     * @function create
                     * @memberof openGameApi.ListProvidersData.Resp.PublicAccountData
                     * @static
                     * @param {openGameApi.ListProvidersData.Resp.IPublicAccountData=} [properties] Properties to set
                     * @returns {openGameApi.ListProvidersData.Resp.PublicAccountData} PublicAccountData instance
                     */
                    PublicAccountData.create = function create(properties) {
                        return new PublicAccountData(properties);
                    };
    
                    /**
                     * Encodes the specified PublicAccountData message. Does not implicitly {@link openGameApi.ListProvidersData.Resp.PublicAccountData.verify|verify} messages.
                     * @function encode
                     * @memberof openGameApi.ListProvidersData.Resp.PublicAccountData
                     * @static
                     * @param {openGameApi.ListProvidersData.Resp.IPublicAccountData} message PublicAccountData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PublicAccountData.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.settings != null && Object.hasOwnProperty.call(message, "settings"))
                            $root.openGameApi.ProviderAccountSettings.encode(message.settings, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.games != null && message.games.length)
                            for (var i = 0; i < message.games.length; ++i)
                                $root.openGameApi.Game.encode(message.games[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.comment != null && Object.hasOwnProperty.call(message, "comment"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.comment);
                        return writer;
                    };
    
                    /**
                     * Encodes the specified PublicAccountData message, length delimited. Does not implicitly {@link openGameApi.ListProvidersData.Resp.PublicAccountData.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof openGameApi.ListProvidersData.Resp.PublicAccountData
                     * @static
                     * @param {openGameApi.ListProvidersData.Resp.IPublicAccountData} message PublicAccountData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PublicAccountData.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a PublicAccountData message from the specified reader or buffer.
                     * @function decode
                     * @memberof openGameApi.ListProvidersData.Resp.PublicAccountData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {openGameApi.ListProvidersData.Resp.PublicAccountData} PublicAccountData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PublicAccountData.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.ListProvidersData.Resp.PublicAccountData();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.settings = $root.openGameApi.ProviderAccountSettings.decode(reader, reader.uint32());
                                break;
                            case 2:
                                if (!(message.games && message.games.length))
                                    message.games = [];
                                message.games.push($root.openGameApi.Game.decode(reader, reader.uint32()));
                                break;
                            case 3:
                                message.comment = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a PublicAccountData message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof openGameApi.ListProvidersData.Resp.PublicAccountData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {openGameApi.ListProvidersData.Resp.PublicAccountData} PublicAccountData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PublicAccountData.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a PublicAccountData message.
                     * @function verify
                     * @memberof openGameApi.ListProvidersData.Resp.PublicAccountData
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PublicAccountData.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.settings != null && message.hasOwnProperty("settings")) {
                            var error = $root.openGameApi.ProviderAccountSettings.verify(message.settings);
                            if (error)
                                return "settings." + error;
                        }
                        if (message.games != null && message.hasOwnProperty("games")) {
                            if (!Array.isArray(message.games))
                                return "games: array expected";
                            for (var i = 0; i < message.games.length; ++i) {
                                var error = $root.openGameApi.Game.verify(message.games[i]);
                                if (error)
                                    return "games." + error;
                            }
                        }
                        if (message.comment != null && message.hasOwnProperty("comment"))
                            if (!$util.isString(message.comment))
                                return "comment: string expected";
                        return null;
                    };
    
                    /**
                     * Creates a PublicAccountData message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof openGameApi.ListProvidersData.Resp.PublicAccountData
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {openGameApi.ListProvidersData.Resp.PublicAccountData} PublicAccountData
                     */
                    PublicAccountData.fromObject = function fromObject(object) {
                        if (object instanceof $root.openGameApi.ListProvidersData.Resp.PublicAccountData)
                            return object;
                        var message = new $root.openGameApi.ListProvidersData.Resp.PublicAccountData();
                        if (object.settings != null) {
                            if (typeof object.settings !== "object")
                                throw TypeError(".openGameApi.ListProvidersData.Resp.PublicAccountData.settings: object expected");
                            message.settings = $root.openGameApi.ProviderAccountSettings.fromObject(object.settings);
                        }
                        if (object.games) {
                            if (!Array.isArray(object.games))
                                throw TypeError(".openGameApi.ListProvidersData.Resp.PublicAccountData.games: array expected");
                            message.games = [];
                            for (var i = 0; i < object.games.length; ++i) {
                                if (typeof object.games[i] !== "object")
                                    throw TypeError(".openGameApi.ListProvidersData.Resp.PublicAccountData.games: object expected");
                                message.games[i] = $root.openGameApi.Game.fromObject(object.games[i]);
                            }
                        }
                        if (object.comment != null)
                            message.comment = String(object.comment);
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a PublicAccountData message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof openGameApi.ListProvidersData.Resp.PublicAccountData
                     * @static
                     * @param {openGameApi.ListProvidersData.Resp.PublicAccountData} message PublicAccountData
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PublicAccountData.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.games = [];
                        if (options.defaults) {
                            object.settings = null;
                            object.comment = "";
                        }
                        if (message.settings != null && message.hasOwnProperty("settings"))
                            object.settings = $root.openGameApi.ProviderAccountSettings.toObject(message.settings, options);
                        if (message.games && message.games.length) {
                            object.games = [];
                            for (var j = 0; j < message.games.length; ++j)
                                object.games[j] = $root.openGameApi.Game.toObject(message.games[j], options);
                        }
                        if (message.comment != null && message.hasOwnProperty("comment"))
                            object.comment = message.comment;
                        return object;
                    };
    
                    /**
                     * Converts this PublicAccountData to JSON.
                     * @function toJSON
                     * @memberof openGameApi.ListProvidersData.Resp.PublicAccountData
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PublicAccountData.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return PublicAccountData;
                })();
    
                Resp.ProviderData = (function() {
    
                    /**
                     * Properties of a ProviderData.
                     * @memberof openGameApi.ListProvidersData.Resp
                     * @interface IProviderData
                     * @property {string|null} [providerId] ProviderData providerId
                     * @property {string|null} [providerPrefix] ProviderData providerPrefix
                     * @property {string|null} [providerName] ProviderData providerName
                     * @property {string|null} [providerSecret] ProviderData providerSecret
                     * @property {boolean|null} [isTesting] ProviderData isTesting
                     * @property {Array.<openGameApi.ListProvidersData.Resp.IPublicAccountData>|null} [accounts] ProviderData accounts
                     */
    
                    /**
                     * Constructs a new ProviderData.
                     * @memberof openGameApi.ListProvidersData.Resp
                     * @classdesc Represents a ProviderData.
                     * @implements IProviderData
                     * @constructor
                     * @param {openGameApi.ListProvidersData.Resp.IProviderData=} [properties] Properties to set
                     */
                    function ProviderData(properties) {
                        this.accounts = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * ProviderData providerId.
                     * @member {string} providerId
                     * @memberof openGameApi.ListProvidersData.Resp.ProviderData
                     * @instance
                     */
                    ProviderData.prototype.providerId = "";
    
                    /**
                     * ProviderData providerPrefix.
                     * @member {string} providerPrefix
                     * @memberof openGameApi.ListProvidersData.Resp.ProviderData
                     * @instance
                     */
                    ProviderData.prototype.providerPrefix = "";
    
                    /**
                     * ProviderData providerName.
                     * @member {string} providerName
                     * @memberof openGameApi.ListProvidersData.Resp.ProviderData
                     * @instance
                     */
                    ProviderData.prototype.providerName = "";
    
                    /**
                     * ProviderData providerSecret.
                     * @member {string} providerSecret
                     * @memberof openGameApi.ListProvidersData.Resp.ProviderData
                     * @instance
                     */
                    ProviderData.prototype.providerSecret = "";
    
                    /**
                     * ProviderData isTesting.
                     * @member {boolean} isTesting
                     * @memberof openGameApi.ListProvidersData.Resp.ProviderData
                     * @instance
                     */
                    ProviderData.prototype.isTesting = false;
    
                    /**
                     * ProviderData accounts.
                     * @member {Array.<openGameApi.ListProvidersData.Resp.IPublicAccountData>} accounts
                     * @memberof openGameApi.ListProvidersData.Resp.ProviderData
                     * @instance
                     */
                    ProviderData.prototype.accounts = $util.emptyArray;
    
                    /**
                     * Creates a new ProviderData instance using the specified properties.
                     * @function create
                     * @memberof openGameApi.ListProvidersData.Resp.ProviderData
                     * @static
                     * @param {openGameApi.ListProvidersData.Resp.IProviderData=} [properties] Properties to set
                     * @returns {openGameApi.ListProvidersData.Resp.ProviderData} ProviderData instance
                     */
                    ProviderData.create = function create(properties) {
                        return new ProviderData(properties);
                    };
    
                    /**
                     * Encodes the specified ProviderData message. Does not implicitly {@link openGameApi.ListProvidersData.Resp.ProviderData.verify|verify} messages.
                     * @function encode
                     * @memberof openGameApi.ListProvidersData.Resp.ProviderData
                     * @static
                     * @param {openGameApi.ListProvidersData.Resp.IProviderData} message ProviderData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProviderData.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
                        if (message.providerPrefix != null && Object.hasOwnProperty.call(message, "providerPrefix"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.providerPrefix);
                        if (message.providerName != null && Object.hasOwnProperty.call(message, "providerName"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.providerName);
                        if (message.providerSecret != null && Object.hasOwnProperty.call(message, "providerSecret"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.providerSecret);
                        if (message.isTesting != null && Object.hasOwnProperty.call(message, "isTesting"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isTesting);
                        if (message.accounts != null && message.accounts.length)
                            for (var i = 0; i < message.accounts.length; ++i)
                                $root.openGameApi.ListProvidersData.Resp.PublicAccountData.encode(message.accounts[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        return writer;
                    };
    
                    /**
                     * Encodes the specified ProviderData message, length delimited. Does not implicitly {@link openGameApi.ListProvidersData.Resp.ProviderData.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof openGameApi.ListProvidersData.Resp.ProviderData
                     * @static
                     * @param {openGameApi.ListProvidersData.Resp.IProviderData} message ProviderData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProviderData.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a ProviderData message from the specified reader or buffer.
                     * @function decode
                     * @memberof openGameApi.ListProvidersData.Resp.ProviderData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {openGameApi.ListProvidersData.Resp.ProviderData} ProviderData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProviderData.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.ListProvidersData.Resp.ProviderData();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.providerId = reader.string();
                                break;
                            case 2:
                                message.providerPrefix = reader.string();
                                break;
                            case 3:
                                message.providerName = reader.string();
                                break;
                            case 4:
                                message.providerSecret = reader.string();
                                break;
                            case 5:
                                message.isTesting = reader.bool();
                                break;
                            case 6:
                                if (!(message.accounts && message.accounts.length))
                                    message.accounts = [];
                                message.accounts.push($root.openGameApi.ListProvidersData.Resp.PublicAccountData.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a ProviderData message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof openGameApi.ListProvidersData.Resp.ProviderData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {openGameApi.ListProvidersData.Resp.ProviderData} ProviderData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProviderData.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a ProviderData message.
                     * @function verify
                     * @memberof openGameApi.ListProvidersData.Resp.ProviderData
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ProviderData.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.providerId != null && message.hasOwnProperty("providerId"))
                            if (!$util.isString(message.providerId))
                                return "providerId: string expected";
                        if (message.providerPrefix != null && message.hasOwnProperty("providerPrefix"))
                            if (!$util.isString(message.providerPrefix))
                                return "providerPrefix: string expected";
                        if (message.providerName != null && message.hasOwnProperty("providerName"))
                            if (!$util.isString(message.providerName))
                                return "providerName: string expected";
                        if (message.providerSecret != null && message.hasOwnProperty("providerSecret"))
                            if (!$util.isString(message.providerSecret))
                                return "providerSecret: string expected";
                        if (message.isTesting != null && message.hasOwnProperty("isTesting"))
                            if (typeof message.isTesting !== "boolean")
                                return "isTesting: boolean expected";
                        if (message.accounts != null && message.hasOwnProperty("accounts")) {
                            if (!Array.isArray(message.accounts))
                                return "accounts: array expected";
                            for (var i = 0; i < message.accounts.length; ++i) {
                                var error = $root.openGameApi.ListProvidersData.Resp.PublicAccountData.verify(message.accounts[i]);
                                if (error)
                                    return "accounts." + error;
                            }
                        }
                        return null;
                    };
    
                    /**
                     * Creates a ProviderData message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof openGameApi.ListProvidersData.Resp.ProviderData
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {openGameApi.ListProvidersData.Resp.ProviderData} ProviderData
                     */
                    ProviderData.fromObject = function fromObject(object) {
                        if (object instanceof $root.openGameApi.ListProvidersData.Resp.ProviderData)
                            return object;
                        var message = new $root.openGameApi.ListProvidersData.Resp.ProviderData();
                        if (object.providerId != null)
                            message.providerId = String(object.providerId);
                        if (object.providerPrefix != null)
                            message.providerPrefix = String(object.providerPrefix);
                        if (object.providerName != null)
                            message.providerName = String(object.providerName);
                        if (object.providerSecret != null)
                            message.providerSecret = String(object.providerSecret);
                        if (object.isTesting != null)
                            message.isTesting = Boolean(object.isTesting);
                        if (object.accounts) {
                            if (!Array.isArray(object.accounts))
                                throw TypeError(".openGameApi.ListProvidersData.Resp.ProviderData.accounts: array expected");
                            message.accounts = [];
                            for (var i = 0; i < object.accounts.length; ++i) {
                                if (typeof object.accounts[i] !== "object")
                                    throw TypeError(".openGameApi.ListProvidersData.Resp.ProviderData.accounts: object expected");
                                message.accounts[i] = $root.openGameApi.ListProvidersData.Resp.PublicAccountData.fromObject(object.accounts[i]);
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a ProviderData message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof openGameApi.ListProvidersData.Resp.ProviderData
                     * @static
                     * @param {openGameApi.ListProvidersData.Resp.ProviderData} message ProviderData
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ProviderData.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.accounts = [];
                        if (options.defaults) {
                            object.providerId = "";
                            object.providerPrefix = "";
                            object.providerName = "";
                            object.providerSecret = "";
                            object.isTesting = false;
                        }
                        if (message.providerId != null && message.hasOwnProperty("providerId"))
                            object.providerId = message.providerId;
                        if (message.providerPrefix != null && message.hasOwnProperty("providerPrefix"))
                            object.providerPrefix = message.providerPrefix;
                        if (message.providerName != null && message.hasOwnProperty("providerName"))
                            object.providerName = message.providerName;
                        if (message.providerSecret != null && message.hasOwnProperty("providerSecret"))
                            object.providerSecret = message.providerSecret;
                        if (message.isTesting != null && message.hasOwnProperty("isTesting"))
                            object.isTesting = message.isTesting;
                        if (message.accounts && message.accounts.length) {
                            object.accounts = [];
                            for (var j = 0; j < message.accounts.length; ++j)
                                object.accounts[j] = $root.openGameApi.ListProvidersData.Resp.PublicAccountData.toObject(message.accounts[j], options);
                        }
                        return object;
                    };
    
                    /**
                     * Converts this ProviderData to JSON.
                     * @function toJSON
                     * @memberof openGameApi.ListProvidersData.Resp.ProviderData
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ProviderData.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return ProviderData;
                })();
    
                return Resp;
            })();
    
            return ListProvidersData;
        })();
    
        openGameApi.ProviderAccountSettings = (function() {
    
            /**
             * Properties of a ProviderAccountSettings.
             * @memberof openGameApi
             * @interface IProviderAccountSettings
             * @property {number|null} [accountId] ProviderAccountSettings accountId
             * @property {string|null} [gameEmbedUrl] ProviderAccountSettings gameEmbedUrl
             * @property {string|null} [royaltyPercent] ProviderAccountSettings royaltyPercent
             * @property {Array.<string>|null} [countriesAvailable] ProviderAccountSettings countriesAvailable
             * @property {Array.<string>|null} [countriesDisabled] ProviderAccountSettings countriesDisabled
             * @property {Array.<string>|null} [currenciesAvailable] ProviderAccountSettings currenciesAvailable
             */
    
            /**
             * Constructs a new ProviderAccountSettings.
             * @memberof openGameApi
             * @classdesc Represents a ProviderAccountSettings.
             * @implements IProviderAccountSettings
             * @constructor
             * @param {openGameApi.IProviderAccountSettings=} [properties] Properties to set
             */
            function ProviderAccountSettings(properties) {
                this.countriesAvailable = [];
                this.countriesDisabled = [];
                this.currenciesAvailable = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * ProviderAccountSettings accountId.
             * @member {number} accountId
             * @memberof openGameApi.ProviderAccountSettings
             * @instance
             */
            ProviderAccountSettings.prototype.accountId = 0;
    
            /**
             * ProviderAccountSettings gameEmbedUrl.
             * @member {string} gameEmbedUrl
             * @memberof openGameApi.ProviderAccountSettings
             * @instance
             */
            ProviderAccountSettings.prototype.gameEmbedUrl = "";
    
            /**
             * ProviderAccountSettings royaltyPercent.
             * @member {string} royaltyPercent
             * @memberof openGameApi.ProviderAccountSettings
             * @instance
             */
            ProviderAccountSettings.prototype.royaltyPercent = "";
    
            /**
             * ProviderAccountSettings countriesAvailable.
             * @member {Array.<string>} countriesAvailable
             * @memberof openGameApi.ProviderAccountSettings
             * @instance
             */
            ProviderAccountSettings.prototype.countriesAvailable = $util.emptyArray;
    
            /**
             * ProviderAccountSettings countriesDisabled.
             * @member {Array.<string>} countriesDisabled
             * @memberof openGameApi.ProviderAccountSettings
             * @instance
             */
            ProviderAccountSettings.prototype.countriesDisabled = $util.emptyArray;
    
            /**
             * ProviderAccountSettings currenciesAvailable.
             * @member {Array.<string>} currenciesAvailable
             * @memberof openGameApi.ProviderAccountSettings
             * @instance
             */
            ProviderAccountSettings.prototype.currenciesAvailable = $util.emptyArray;
    
            /**
             * Creates a new ProviderAccountSettings instance using the specified properties.
             * @function create
             * @memberof openGameApi.ProviderAccountSettings
             * @static
             * @param {openGameApi.IProviderAccountSettings=} [properties] Properties to set
             * @returns {openGameApi.ProviderAccountSettings} ProviderAccountSettings instance
             */
            ProviderAccountSettings.create = function create(properties) {
                return new ProviderAccountSettings(properties);
            };
    
            /**
             * Encodes the specified ProviderAccountSettings message. Does not implicitly {@link openGameApi.ProviderAccountSettings.verify|verify} messages.
             * @function encode
             * @memberof openGameApi.ProviderAccountSettings
             * @static
             * @param {openGameApi.IProviderAccountSettings} message ProviderAccountSettings message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProviderAccountSettings.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                    writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.accountId);
                if (message.gameEmbedUrl != null && Object.hasOwnProperty.call(message, "gameEmbedUrl"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.gameEmbedUrl);
                if (message.royaltyPercent != null && Object.hasOwnProperty.call(message, "royaltyPercent"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.royaltyPercent);
                if (message.countriesAvailable != null && message.countriesAvailable.length)
                    for (var i = 0; i < message.countriesAvailable.length; ++i)
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.countriesAvailable[i]);
                if (message.countriesDisabled != null && message.countriesDisabled.length)
                    for (var i = 0; i < message.countriesDisabled.length; ++i)
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.countriesDisabled[i]);
                if (message.currenciesAvailable != null && message.currenciesAvailable.length)
                    for (var i = 0; i < message.currenciesAvailable.length; ++i)
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.currenciesAvailable[i]);
                return writer;
            };
    
            /**
             * Encodes the specified ProviderAccountSettings message, length delimited. Does not implicitly {@link openGameApi.ProviderAccountSettings.verify|verify} messages.
             * @function encodeDelimited
             * @memberof openGameApi.ProviderAccountSettings
             * @static
             * @param {openGameApi.IProviderAccountSettings} message ProviderAccountSettings message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProviderAccountSettings.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a ProviderAccountSettings message from the specified reader or buffer.
             * @function decode
             * @memberof openGameApi.ProviderAccountSettings
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {openGameApi.ProviderAccountSettings} ProviderAccountSettings
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProviderAccountSettings.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.ProviderAccountSettings();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.accountId = reader.uint32();
                        break;
                    case 2:
                        message.gameEmbedUrl = reader.string();
                        break;
                    case 3:
                        message.royaltyPercent = reader.string();
                        break;
                    case 4:
                        if (!(message.countriesAvailable && message.countriesAvailable.length))
                            message.countriesAvailable = [];
                        message.countriesAvailable.push(reader.string());
                        break;
                    case 5:
                        if (!(message.countriesDisabled && message.countriesDisabled.length))
                            message.countriesDisabled = [];
                        message.countriesDisabled.push(reader.string());
                        break;
                    case 6:
                        if (!(message.currenciesAvailable && message.currenciesAvailable.length))
                            message.currenciesAvailable = [];
                        message.currenciesAvailable.push(reader.string());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a ProviderAccountSettings message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof openGameApi.ProviderAccountSettings
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {openGameApi.ProviderAccountSettings} ProviderAccountSettings
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProviderAccountSettings.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a ProviderAccountSettings message.
             * @function verify
             * @memberof openGameApi.ProviderAccountSettings
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ProviderAccountSettings.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.accountId != null && message.hasOwnProperty("accountId"))
                    if (!$util.isInteger(message.accountId))
                        return "accountId: integer expected";
                if (message.gameEmbedUrl != null && message.hasOwnProperty("gameEmbedUrl"))
                    if (!$util.isString(message.gameEmbedUrl))
                        return "gameEmbedUrl: string expected";
                if (message.royaltyPercent != null && message.hasOwnProperty("royaltyPercent"))
                    if (!$util.isString(message.royaltyPercent))
                        return "royaltyPercent: string expected";
                if (message.countriesAvailable != null && message.hasOwnProperty("countriesAvailable")) {
                    if (!Array.isArray(message.countriesAvailable))
                        return "countriesAvailable: array expected";
                    for (var i = 0; i < message.countriesAvailable.length; ++i)
                        if (!$util.isString(message.countriesAvailable[i]))
                            return "countriesAvailable: string[] expected";
                }
                if (message.countriesDisabled != null && message.hasOwnProperty("countriesDisabled")) {
                    if (!Array.isArray(message.countriesDisabled))
                        return "countriesDisabled: array expected";
                    for (var i = 0; i < message.countriesDisabled.length; ++i)
                        if (!$util.isString(message.countriesDisabled[i]))
                            return "countriesDisabled: string[] expected";
                }
                if (message.currenciesAvailable != null && message.hasOwnProperty("currenciesAvailable")) {
                    if (!Array.isArray(message.currenciesAvailable))
                        return "currenciesAvailable: array expected";
                    for (var i = 0; i < message.currenciesAvailable.length; ++i)
                        if (!$util.isString(message.currenciesAvailable[i]))
                            return "currenciesAvailable: string[] expected";
                }
                return null;
            };
    
            /**
             * Creates a ProviderAccountSettings message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof openGameApi.ProviderAccountSettings
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {openGameApi.ProviderAccountSettings} ProviderAccountSettings
             */
            ProviderAccountSettings.fromObject = function fromObject(object) {
                if (object instanceof $root.openGameApi.ProviderAccountSettings)
                    return object;
                var message = new $root.openGameApi.ProviderAccountSettings();
                if (object.accountId != null)
                    message.accountId = object.accountId >>> 0;
                if (object.gameEmbedUrl != null)
                    message.gameEmbedUrl = String(object.gameEmbedUrl);
                if (object.royaltyPercent != null)
                    message.royaltyPercent = String(object.royaltyPercent);
                if (object.countriesAvailable) {
                    if (!Array.isArray(object.countriesAvailable))
                        throw TypeError(".openGameApi.ProviderAccountSettings.countriesAvailable: array expected");
                    message.countriesAvailable = [];
                    for (var i = 0; i < object.countriesAvailable.length; ++i)
                        message.countriesAvailable[i] = String(object.countriesAvailable[i]);
                }
                if (object.countriesDisabled) {
                    if (!Array.isArray(object.countriesDisabled))
                        throw TypeError(".openGameApi.ProviderAccountSettings.countriesDisabled: array expected");
                    message.countriesDisabled = [];
                    for (var i = 0; i < object.countriesDisabled.length; ++i)
                        message.countriesDisabled[i] = String(object.countriesDisabled[i]);
                }
                if (object.currenciesAvailable) {
                    if (!Array.isArray(object.currenciesAvailable))
                        throw TypeError(".openGameApi.ProviderAccountSettings.currenciesAvailable: array expected");
                    message.currenciesAvailable = [];
                    for (var i = 0; i < object.currenciesAvailable.length; ++i)
                        message.currenciesAvailable[i] = String(object.currenciesAvailable[i]);
                }
                return message;
            };
    
            /**
             * Creates a plain object from a ProviderAccountSettings message. Also converts values to other types if specified.
             * @function toObject
             * @memberof openGameApi.ProviderAccountSettings
             * @static
             * @param {openGameApi.ProviderAccountSettings} message ProviderAccountSettings
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ProviderAccountSettings.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.countriesAvailable = [];
                    object.countriesDisabled = [];
                    object.currenciesAvailable = [];
                }
                if (options.defaults) {
                    object.accountId = 0;
                    object.gameEmbedUrl = "";
                    object.royaltyPercent = "";
                }
                if (message.accountId != null && message.hasOwnProperty("accountId"))
                    object.accountId = message.accountId;
                if (message.gameEmbedUrl != null && message.hasOwnProperty("gameEmbedUrl"))
                    object.gameEmbedUrl = message.gameEmbedUrl;
                if (message.royaltyPercent != null && message.hasOwnProperty("royaltyPercent"))
                    object.royaltyPercent = message.royaltyPercent;
                if (message.countriesAvailable && message.countriesAvailable.length) {
                    object.countriesAvailable = [];
                    for (var j = 0; j < message.countriesAvailable.length; ++j)
                        object.countriesAvailable[j] = message.countriesAvailable[j];
                }
                if (message.countriesDisabled && message.countriesDisabled.length) {
                    object.countriesDisabled = [];
                    for (var j = 0; j < message.countriesDisabled.length; ++j)
                        object.countriesDisabled[j] = message.countriesDisabled[j];
                }
                if (message.currenciesAvailable && message.currenciesAvailable.length) {
                    object.currenciesAvailable = [];
                    for (var j = 0; j < message.currenciesAvailable.length; ++j)
                        object.currenciesAvailable[j] = message.currenciesAvailable[j];
                }
                return object;
            };
    
            /**
             * Converts this ProviderAccountSettings to JSON.
             * @function toJSON
             * @memberof openGameApi.ProviderAccountSettings
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ProviderAccountSettings.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            return ProviderAccountSettings;
        })();
    
        openGameApi.CreateOrUpdateIntegration = (function() {
    
            /**
             * Properties of a CreateOrUpdateIntegration.
             * @memberof openGameApi
             * @interface ICreateOrUpdateIntegration
             */
    
            /**
             * Constructs a new CreateOrUpdateIntegration.
             * @memberof openGameApi
             * @classdesc Represents a CreateOrUpdateIntegration.
             * @implements ICreateOrUpdateIntegration
             * @constructor
             * @param {openGameApi.ICreateOrUpdateIntegration=} [properties] Properties to set
             */
            function CreateOrUpdateIntegration(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new CreateOrUpdateIntegration instance using the specified properties.
             * @function create
             * @memberof openGameApi.CreateOrUpdateIntegration
             * @static
             * @param {openGameApi.ICreateOrUpdateIntegration=} [properties] Properties to set
             * @returns {openGameApi.CreateOrUpdateIntegration} CreateOrUpdateIntegration instance
             */
            CreateOrUpdateIntegration.create = function create(properties) {
                return new CreateOrUpdateIntegration(properties);
            };
    
            /**
             * Encodes the specified CreateOrUpdateIntegration message. Does not implicitly {@link openGameApi.CreateOrUpdateIntegration.verify|verify} messages.
             * @function encode
             * @memberof openGameApi.CreateOrUpdateIntegration
             * @static
             * @param {openGameApi.ICreateOrUpdateIntegration} message CreateOrUpdateIntegration message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateOrUpdateIntegration.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified CreateOrUpdateIntegration message, length delimited. Does not implicitly {@link openGameApi.CreateOrUpdateIntegration.verify|verify} messages.
             * @function encodeDelimited
             * @memberof openGameApi.CreateOrUpdateIntegration
             * @static
             * @param {openGameApi.ICreateOrUpdateIntegration} message CreateOrUpdateIntegration message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateOrUpdateIntegration.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CreateOrUpdateIntegration message from the specified reader or buffer.
             * @function decode
             * @memberof openGameApi.CreateOrUpdateIntegration
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {openGameApi.CreateOrUpdateIntegration} CreateOrUpdateIntegration
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateOrUpdateIntegration.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.CreateOrUpdateIntegration();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CreateOrUpdateIntegration message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof openGameApi.CreateOrUpdateIntegration
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {openGameApi.CreateOrUpdateIntegration} CreateOrUpdateIntegration
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateOrUpdateIntegration.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CreateOrUpdateIntegration message.
             * @function verify
             * @memberof openGameApi.CreateOrUpdateIntegration
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateOrUpdateIntegration.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a CreateOrUpdateIntegration message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof openGameApi.CreateOrUpdateIntegration
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {openGameApi.CreateOrUpdateIntegration} CreateOrUpdateIntegration
             */
            CreateOrUpdateIntegration.fromObject = function fromObject(object) {
                if (object instanceof $root.openGameApi.CreateOrUpdateIntegration)
                    return object;
                return new $root.openGameApi.CreateOrUpdateIntegration();
            };
    
            /**
             * Creates a plain object from a CreateOrUpdateIntegration message. Also converts values to other types if specified.
             * @function toObject
             * @memberof openGameApi.CreateOrUpdateIntegration
             * @static
             * @param {openGameApi.CreateOrUpdateIntegration} message CreateOrUpdateIntegration
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateOrUpdateIntegration.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this CreateOrUpdateIntegration to JSON.
             * @function toJSON
             * @memberof openGameApi.CreateOrUpdateIntegration
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateOrUpdateIntegration.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            CreateOrUpdateIntegration.Req = (function() {
    
                /**
                 * Properties of a Req.
                 * @memberof openGameApi.CreateOrUpdateIntegration
                 * @interface IReq
                 * @property {string|null} [contentProviderId] Req contentProviderId
                 * @property {Array.<openGameApi.IProviderAccountSettings>|null} [accounts] Req accounts
                 */
    
                /**
                 * Constructs a new Req.
                 * @memberof openGameApi.CreateOrUpdateIntegration
                 * @classdesc Represents a Req.
                 * @implements IReq
                 * @constructor
                 * @param {openGameApi.CreateOrUpdateIntegration.IReq=} [properties] Properties to set
                 */
                function Req(properties) {
                    this.accounts = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Req contentProviderId.
                 * @member {string} contentProviderId
                 * @memberof openGameApi.CreateOrUpdateIntegration.Req
                 * @instance
                 */
                Req.prototype.contentProviderId = "";
    
                /**
                 * Req accounts.
                 * @member {Array.<openGameApi.IProviderAccountSettings>} accounts
                 * @memberof openGameApi.CreateOrUpdateIntegration.Req
                 * @instance
                 */
                Req.prototype.accounts = $util.emptyArray;
    
                /**
                 * Creates a new Req instance using the specified properties.
                 * @function create
                 * @memberof openGameApi.CreateOrUpdateIntegration.Req
                 * @static
                 * @param {openGameApi.CreateOrUpdateIntegration.IReq=} [properties] Properties to set
                 * @returns {openGameApi.CreateOrUpdateIntegration.Req} Req instance
                 */
                Req.create = function create(properties) {
                    return new Req(properties);
                };
    
                /**
                 * Encodes the specified Req message. Does not implicitly {@link openGameApi.CreateOrUpdateIntegration.Req.verify|verify} messages.
                 * @function encode
                 * @memberof openGameApi.CreateOrUpdateIntegration.Req
                 * @static
                 * @param {openGameApi.CreateOrUpdateIntegration.IReq} message Req message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Req.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.contentProviderId != null && Object.hasOwnProperty.call(message, "contentProviderId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.contentProviderId);
                    if (message.accounts != null && message.accounts.length)
                        for (var i = 0; i < message.accounts.length; ++i)
                            $root.openGameApi.ProviderAccountSettings.encode(message.accounts[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Req message, length delimited. Does not implicitly {@link openGameApi.CreateOrUpdateIntegration.Req.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof openGameApi.CreateOrUpdateIntegration.Req
                 * @static
                 * @param {openGameApi.CreateOrUpdateIntegration.IReq} message Req message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Req.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Req message from the specified reader or buffer.
                 * @function decode
                 * @memberof openGameApi.CreateOrUpdateIntegration.Req
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {openGameApi.CreateOrUpdateIntegration.Req} Req
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Req.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.CreateOrUpdateIntegration.Req();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.contentProviderId = reader.string();
                            break;
                        case 2:
                            if (!(message.accounts && message.accounts.length))
                                message.accounts = [];
                            message.accounts.push($root.openGameApi.ProviderAccountSettings.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Req message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof openGameApi.CreateOrUpdateIntegration.Req
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {openGameApi.CreateOrUpdateIntegration.Req} Req
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Req.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Req message.
                 * @function verify
                 * @memberof openGameApi.CreateOrUpdateIntegration.Req
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Req.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.contentProviderId != null && message.hasOwnProperty("contentProviderId"))
                        if (!$util.isString(message.contentProviderId))
                            return "contentProviderId: string expected";
                    if (message.accounts != null && message.hasOwnProperty("accounts")) {
                        if (!Array.isArray(message.accounts))
                            return "accounts: array expected";
                        for (var i = 0; i < message.accounts.length; ++i) {
                            var error = $root.openGameApi.ProviderAccountSettings.verify(message.accounts[i]);
                            if (error)
                                return "accounts." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a Req message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof openGameApi.CreateOrUpdateIntegration.Req
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {openGameApi.CreateOrUpdateIntegration.Req} Req
                 */
                Req.fromObject = function fromObject(object) {
                    if (object instanceof $root.openGameApi.CreateOrUpdateIntegration.Req)
                        return object;
                    var message = new $root.openGameApi.CreateOrUpdateIntegration.Req();
                    if (object.contentProviderId != null)
                        message.contentProviderId = String(object.contentProviderId);
                    if (object.accounts) {
                        if (!Array.isArray(object.accounts))
                            throw TypeError(".openGameApi.CreateOrUpdateIntegration.Req.accounts: array expected");
                        message.accounts = [];
                        for (var i = 0; i < object.accounts.length; ++i) {
                            if (typeof object.accounts[i] !== "object")
                                throw TypeError(".openGameApi.CreateOrUpdateIntegration.Req.accounts: object expected");
                            message.accounts[i] = $root.openGameApi.ProviderAccountSettings.fromObject(object.accounts[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Req message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof openGameApi.CreateOrUpdateIntegration.Req
                 * @static
                 * @param {openGameApi.CreateOrUpdateIntegration.Req} message Req
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Req.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.accounts = [];
                    if (options.defaults)
                        object.contentProviderId = "";
                    if (message.contentProviderId != null && message.hasOwnProperty("contentProviderId"))
                        object.contentProviderId = message.contentProviderId;
                    if (message.accounts && message.accounts.length) {
                        object.accounts = [];
                        for (var j = 0; j < message.accounts.length; ++j)
                            object.accounts[j] = $root.openGameApi.ProviderAccountSettings.toObject(message.accounts[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this Req to JSON.
                 * @function toJSON
                 * @memberof openGameApi.CreateOrUpdateIntegration.Req
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Req.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Req;
            })();
    
            CreateOrUpdateIntegration.Resp = (function() {
    
                /**
                 * Properties of a Resp.
                 * @memberof openGameApi.CreateOrUpdateIntegration
                 * @interface IResp
                 * @property {boolean|null} [isSuccess] Resp isSuccess
                 * @property {openGameApi.CreateOrUpdateIntegration.OpenApiErrorType|null} [error] Resp error
                 * @property {string|null} [errorMessage] Resp errorMessage
                 * @property {Array.<openGameApi.IProviderAccountSettings>|null} [accounts] Resp accounts
                 */
    
                /**
                 * Constructs a new Resp.
                 * @memberof openGameApi.CreateOrUpdateIntegration
                 * @classdesc Represents a Resp.
                 * @implements IResp
                 * @constructor
                 * @param {openGameApi.CreateOrUpdateIntegration.IResp=} [properties] Properties to set
                 */
                function Resp(properties) {
                    this.accounts = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Resp isSuccess.
                 * @member {boolean} isSuccess
                 * @memberof openGameApi.CreateOrUpdateIntegration.Resp
                 * @instance
                 */
                Resp.prototype.isSuccess = false;
    
                /**
                 * Resp error.
                 * @member {openGameApi.CreateOrUpdateIntegration.OpenApiErrorType} error
                 * @memberof openGameApi.CreateOrUpdateIntegration.Resp
                 * @instance
                 */
                Resp.prototype.error = 0;
    
                /**
                 * Resp errorMessage.
                 * @member {string} errorMessage
                 * @memberof openGameApi.CreateOrUpdateIntegration.Resp
                 * @instance
                 */
                Resp.prototype.errorMessage = "";
    
                /**
                 * Resp accounts.
                 * @member {Array.<openGameApi.IProviderAccountSettings>} accounts
                 * @memberof openGameApi.CreateOrUpdateIntegration.Resp
                 * @instance
                 */
                Resp.prototype.accounts = $util.emptyArray;
    
                /**
                 * Creates a new Resp instance using the specified properties.
                 * @function create
                 * @memberof openGameApi.CreateOrUpdateIntegration.Resp
                 * @static
                 * @param {openGameApi.CreateOrUpdateIntegration.IResp=} [properties] Properties to set
                 * @returns {openGameApi.CreateOrUpdateIntegration.Resp} Resp instance
                 */
                Resp.create = function create(properties) {
                    return new Resp(properties);
                };
    
                /**
                 * Encodes the specified Resp message. Does not implicitly {@link openGameApi.CreateOrUpdateIntegration.Resp.verify|verify} messages.
                 * @function encode
                 * @memberof openGameApi.CreateOrUpdateIntegration.Resp
                 * @static
                 * @param {openGameApi.CreateOrUpdateIntegration.IResp} message Resp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Resp.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.isSuccess != null && Object.hasOwnProperty.call(message, "isSuccess"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isSuccess);
                    if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.error);
                    if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.errorMessage);
                    if (message.accounts != null && message.accounts.length)
                        for (var i = 0; i < message.accounts.length; ++i)
                            $root.openGameApi.ProviderAccountSettings.encode(message.accounts[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Resp message, length delimited. Does not implicitly {@link openGameApi.CreateOrUpdateIntegration.Resp.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof openGameApi.CreateOrUpdateIntegration.Resp
                 * @static
                 * @param {openGameApi.CreateOrUpdateIntegration.IResp} message Resp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Resp.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Resp message from the specified reader or buffer.
                 * @function decode
                 * @memberof openGameApi.CreateOrUpdateIntegration.Resp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {openGameApi.CreateOrUpdateIntegration.Resp} Resp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Resp.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.CreateOrUpdateIntegration.Resp();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.isSuccess = reader.bool();
                            break;
                        case 2:
                            message.error = reader.int32();
                            break;
                        case 3:
                            message.errorMessage = reader.string();
                            break;
                        case 4:
                            if (!(message.accounts && message.accounts.length))
                                message.accounts = [];
                            message.accounts.push($root.openGameApi.ProviderAccountSettings.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Resp message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof openGameApi.CreateOrUpdateIntegration.Resp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {openGameApi.CreateOrUpdateIntegration.Resp} Resp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Resp.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Resp message.
                 * @function verify
                 * @memberof openGameApi.CreateOrUpdateIntegration.Resp
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Resp.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.isSuccess != null && message.hasOwnProperty("isSuccess"))
                        if (typeof message.isSuccess !== "boolean")
                            return "isSuccess: boolean expected";
                    if (message.error != null && message.hasOwnProperty("error"))
                        switch (message.error) {
                        default:
                            return "error: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 999:
                            break;
                        }
                    if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                        if (!$util.isString(message.errorMessage))
                            return "errorMessage: string expected";
                    if (message.accounts != null && message.hasOwnProperty("accounts")) {
                        if (!Array.isArray(message.accounts))
                            return "accounts: array expected";
                        for (var i = 0; i < message.accounts.length; ++i) {
                            var error = $root.openGameApi.ProviderAccountSettings.verify(message.accounts[i]);
                            if (error)
                                return "accounts." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a Resp message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof openGameApi.CreateOrUpdateIntegration.Resp
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {openGameApi.CreateOrUpdateIntegration.Resp} Resp
                 */
                Resp.fromObject = function fromObject(object) {
                    if (object instanceof $root.openGameApi.CreateOrUpdateIntegration.Resp)
                        return object;
                    var message = new $root.openGameApi.CreateOrUpdateIntegration.Resp();
                    if (object.isSuccess != null)
                        message.isSuccess = Boolean(object.isSuccess);
                    switch (object.error) {
                    case "NO_ERROR":
                    case 0:
                        message.error = 0;
                        break;
                    case "SIGN_NOT_FOUND":
                    case 1:
                        message.error = 1;
                        break;
                    case "INVALID_SIGN":
                    case 2:
                        message.error = 2;
                        break;
                    case "INVALID_BODY":
                    case 3:
                        message.error = 3;
                        break;
                    case "INTERNAL_ERROR":
                    case 999:
                        message.error = 999;
                        break;
                    }
                    if (object.errorMessage != null)
                        message.errorMessage = String(object.errorMessage);
                    if (object.accounts) {
                        if (!Array.isArray(object.accounts))
                            throw TypeError(".openGameApi.CreateOrUpdateIntegration.Resp.accounts: array expected");
                        message.accounts = [];
                        for (var i = 0; i < object.accounts.length; ++i) {
                            if (typeof object.accounts[i] !== "object")
                                throw TypeError(".openGameApi.CreateOrUpdateIntegration.Resp.accounts: object expected");
                            message.accounts[i] = $root.openGameApi.ProviderAccountSettings.fromObject(object.accounts[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Resp message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof openGameApi.CreateOrUpdateIntegration.Resp
                 * @static
                 * @param {openGameApi.CreateOrUpdateIntegration.Resp} message Resp
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Resp.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.accounts = [];
                    if (options.defaults) {
                        object.isSuccess = false;
                        object.error = options.enums === String ? "NO_ERROR" : 0;
                        object.errorMessage = "";
                    }
                    if (message.isSuccess != null && message.hasOwnProperty("isSuccess"))
                        object.isSuccess = message.isSuccess;
                    if (message.error != null && message.hasOwnProperty("error"))
                        object.error = options.enums === String ? $root.openGameApi.CreateOrUpdateIntegration.OpenApiErrorType[message.error] : message.error;
                    if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                        object.errorMessage = message.errorMessage;
                    if (message.accounts && message.accounts.length) {
                        object.accounts = [];
                        for (var j = 0; j < message.accounts.length; ++j)
                            object.accounts[j] = $root.openGameApi.ProviderAccountSettings.toObject(message.accounts[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this Resp to JSON.
                 * @function toJSON
                 * @memberof openGameApi.CreateOrUpdateIntegration.Resp
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Resp.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Resp;
            })();
    
            /**
             * OpenApiErrorType enum.
             * @name openGameApi.CreateOrUpdateIntegration.OpenApiErrorType
             * @enum {number}
             * @property {number} NO_ERROR=0 NO_ERROR value
             * @property {number} SIGN_NOT_FOUND=1 SIGN_NOT_FOUND value
             * @property {number} INVALID_SIGN=2 INVALID_SIGN value
             * @property {number} INVALID_BODY=3 INVALID_BODY value
             * @property {number} INTERNAL_ERROR=999 INTERNAL_ERROR value
             */
            CreateOrUpdateIntegration.OpenApiErrorType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "NO_ERROR"] = 0;
                values[valuesById[1] = "SIGN_NOT_FOUND"] = 1;
                values[valuesById[2] = "INVALID_SIGN"] = 2;
                values[valuesById[3] = "INVALID_BODY"] = 3;
                values[valuesById[999] = "INTERNAL_ERROR"] = 999;
                return values;
            })();
    
            return CreateOrUpdateIntegration;
        })();
    
        openGameApi.UpdateGames = (function() {
    
            /**
             * Properties of an UpdateGames.
             * @memberof openGameApi
             * @interface IUpdateGames
             */
    
            /**
             * Constructs a new UpdateGames.
             * @memberof openGameApi
             * @classdesc Represents an UpdateGames.
             * @implements IUpdateGames
             * @constructor
             * @param {openGameApi.IUpdateGames=} [properties] Properties to set
             */
            function UpdateGames(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new UpdateGames instance using the specified properties.
             * @function create
             * @memberof openGameApi.UpdateGames
             * @static
             * @param {openGameApi.IUpdateGames=} [properties] Properties to set
             * @returns {openGameApi.UpdateGames} UpdateGames instance
             */
            UpdateGames.create = function create(properties) {
                return new UpdateGames(properties);
            };
    
            /**
             * Encodes the specified UpdateGames message. Does not implicitly {@link openGameApi.UpdateGames.verify|verify} messages.
             * @function encode
             * @memberof openGameApi.UpdateGames
             * @static
             * @param {openGameApi.IUpdateGames} message UpdateGames message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateGames.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified UpdateGames message, length delimited. Does not implicitly {@link openGameApi.UpdateGames.verify|verify} messages.
             * @function encodeDelimited
             * @memberof openGameApi.UpdateGames
             * @static
             * @param {openGameApi.IUpdateGames} message UpdateGames message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateGames.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an UpdateGames message from the specified reader or buffer.
             * @function decode
             * @memberof openGameApi.UpdateGames
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {openGameApi.UpdateGames} UpdateGames
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateGames.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.UpdateGames();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an UpdateGames message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof openGameApi.UpdateGames
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {openGameApi.UpdateGames} UpdateGames
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateGames.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an UpdateGames message.
             * @function verify
             * @memberof openGameApi.UpdateGames
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateGames.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates an UpdateGames message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof openGameApi.UpdateGames
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {openGameApi.UpdateGames} UpdateGames
             */
            UpdateGames.fromObject = function fromObject(object) {
                if (object instanceof $root.openGameApi.UpdateGames)
                    return object;
                return new $root.openGameApi.UpdateGames();
            };
    
            /**
             * Creates a plain object from an UpdateGames message. Also converts values to other types if specified.
             * @function toObject
             * @memberof openGameApi.UpdateGames
             * @static
             * @param {openGameApi.UpdateGames} message UpdateGames
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateGames.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this UpdateGames to JSON.
             * @function toJSON
             * @memberof openGameApi.UpdateGames
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateGames.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            UpdateGames.Req = (function() {
    
                /**
                 * Properties of a Req.
                 * @memberof openGameApi.UpdateGames
                 * @interface IReq
                 * @property {string|null} [contentProviderId] Req contentProviderId
                 * @property {number|null} [accountId] Req accountId
                 * @property {Array.<openGameApi.IGame>|null} [games] Req games
                 */
    
                /**
                 * Constructs a new Req.
                 * @memberof openGameApi.UpdateGames
                 * @classdesc Represents a Req.
                 * @implements IReq
                 * @constructor
                 * @param {openGameApi.UpdateGames.IReq=} [properties] Properties to set
                 */
                function Req(properties) {
                    this.games = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Req contentProviderId.
                 * @member {string} contentProviderId
                 * @memberof openGameApi.UpdateGames.Req
                 * @instance
                 */
                Req.prototype.contentProviderId = "";
    
                /**
                 * Req accountId.
                 * @member {number} accountId
                 * @memberof openGameApi.UpdateGames.Req
                 * @instance
                 */
                Req.prototype.accountId = 0;
    
                /**
                 * Req games.
                 * @member {Array.<openGameApi.IGame>} games
                 * @memberof openGameApi.UpdateGames.Req
                 * @instance
                 */
                Req.prototype.games = $util.emptyArray;
    
                /**
                 * Creates a new Req instance using the specified properties.
                 * @function create
                 * @memberof openGameApi.UpdateGames.Req
                 * @static
                 * @param {openGameApi.UpdateGames.IReq=} [properties] Properties to set
                 * @returns {openGameApi.UpdateGames.Req} Req instance
                 */
                Req.create = function create(properties) {
                    return new Req(properties);
                };
    
                /**
                 * Encodes the specified Req message. Does not implicitly {@link openGameApi.UpdateGames.Req.verify|verify} messages.
                 * @function encode
                 * @memberof openGameApi.UpdateGames.Req
                 * @static
                 * @param {openGameApi.UpdateGames.IReq} message Req message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Req.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.contentProviderId != null && Object.hasOwnProperty.call(message, "contentProviderId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.contentProviderId);
                    if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                        writer.uint32(/* id 2, wireType 0 =*/16).uint32(message.accountId);
                    if (message.games != null && message.games.length)
                        for (var i = 0; i < message.games.length; ++i)
                            $root.openGameApi.Game.encode(message.games[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Req message, length delimited. Does not implicitly {@link openGameApi.UpdateGames.Req.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof openGameApi.UpdateGames.Req
                 * @static
                 * @param {openGameApi.UpdateGames.IReq} message Req message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Req.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Req message from the specified reader or buffer.
                 * @function decode
                 * @memberof openGameApi.UpdateGames.Req
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {openGameApi.UpdateGames.Req} Req
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Req.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.UpdateGames.Req();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.contentProviderId = reader.string();
                            break;
                        case 2:
                            message.accountId = reader.uint32();
                            break;
                        case 3:
                            if (!(message.games && message.games.length))
                                message.games = [];
                            message.games.push($root.openGameApi.Game.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Req message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof openGameApi.UpdateGames.Req
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {openGameApi.UpdateGames.Req} Req
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Req.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Req message.
                 * @function verify
                 * @memberof openGameApi.UpdateGames.Req
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Req.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.contentProviderId != null && message.hasOwnProperty("contentProviderId"))
                        if (!$util.isString(message.contentProviderId))
                            return "contentProviderId: string expected";
                    if (message.accountId != null && message.hasOwnProperty("accountId"))
                        if (!$util.isInteger(message.accountId))
                            return "accountId: integer expected";
                    if (message.games != null && message.hasOwnProperty("games")) {
                        if (!Array.isArray(message.games))
                            return "games: array expected";
                        for (var i = 0; i < message.games.length; ++i) {
                            var error = $root.openGameApi.Game.verify(message.games[i]);
                            if (error)
                                return "games." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a Req message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof openGameApi.UpdateGames.Req
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {openGameApi.UpdateGames.Req} Req
                 */
                Req.fromObject = function fromObject(object) {
                    if (object instanceof $root.openGameApi.UpdateGames.Req)
                        return object;
                    var message = new $root.openGameApi.UpdateGames.Req();
                    if (object.contentProviderId != null)
                        message.contentProviderId = String(object.contentProviderId);
                    if (object.accountId != null)
                        message.accountId = object.accountId >>> 0;
                    if (object.games) {
                        if (!Array.isArray(object.games))
                            throw TypeError(".openGameApi.UpdateGames.Req.games: array expected");
                        message.games = [];
                        for (var i = 0; i < object.games.length; ++i) {
                            if (typeof object.games[i] !== "object")
                                throw TypeError(".openGameApi.UpdateGames.Req.games: object expected");
                            message.games[i] = $root.openGameApi.Game.fromObject(object.games[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Req message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof openGameApi.UpdateGames.Req
                 * @static
                 * @param {openGameApi.UpdateGames.Req} message Req
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Req.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.games = [];
                    if (options.defaults) {
                        object.contentProviderId = "";
                        object.accountId = 0;
                    }
                    if (message.contentProviderId != null && message.hasOwnProperty("contentProviderId"))
                        object.contentProviderId = message.contentProviderId;
                    if (message.accountId != null && message.hasOwnProperty("accountId"))
                        object.accountId = message.accountId;
                    if (message.games && message.games.length) {
                        object.games = [];
                        for (var j = 0; j < message.games.length; ++j)
                            object.games[j] = $root.openGameApi.Game.toObject(message.games[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this Req to JSON.
                 * @function toJSON
                 * @memberof openGameApi.UpdateGames.Req
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Req.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Req;
            })();
    
            UpdateGames.Resp = (function() {
    
                /**
                 * Properties of a Resp.
                 * @memberof openGameApi.UpdateGames
                 * @interface IResp
                 * @property {boolean|null} [isSuccess] Resp isSuccess
                 * @property {openGameApi.UpdateGames.OpenApiErrorType|null} [error] Resp error
                 * @property {string|null} [errorMessage] Resp errorMessage
                 */
    
                /**
                 * Constructs a new Resp.
                 * @memberof openGameApi.UpdateGames
                 * @classdesc Represents a Resp.
                 * @implements IResp
                 * @constructor
                 * @param {openGameApi.UpdateGames.IResp=} [properties] Properties to set
                 */
                function Resp(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Resp isSuccess.
                 * @member {boolean} isSuccess
                 * @memberof openGameApi.UpdateGames.Resp
                 * @instance
                 */
                Resp.prototype.isSuccess = false;
    
                /**
                 * Resp error.
                 * @member {openGameApi.UpdateGames.OpenApiErrorType} error
                 * @memberof openGameApi.UpdateGames.Resp
                 * @instance
                 */
                Resp.prototype.error = 0;
    
                /**
                 * Resp errorMessage.
                 * @member {string} errorMessage
                 * @memberof openGameApi.UpdateGames.Resp
                 * @instance
                 */
                Resp.prototype.errorMessage = "";
    
                /**
                 * Creates a new Resp instance using the specified properties.
                 * @function create
                 * @memberof openGameApi.UpdateGames.Resp
                 * @static
                 * @param {openGameApi.UpdateGames.IResp=} [properties] Properties to set
                 * @returns {openGameApi.UpdateGames.Resp} Resp instance
                 */
                Resp.create = function create(properties) {
                    return new Resp(properties);
                };
    
                /**
                 * Encodes the specified Resp message. Does not implicitly {@link openGameApi.UpdateGames.Resp.verify|verify} messages.
                 * @function encode
                 * @memberof openGameApi.UpdateGames.Resp
                 * @static
                 * @param {openGameApi.UpdateGames.IResp} message Resp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Resp.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.isSuccess != null && Object.hasOwnProperty.call(message, "isSuccess"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isSuccess);
                    if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.error);
                    if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.errorMessage);
                    return writer;
                };
    
                /**
                 * Encodes the specified Resp message, length delimited. Does not implicitly {@link openGameApi.UpdateGames.Resp.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof openGameApi.UpdateGames.Resp
                 * @static
                 * @param {openGameApi.UpdateGames.IResp} message Resp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Resp.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Resp message from the specified reader or buffer.
                 * @function decode
                 * @memberof openGameApi.UpdateGames.Resp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {openGameApi.UpdateGames.Resp} Resp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Resp.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.UpdateGames.Resp();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.isSuccess = reader.bool();
                            break;
                        case 2:
                            message.error = reader.int32();
                            break;
                        case 3:
                            message.errorMessage = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Resp message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof openGameApi.UpdateGames.Resp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {openGameApi.UpdateGames.Resp} Resp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Resp.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Resp message.
                 * @function verify
                 * @memberof openGameApi.UpdateGames.Resp
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Resp.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.isSuccess != null && message.hasOwnProperty("isSuccess"))
                        if (typeof message.isSuccess !== "boolean")
                            return "isSuccess: boolean expected";
                    if (message.error != null && message.hasOwnProperty("error"))
                        switch (message.error) {
                        default:
                            return "error: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 999:
                            break;
                        }
                    if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                        if (!$util.isString(message.errorMessage))
                            return "errorMessage: string expected";
                    return null;
                };
    
                /**
                 * Creates a Resp message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof openGameApi.UpdateGames.Resp
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {openGameApi.UpdateGames.Resp} Resp
                 */
                Resp.fromObject = function fromObject(object) {
                    if (object instanceof $root.openGameApi.UpdateGames.Resp)
                        return object;
                    var message = new $root.openGameApi.UpdateGames.Resp();
                    if (object.isSuccess != null)
                        message.isSuccess = Boolean(object.isSuccess);
                    switch (object.error) {
                    case "NO_ERROR":
                    case 0:
                        message.error = 0;
                        break;
                    case "SIGN_NOT_FOUND":
                    case 1:
                        message.error = 1;
                        break;
                    case "INVALID_SIGN":
                    case 2:
                        message.error = 2;
                        break;
                    case "INVALID_BODY":
                    case 3:
                        message.error = 3;
                        break;
                    case "UNKNOWN_ACCOUNT":
                    case 4:
                        message.error = 4;
                        break;
                    case "INTERNAL_ERROR":
                    case 999:
                        message.error = 999;
                        break;
                    }
                    if (object.errorMessage != null)
                        message.errorMessage = String(object.errorMessage);
                    return message;
                };
    
                /**
                 * Creates a plain object from a Resp message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof openGameApi.UpdateGames.Resp
                 * @static
                 * @param {openGameApi.UpdateGames.Resp} message Resp
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Resp.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.isSuccess = false;
                        object.error = options.enums === String ? "NO_ERROR" : 0;
                        object.errorMessage = "";
                    }
                    if (message.isSuccess != null && message.hasOwnProperty("isSuccess"))
                        object.isSuccess = message.isSuccess;
                    if (message.error != null && message.hasOwnProperty("error"))
                        object.error = options.enums === String ? $root.openGameApi.UpdateGames.OpenApiErrorType[message.error] : message.error;
                    if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                        object.errorMessage = message.errorMessage;
                    return object;
                };
    
                /**
                 * Converts this Resp to JSON.
                 * @function toJSON
                 * @memberof openGameApi.UpdateGames.Resp
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Resp.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Resp;
            })();
    
            /**
             * OpenApiErrorType enum.
             * @name openGameApi.UpdateGames.OpenApiErrorType
             * @enum {number}
             * @property {number} NO_ERROR=0 NO_ERROR value
             * @property {number} SIGN_NOT_FOUND=1 SIGN_NOT_FOUND value
             * @property {number} INVALID_SIGN=2 INVALID_SIGN value
             * @property {number} INVALID_BODY=3 INVALID_BODY value
             * @property {number} UNKNOWN_ACCOUNT=4 UNKNOWN_ACCOUNT value
             * @property {number} INTERNAL_ERROR=999 INTERNAL_ERROR value
             */
            UpdateGames.OpenApiErrorType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "NO_ERROR"] = 0;
                values[valuesById[1] = "SIGN_NOT_FOUND"] = 1;
                values[valuesById[2] = "INVALID_SIGN"] = 2;
                values[valuesById[3] = "INVALID_BODY"] = 3;
                values[valuesById[4] = "UNKNOWN_ACCOUNT"] = 4;
                values[valuesById[999] = "INTERNAL_ERROR"] = 999;
                return values;
            })();
    
            return UpdateGames;
        })();
    
        openGameApi.Game = (function() {
    
            /**
             * Properties of a Game.
             * @memberof openGameApi
             * @interface IGame
             * @property {string|null} [id] Game id
             * @property {Array.<openGameApi.Game.ILaunchMethod>|null} [launchMethod] Game launchMethod
             * @property {string|null} [defaultName] Game defaultName
             * @property {string|null} [defaultDescription] Game defaultDescription
             * @property {openGameApi.Game.IImageCollection|null} [icons] Game icons
             * @property {openGameApi.Game.GameType|null} [gameType] Game gameType
             * @property {boolean|null} [hasInternalBonuses] Game hasInternalBonuses
             * @property {Object.<string,openGameApi.Game.ISpinGameDescription>|null} [spinDescription] Game spinDescription
             * @property {Object.<string,openGameApi.Game.IBetDescription>|null} [betDescription] Game betDescription
             * @property {Long|null} [addedAt] Game addedAt
             * @property {Array.<string>|null} [tags] Game tags
             * @property {Object.<string,string>|null} [decodedSymbols] Game decodedSymbols
             * @property {boolean|null} [supportsMaxWin] Game supportsMaxWin
             */
    
            /**
             * Constructs a new Game.
             * @memberof openGameApi
             * @classdesc Represents a Game.
             * @implements IGame
             * @constructor
             * @param {openGameApi.IGame=} [properties] Properties to set
             */
            function Game(properties) {
                this.launchMethod = [];
                this.spinDescription = {};
                this.betDescription = {};
                this.tags = [];
                this.decodedSymbols = {};
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Game id.
             * @member {string} id
             * @memberof openGameApi.Game
             * @instance
             */
            Game.prototype.id = "";
    
            /**
             * Game launchMethod.
             * @member {Array.<openGameApi.Game.ILaunchMethod>} launchMethod
             * @memberof openGameApi.Game
             * @instance
             */
            Game.prototype.launchMethod = $util.emptyArray;
    
            /**
             * Game defaultName.
             * @member {string} defaultName
             * @memberof openGameApi.Game
             * @instance
             */
            Game.prototype.defaultName = "";
    
            /**
             * Game defaultDescription.
             * @member {string} defaultDescription
             * @memberof openGameApi.Game
             * @instance
             */
            Game.prototype.defaultDescription = "";
    
            /**
             * Game icons.
             * @member {openGameApi.Game.IImageCollection|null|undefined} icons
             * @memberof openGameApi.Game
             * @instance
             */
            Game.prototype.icons = null;
    
            /**
             * Game gameType.
             * @member {openGameApi.Game.GameType} gameType
             * @memberof openGameApi.Game
             * @instance
             */
            Game.prototype.gameType = 0;
    
            /**
             * Game hasInternalBonuses.
             * @member {boolean} hasInternalBonuses
             * @memberof openGameApi.Game
             * @instance
             */
            Game.prototype.hasInternalBonuses = false;
    
            /**
             * Game spinDescription.
             * @member {Object.<string,openGameApi.Game.ISpinGameDescription>} spinDescription
             * @memberof openGameApi.Game
             * @instance
             */
            Game.prototype.spinDescription = $util.emptyObject;
    
            /**
             * Game betDescription.
             * @member {Object.<string,openGameApi.Game.IBetDescription>} betDescription
             * @memberof openGameApi.Game
             * @instance
             */
            Game.prototype.betDescription = $util.emptyObject;
    
            /**
             * Game addedAt.
             * @member {Long} addedAt
             * @memberof openGameApi.Game
             * @instance
             */
            Game.prototype.addedAt = $util.Long ? $util.Long.fromBits(0,0,true) : 0;
    
            /**
             * Game tags.
             * @member {Array.<string>} tags
             * @memberof openGameApi.Game
             * @instance
             */
            Game.prototype.tags = $util.emptyArray;
    
            /**
             * Game decodedSymbols.
             * @member {Object.<string,string>} decodedSymbols
             * @memberof openGameApi.Game
             * @instance
             */
            Game.prototype.decodedSymbols = $util.emptyObject;
    
            /**
             * Game supportsMaxWin.
             * @member {boolean} supportsMaxWin
             * @memberof openGameApi.Game
             * @instance
             */
            Game.prototype.supportsMaxWin = false;
    
            /**
             * Creates a new Game instance using the specified properties.
             * @function create
             * @memberof openGameApi.Game
             * @static
             * @param {openGameApi.IGame=} [properties] Properties to set
             * @returns {openGameApi.Game} Game instance
             */
            Game.create = function create(properties) {
                return new Game(properties);
            };
    
            /**
             * Encodes the specified Game message. Does not implicitly {@link openGameApi.Game.verify|verify} messages.
             * @function encode
             * @memberof openGameApi.Game
             * @static
             * @param {openGameApi.IGame} message Game message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Game.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.launchMethod != null && message.launchMethod.length)
                    for (var i = 0; i < message.launchMethod.length; ++i)
                        $root.openGameApi.Game.LaunchMethod.encode(message.launchMethod[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.defaultName != null && Object.hasOwnProperty.call(message, "defaultName"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.defaultName);
                if (message.defaultDescription != null && Object.hasOwnProperty.call(message, "defaultDescription"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.defaultDescription);
                if (message.icons != null && Object.hasOwnProperty.call(message, "icons"))
                    $root.openGameApi.Game.ImageCollection.encode(message.icons, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.gameType != null && Object.hasOwnProperty.call(message, "gameType"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.gameType);
                if (message.hasInternalBonuses != null && Object.hasOwnProperty.call(message, "hasInternalBonuses"))
                    writer.uint32(/* id 9, wireType 0 =*/72).bool(message.hasInternalBonuses);
                if (message.spinDescription != null && Object.hasOwnProperty.call(message, "spinDescription"))
                    for (var keys = Object.keys(message.spinDescription), i = 0; i < keys.length; ++i) {
                        writer.uint32(/* id 10, wireType 2 =*/82).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                        $root.openGameApi.Game.SpinGameDescription.encode(message.spinDescription[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                    }
                if (message.betDescription != null && Object.hasOwnProperty.call(message, "betDescription"))
                    for (var keys = Object.keys(message.betDescription), i = 0; i < keys.length; ++i) {
                        writer.uint32(/* id 11, wireType 2 =*/90).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]);
                        $root.openGameApi.Game.BetDescription.encode(message.betDescription[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                    }
                if (message.addedAt != null && Object.hasOwnProperty.call(message, "addedAt"))
                    writer.uint32(/* id 12, wireType 0 =*/96).uint64(message.addedAt);
                if (message.tags != null && message.tags.length)
                    for (var i = 0; i < message.tags.length; ++i)
                        writer.uint32(/* id 13, wireType 2 =*/106).string(message.tags[i]);
                if (message.decodedSymbols != null && Object.hasOwnProperty.call(message, "decodedSymbols"))
                    for (var keys = Object.keys(message.decodedSymbols), i = 0; i < keys.length; ++i)
                        writer.uint32(/* id 14, wireType 2 =*/114).fork().uint32(/* id 1, wireType 0 =*/8).int32(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.decodedSymbols[keys[i]]).ldelim();
                if (message.supportsMaxWin != null && Object.hasOwnProperty.call(message, "supportsMaxWin"))
                    writer.uint32(/* id 15, wireType 0 =*/120).bool(message.supportsMaxWin);
                return writer;
            };
    
            /**
             * Encodes the specified Game message, length delimited. Does not implicitly {@link openGameApi.Game.verify|verify} messages.
             * @function encodeDelimited
             * @memberof openGameApi.Game
             * @static
             * @param {openGameApi.IGame} message Game message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Game.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a Game message from the specified reader or buffer.
             * @function decode
             * @memberof openGameApi.Game
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {openGameApi.Game} Game
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Game.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.Game(), key, value;
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.string();
                        break;
                    case 2:
                        if (!(message.launchMethod && message.launchMethod.length))
                            message.launchMethod = [];
                        message.launchMethod.push($root.openGameApi.Game.LaunchMethod.decode(reader, reader.uint32()));
                        break;
                    case 3:
                        message.defaultName = reader.string();
                        break;
                    case 4:
                        message.defaultDescription = reader.string();
                        break;
                    case 5:
                        message.icons = $root.openGameApi.Game.ImageCollection.decode(reader, reader.uint32());
                        break;
                    case 8:
                        message.gameType = reader.int32();
                        break;
                    case 9:
                        message.hasInternalBonuses = reader.bool();
                        break;
                    case 10:
                        if (message.spinDescription === $util.emptyObject)
                            message.spinDescription = {};
                        var end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = null;
                        while (reader.pos < end2) {
                            var tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = $root.openGameApi.Game.SpinGameDescription.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.spinDescription[key] = value;
                        break;
                    case 11:
                        if (message.betDescription === $util.emptyObject)
                            message.betDescription = {};
                        var end2 = reader.uint32() + reader.pos;
                        key = "";
                        value = null;
                        while (reader.pos < end2) {
                            var tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.string();
                                break;
                            case 2:
                                value = $root.openGameApi.Game.BetDescription.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.betDescription[key] = value;
                        break;
                    case 12:
                        message.addedAt = reader.uint64();
                        break;
                    case 13:
                        if (!(message.tags && message.tags.length))
                            message.tags = [];
                        message.tags.push(reader.string());
                        break;
                    case 14:
                        if (message.decodedSymbols === $util.emptyObject)
                            message.decodedSymbols = {};
                        var end2 = reader.uint32() + reader.pos;
                        key = 0;
                        value = "";
                        while (reader.pos < end2) {
                            var tag2 = reader.uint32();
                            switch (tag2 >>> 3) {
                            case 1:
                                key = reader.int32();
                                break;
                            case 2:
                                value = reader.string();
                                break;
                            default:
                                reader.skipType(tag2 & 7);
                                break;
                            }
                        }
                        message.decodedSymbols[key] = value;
                        break;
                    case 15:
                        message.supportsMaxWin = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a Game message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof openGameApi.Game
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {openGameApi.Game} Game
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Game.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a Game message.
             * @function verify
             * @memberof openGameApi.Game
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Game.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.launchMethod != null && message.hasOwnProperty("launchMethod")) {
                    if (!Array.isArray(message.launchMethod))
                        return "launchMethod: array expected";
                    for (var i = 0; i < message.launchMethod.length; ++i) {
                        var error = $root.openGameApi.Game.LaunchMethod.verify(message.launchMethod[i]);
                        if (error)
                            return "launchMethod." + error;
                    }
                }
                if (message.defaultName != null && message.hasOwnProperty("defaultName"))
                    if (!$util.isString(message.defaultName))
                        return "defaultName: string expected";
                if (message.defaultDescription != null && message.hasOwnProperty("defaultDescription"))
                    if (!$util.isString(message.defaultDescription))
                        return "defaultDescription: string expected";
                if (message.icons != null && message.hasOwnProperty("icons")) {
                    var error = $root.openGameApi.Game.ImageCollection.verify(message.icons);
                    if (error)
                        return "icons." + error;
                }
                if (message.gameType != null && message.hasOwnProperty("gameType"))
                    switch (message.gameType) {
                    default:
                        return "gameType: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.hasInternalBonuses != null && message.hasOwnProperty("hasInternalBonuses"))
                    if (typeof message.hasInternalBonuses !== "boolean")
                        return "hasInternalBonuses: boolean expected";
                if (message.spinDescription != null && message.hasOwnProperty("spinDescription")) {
                    if (!$util.isObject(message.spinDescription))
                        return "spinDescription: object expected";
                    var key = Object.keys(message.spinDescription);
                    for (var i = 0; i < key.length; ++i) {
                        var error = $root.openGameApi.Game.SpinGameDescription.verify(message.spinDescription[key[i]]);
                        if (error)
                            return "spinDescription." + error;
                    }
                }
                if (message.betDescription != null && message.hasOwnProperty("betDescription")) {
                    if (!$util.isObject(message.betDescription))
                        return "betDescription: object expected";
                    var key = Object.keys(message.betDescription);
                    for (var i = 0; i < key.length; ++i) {
                        var error = $root.openGameApi.Game.BetDescription.verify(message.betDescription[key[i]]);
                        if (error)
                            return "betDescription." + error;
                    }
                }
                if (message.addedAt != null && message.hasOwnProperty("addedAt"))
                    if (!$util.isInteger(message.addedAt) && !(message.addedAt && $util.isInteger(message.addedAt.low) && $util.isInteger(message.addedAt.high)))
                        return "addedAt: integer|Long expected";
                if (message.tags != null && message.hasOwnProperty("tags")) {
                    if (!Array.isArray(message.tags))
                        return "tags: array expected";
                    for (var i = 0; i < message.tags.length; ++i)
                        if (!$util.isString(message.tags[i]))
                            return "tags: string[] expected";
                }
                if (message.decodedSymbols != null && message.hasOwnProperty("decodedSymbols")) {
                    if (!$util.isObject(message.decodedSymbols))
                        return "decodedSymbols: object expected";
                    var key = Object.keys(message.decodedSymbols);
                    for (var i = 0; i < key.length; ++i) {
                        if (!$util.key32Re.test(key[i]))
                            return "decodedSymbols: integer key{k:int32} expected";
                        if (!$util.isString(message.decodedSymbols[key[i]]))
                            return "decodedSymbols: string{k:int32} expected";
                    }
                }
                if (message.supportsMaxWin != null && message.hasOwnProperty("supportsMaxWin"))
                    if (typeof message.supportsMaxWin !== "boolean")
                        return "supportsMaxWin: boolean expected";
                return null;
            };
    
            /**
             * Creates a Game message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof openGameApi.Game
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {openGameApi.Game} Game
             */
            Game.fromObject = function fromObject(object) {
                if (object instanceof $root.openGameApi.Game)
                    return object;
                var message = new $root.openGameApi.Game();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.launchMethod) {
                    if (!Array.isArray(object.launchMethod))
                        throw TypeError(".openGameApi.Game.launchMethod: array expected");
                    message.launchMethod = [];
                    for (var i = 0; i < object.launchMethod.length; ++i) {
                        if (typeof object.launchMethod[i] !== "object")
                            throw TypeError(".openGameApi.Game.launchMethod: object expected");
                        message.launchMethod[i] = $root.openGameApi.Game.LaunchMethod.fromObject(object.launchMethod[i]);
                    }
                }
                if (object.defaultName != null)
                    message.defaultName = String(object.defaultName);
                if (object.defaultDescription != null)
                    message.defaultDescription = String(object.defaultDescription);
                if (object.icons != null) {
                    if (typeof object.icons !== "object")
                        throw TypeError(".openGameApi.Game.icons: object expected");
                    message.icons = $root.openGameApi.Game.ImageCollection.fromObject(object.icons);
                }
                switch (object.gameType) {
                case "SPIN":
                case 0:
                    message.gameType = 0;
                    break;
                case "BET":
                case 1:
                    message.gameType = 1;
                    break;
                }
                if (object.hasInternalBonuses != null)
                    message.hasInternalBonuses = Boolean(object.hasInternalBonuses);
                if (object.spinDescription) {
                    if (typeof object.spinDescription !== "object")
                        throw TypeError(".openGameApi.Game.spinDescription: object expected");
                    message.spinDescription = {};
                    for (var keys = Object.keys(object.spinDescription), i = 0; i < keys.length; ++i) {
                        if (typeof object.spinDescription[keys[i]] !== "object")
                            throw TypeError(".openGameApi.Game.spinDescription: object expected");
                        message.spinDescription[keys[i]] = $root.openGameApi.Game.SpinGameDescription.fromObject(object.spinDescription[keys[i]]);
                    }
                }
                if (object.betDescription) {
                    if (typeof object.betDescription !== "object")
                        throw TypeError(".openGameApi.Game.betDescription: object expected");
                    message.betDescription = {};
                    for (var keys = Object.keys(object.betDescription), i = 0; i < keys.length; ++i) {
                        if (typeof object.betDescription[keys[i]] !== "object")
                            throw TypeError(".openGameApi.Game.betDescription: object expected");
                        message.betDescription[keys[i]] = $root.openGameApi.Game.BetDescription.fromObject(object.betDescription[keys[i]]);
                    }
                }
                if (object.addedAt != null)
                    if ($util.Long)
                        (message.addedAt = $util.Long.fromValue(object.addedAt)).unsigned = true;
                    else if (typeof object.addedAt === "string")
                        message.addedAt = parseInt(object.addedAt, 10);
                    else if (typeof object.addedAt === "number")
                        message.addedAt = object.addedAt;
                    else if (typeof object.addedAt === "object")
                        message.addedAt = new $util.LongBits(object.addedAt.low >>> 0, object.addedAt.high >>> 0).toNumber(true);
                if (object.tags) {
                    if (!Array.isArray(object.tags))
                        throw TypeError(".openGameApi.Game.tags: array expected");
                    message.tags = [];
                    for (var i = 0; i < object.tags.length; ++i)
                        message.tags[i] = String(object.tags[i]);
                }
                if (object.decodedSymbols) {
                    if (typeof object.decodedSymbols !== "object")
                        throw TypeError(".openGameApi.Game.decodedSymbols: object expected");
                    message.decodedSymbols = {};
                    for (var keys = Object.keys(object.decodedSymbols), i = 0; i < keys.length; ++i)
                        message.decodedSymbols[keys[i]] = String(object.decodedSymbols[keys[i]]);
                }
                if (object.supportsMaxWin != null)
                    message.supportsMaxWin = Boolean(object.supportsMaxWin);
                return message;
            };
    
            /**
             * Creates a plain object from a Game message. Also converts values to other types if specified.
             * @function toObject
             * @memberof openGameApi.Game
             * @static
             * @param {openGameApi.Game} message Game
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Game.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.launchMethod = [];
                    object.tags = [];
                }
                if (options.objects || options.defaults) {
                    object.spinDescription = {};
                    object.betDescription = {};
                    object.decodedSymbols = {};
                }
                if (options.defaults) {
                    object.id = "";
                    object.defaultName = "";
                    object.defaultDescription = "";
                    object.icons = null;
                    object.gameType = options.enums === String ? "SPIN" : 0;
                    object.hasInternalBonuses = false;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, true);
                        object.addedAt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.addedAt = options.longs === String ? "0" : 0;
                    object.supportsMaxWin = false;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.launchMethod && message.launchMethod.length) {
                    object.launchMethod = [];
                    for (var j = 0; j < message.launchMethod.length; ++j)
                        object.launchMethod[j] = $root.openGameApi.Game.LaunchMethod.toObject(message.launchMethod[j], options);
                }
                if (message.defaultName != null && message.hasOwnProperty("defaultName"))
                    object.defaultName = message.defaultName;
                if (message.defaultDescription != null && message.hasOwnProperty("defaultDescription"))
                    object.defaultDescription = message.defaultDescription;
                if (message.icons != null && message.hasOwnProperty("icons"))
                    object.icons = $root.openGameApi.Game.ImageCollection.toObject(message.icons, options);
                if (message.gameType != null && message.hasOwnProperty("gameType"))
                    object.gameType = options.enums === String ? $root.openGameApi.Game.GameType[message.gameType] : message.gameType;
                if (message.hasInternalBonuses != null && message.hasOwnProperty("hasInternalBonuses"))
                    object.hasInternalBonuses = message.hasInternalBonuses;
                var keys2;
                if (message.spinDescription && (keys2 = Object.keys(message.spinDescription)).length) {
                    object.spinDescription = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.spinDescription[keys2[j]] = $root.openGameApi.Game.SpinGameDescription.toObject(message.spinDescription[keys2[j]], options);
                }
                if (message.betDescription && (keys2 = Object.keys(message.betDescription)).length) {
                    object.betDescription = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.betDescription[keys2[j]] = $root.openGameApi.Game.BetDescription.toObject(message.betDescription[keys2[j]], options);
                }
                if (message.addedAt != null && message.hasOwnProperty("addedAt"))
                    if (typeof message.addedAt === "number")
                        object.addedAt = options.longs === String ? String(message.addedAt) : message.addedAt;
                    else
                        object.addedAt = options.longs === String ? $util.Long.prototype.toString.call(message.addedAt) : options.longs === Number ? new $util.LongBits(message.addedAt.low >>> 0, message.addedAt.high >>> 0).toNumber(true) : message.addedAt;
                if (message.tags && message.tags.length) {
                    object.tags = [];
                    for (var j = 0; j < message.tags.length; ++j)
                        object.tags[j] = message.tags[j];
                }
                if (message.decodedSymbols && (keys2 = Object.keys(message.decodedSymbols)).length) {
                    object.decodedSymbols = {};
                    for (var j = 0; j < keys2.length; ++j)
                        object.decodedSymbols[keys2[j]] = message.decodedSymbols[keys2[j]];
                }
                if (message.supportsMaxWin != null && message.hasOwnProperty("supportsMaxWin"))
                    object.supportsMaxWin = message.supportsMaxWin;
                return object;
            };
    
            /**
             * Converts this Game to JSON.
             * @function toJSON
             * @memberof openGameApi.Game
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Game.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            /**
             * DeviceType enum.
             * @name openGameApi.Game.DeviceType
             * @enum {number}
             * @property {number} dt_mobile=0 dt_mobile value
             * @property {number} dt_desktop=1 dt_desktop value
             */
            Game.DeviceType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "dt_mobile"] = 0;
                values[valuesById[1] = "dt_desktop"] = 1;
                return values;
            })();
    
            /**
             * DeviceCapabilities enum.
             * @name openGameApi.Game.DeviceCapabilities
             * @enum {number}
             * @property {number} dc_html5full=0 dc_html5full value
             * @property {number} dc_flashplayer=1 dc_flashplayer value
             */
            Game.DeviceCapabilities = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "dc_html5full"] = 0;
                values[valuesById[1] = "dc_flashplayer"] = 1;
                return values;
            })();
    
            Game.LaunchMethod = (function() {
    
                /**
                 * Properties of a LaunchMethod.
                 * @memberof openGameApi.Game
                 * @interface ILaunchMethod
                 * @property {Array.<openGameApi.Game.DeviceCapabilities>|null} [capabilities] LaunchMethod capabilities
                 * @property {Array.<openGameApi.Game.DeviceType>|null} [devices] LaunchMethod devices
                 * @property {number|null} [screenWidth] LaunchMethod screenWidth
                 * @property {number|null} [screenHeight] LaunchMethod screenHeight
                 */
    
                /**
                 * Constructs a new LaunchMethod.
                 * @memberof openGameApi.Game
                 * @classdesc Represents a LaunchMethod.
                 * @implements ILaunchMethod
                 * @constructor
                 * @param {openGameApi.Game.ILaunchMethod=} [properties] Properties to set
                 */
                function LaunchMethod(properties) {
                    this.capabilities = [];
                    this.devices = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * LaunchMethod capabilities.
                 * @member {Array.<openGameApi.Game.DeviceCapabilities>} capabilities
                 * @memberof openGameApi.Game.LaunchMethod
                 * @instance
                 */
                LaunchMethod.prototype.capabilities = $util.emptyArray;
    
                /**
                 * LaunchMethod devices.
                 * @member {Array.<openGameApi.Game.DeviceType>} devices
                 * @memberof openGameApi.Game.LaunchMethod
                 * @instance
                 */
                LaunchMethod.prototype.devices = $util.emptyArray;
    
                /**
                 * LaunchMethod screenWidth.
                 * @member {number} screenWidth
                 * @memberof openGameApi.Game.LaunchMethod
                 * @instance
                 */
                LaunchMethod.prototype.screenWidth = 0;
    
                /**
                 * LaunchMethod screenHeight.
                 * @member {number} screenHeight
                 * @memberof openGameApi.Game.LaunchMethod
                 * @instance
                 */
                LaunchMethod.prototype.screenHeight = 0;
    
                /**
                 * Creates a new LaunchMethod instance using the specified properties.
                 * @function create
                 * @memberof openGameApi.Game.LaunchMethod
                 * @static
                 * @param {openGameApi.Game.ILaunchMethod=} [properties] Properties to set
                 * @returns {openGameApi.Game.LaunchMethod} LaunchMethod instance
                 */
                LaunchMethod.create = function create(properties) {
                    return new LaunchMethod(properties);
                };
    
                /**
                 * Encodes the specified LaunchMethod message. Does not implicitly {@link openGameApi.Game.LaunchMethod.verify|verify} messages.
                 * @function encode
                 * @memberof openGameApi.Game.LaunchMethod
                 * @static
                 * @param {openGameApi.Game.ILaunchMethod} message LaunchMethod message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LaunchMethod.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.capabilities != null && message.capabilities.length) {
                        writer.uint32(/* id 3, wireType 2 =*/26).fork();
                        for (var i = 0; i < message.capabilities.length; ++i)
                            writer.int32(message.capabilities[i]);
                        writer.ldelim();
                    }
                    if (message.devices != null && message.devices.length) {
                        writer.uint32(/* id 4, wireType 2 =*/34).fork();
                        for (var i = 0; i < message.devices.length; ++i)
                            writer.int32(message.devices[i]);
                        writer.ldelim();
                    }
                    if (message.screenWidth != null && Object.hasOwnProperty.call(message, "screenWidth"))
                        writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.screenWidth);
                    if (message.screenHeight != null && Object.hasOwnProperty.call(message, "screenHeight"))
                        writer.uint32(/* id 6, wireType 0 =*/48).uint32(message.screenHeight);
                    return writer;
                };
    
                /**
                 * Encodes the specified LaunchMethod message, length delimited. Does not implicitly {@link openGameApi.Game.LaunchMethod.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof openGameApi.Game.LaunchMethod
                 * @static
                 * @param {openGameApi.Game.ILaunchMethod} message LaunchMethod message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LaunchMethod.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a LaunchMethod message from the specified reader or buffer.
                 * @function decode
                 * @memberof openGameApi.Game.LaunchMethod
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {openGameApi.Game.LaunchMethod} LaunchMethod
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LaunchMethod.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.Game.LaunchMethod();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 3:
                            if (!(message.capabilities && message.capabilities.length))
                                message.capabilities = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.capabilities.push(reader.int32());
                            } else
                                message.capabilities.push(reader.int32());
                            break;
                        case 4:
                            if (!(message.devices && message.devices.length))
                                message.devices = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.devices.push(reader.int32());
                            } else
                                message.devices.push(reader.int32());
                            break;
                        case 5:
                            message.screenWidth = reader.uint32();
                            break;
                        case 6:
                            message.screenHeight = reader.uint32();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a LaunchMethod message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof openGameApi.Game.LaunchMethod
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {openGameApi.Game.LaunchMethod} LaunchMethod
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LaunchMethod.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a LaunchMethod message.
                 * @function verify
                 * @memberof openGameApi.Game.LaunchMethod
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LaunchMethod.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.capabilities != null && message.hasOwnProperty("capabilities")) {
                        if (!Array.isArray(message.capabilities))
                            return "capabilities: array expected";
                        for (var i = 0; i < message.capabilities.length; ++i)
                            switch (message.capabilities[i]) {
                            default:
                                return "capabilities: enum value[] expected";
                            case 0:
                            case 1:
                                break;
                            }
                    }
                    if (message.devices != null && message.hasOwnProperty("devices")) {
                        if (!Array.isArray(message.devices))
                            return "devices: array expected";
                        for (var i = 0; i < message.devices.length; ++i)
                            switch (message.devices[i]) {
                            default:
                                return "devices: enum value[] expected";
                            case 0:
                            case 1:
                                break;
                            }
                    }
                    if (message.screenWidth != null && message.hasOwnProperty("screenWidth"))
                        if (!$util.isInteger(message.screenWidth))
                            return "screenWidth: integer expected";
                    if (message.screenHeight != null && message.hasOwnProperty("screenHeight"))
                        if (!$util.isInteger(message.screenHeight))
                            return "screenHeight: integer expected";
                    return null;
                };
    
                /**
                 * Creates a LaunchMethod message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof openGameApi.Game.LaunchMethod
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {openGameApi.Game.LaunchMethod} LaunchMethod
                 */
                LaunchMethod.fromObject = function fromObject(object) {
                    if (object instanceof $root.openGameApi.Game.LaunchMethod)
                        return object;
                    var message = new $root.openGameApi.Game.LaunchMethod();
                    if (object.capabilities) {
                        if (!Array.isArray(object.capabilities))
                            throw TypeError(".openGameApi.Game.LaunchMethod.capabilities: array expected");
                        message.capabilities = [];
                        for (var i = 0; i < object.capabilities.length; ++i)
                            switch (object.capabilities[i]) {
                            default:
                            case "dc_html5full":
                            case 0:
                                message.capabilities[i] = 0;
                                break;
                            case "dc_flashplayer":
                            case 1:
                                message.capabilities[i] = 1;
                                break;
                            }
                    }
                    if (object.devices) {
                        if (!Array.isArray(object.devices))
                            throw TypeError(".openGameApi.Game.LaunchMethod.devices: array expected");
                        message.devices = [];
                        for (var i = 0; i < object.devices.length; ++i)
                            switch (object.devices[i]) {
                            default:
                            case "dt_mobile":
                            case 0:
                                message.devices[i] = 0;
                                break;
                            case "dt_desktop":
                            case 1:
                                message.devices[i] = 1;
                                break;
                            }
                    }
                    if (object.screenWidth != null)
                        message.screenWidth = object.screenWidth >>> 0;
                    if (object.screenHeight != null)
                        message.screenHeight = object.screenHeight >>> 0;
                    return message;
                };
    
                /**
                 * Creates a plain object from a LaunchMethod message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof openGameApi.Game.LaunchMethod
                 * @static
                 * @param {openGameApi.Game.LaunchMethod} message LaunchMethod
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LaunchMethod.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.capabilities = [];
                        object.devices = [];
                    }
                    if (options.defaults) {
                        object.screenWidth = 0;
                        object.screenHeight = 0;
                    }
                    if (message.capabilities && message.capabilities.length) {
                        object.capabilities = [];
                        for (var j = 0; j < message.capabilities.length; ++j)
                            object.capabilities[j] = options.enums === String ? $root.openGameApi.Game.DeviceCapabilities[message.capabilities[j]] : message.capabilities[j];
                    }
                    if (message.devices && message.devices.length) {
                        object.devices = [];
                        for (var j = 0; j < message.devices.length; ++j)
                            object.devices[j] = options.enums === String ? $root.openGameApi.Game.DeviceType[message.devices[j]] : message.devices[j];
                    }
                    if (message.screenWidth != null && message.hasOwnProperty("screenWidth"))
                        object.screenWidth = message.screenWidth;
                    if (message.screenHeight != null && message.hasOwnProperty("screenHeight"))
                        object.screenHeight = message.screenHeight;
                    return object;
                };
    
                /**
                 * Converts this LaunchMethod to JSON.
                 * @function toJSON
                 * @memberof openGameApi.Game.LaunchMethod
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LaunchMethod.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return LaunchMethod;
            })();
    
            Game.ImageCollection = (function() {
    
                /**
                 * Properties of an ImageCollection.
                 * @memberof openGameApi.Game
                 * @interface IImageCollection
                 * @property {string|null} [link_500x500] ImageCollection link_500x500
                 * @property {string|null} [link_300x300] ImageCollection link_300x300
                 * @property {string|null} [link_300x188] ImageCollection link_300x188
                 * @property {string|null} [link_40x40] ImageCollection link_40x40
                 */
    
                /**
                 * Constructs a new ImageCollection.
                 * @memberof openGameApi.Game
                 * @classdesc Represents an ImageCollection.
                 * @implements IImageCollection
                 * @constructor
                 * @param {openGameApi.Game.IImageCollection=} [properties] Properties to set
                 */
                function ImageCollection(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * ImageCollection link_500x500.
                 * @member {string} link_500x500
                 * @memberof openGameApi.Game.ImageCollection
                 * @instance
                 */
                ImageCollection.prototype.link_500x500 = "";
    
                /**
                 * ImageCollection link_300x300.
                 * @member {string} link_300x300
                 * @memberof openGameApi.Game.ImageCollection
                 * @instance
                 */
                ImageCollection.prototype.link_300x300 = "";
    
                /**
                 * ImageCollection link_300x188.
                 * @member {string} link_300x188
                 * @memberof openGameApi.Game.ImageCollection
                 * @instance
                 */
                ImageCollection.prototype.link_300x188 = "";
    
                /**
                 * ImageCollection link_40x40.
                 * @member {string} link_40x40
                 * @memberof openGameApi.Game.ImageCollection
                 * @instance
                 */
                ImageCollection.prototype.link_40x40 = "";
    
                /**
                 * Creates a new ImageCollection instance using the specified properties.
                 * @function create
                 * @memberof openGameApi.Game.ImageCollection
                 * @static
                 * @param {openGameApi.Game.IImageCollection=} [properties] Properties to set
                 * @returns {openGameApi.Game.ImageCollection} ImageCollection instance
                 */
                ImageCollection.create = function create(properties) {
                    return new ImageCollection(properties);
                };
    
                /**
                 * Encodes the specified ImageCollection message. Does not implicitly {@link openGameApi.Game.ImageCollection.verify|verify} messages.
                 * @function encode
                 * @memberof openGameApi.Game.ImageCollection
                 * @static
                 * @param {openGameApi.Game.IImageCollection} message ImageCollection message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ImageCollection.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.link_500x500 != null && Object.hasOwnProperty.call(message, "link_500x500"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.link_500x500);
                    if (message.link_300x300 != null && Object.hasOwnProperty.call(message, "link_300x300"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.link_300x300);
                    if (message.link_300x188 != null && Object.hasOwnProperty.call(message, "link_300x188"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.link_300x188);
                    if (message.link_40x40 != null && Object.hasOwnProperty.call(message, "link_40x40"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.link_40x40);
                    return writer;
                };
    
                /**
                 * Encodes the specified ImageCollection message, length delimited. Does not implicitly {@link openGameApi.Game.ImageCollection.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof openGameApi.Game.ImageCollection
                 * @static
                 * @param {openGameApi.Game.IImageCollection} message ImageCollection message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ImageCollection.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an ImageCollection message from the specified reader or buffer.
                 * @function decode
                 * @memberof openGameApi.Game.ImageCollection
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {openGameApi.Game.ImageCollection} ImageCollection
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ImageCollection.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.Game.ImageCollection();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.link_500x500 = reader.string();
                            break;
                        case 2:
                            message.link_300x300 = reader.string();
                            break;
                        case 3:
                            message.link_300x188 = reader.string();
                            break;
                        case 4:
                            message.link_40x40 = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an ImageCollection message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof openGameApi.Game.ImageCollection
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {openGameApi.Game.ImageCollection} ImageCollection
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ImageCollection.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an ImageCollection message.
                 * @function verify
                 * @memberof openGameApi.Game.ImageCollection
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ImageCollection.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.link_500x500 != null && message.hasOwnProperty("link_500x500"))
                        if (!$util.isString(message.link_500x500))
                            return "link_500x500: string expected";
                    if (message.link_300x300 != null && message.hasOwnProperty("link_300x300"))
                        if (!$util.isString(message.link_300x300))
                            return "link_300x300: string expected";
                    if (message.link_300x188 != null && message.hasOwnProperty("link_300x188"))
                        if (!$util.isString(message.link_300x188))
                            return "link_300x188: string expected";
                    if (message.link_40x40 != null && message.hasOwnProperty("link_40x40"))
                        if (!$util.isString(message.link_40x40))
                            return "link_40x40: string expected";
                    return null;
                };
    
                /**
                 * Creates an ImageCollection message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof openGameApi.Game.ImageCollection
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {openGameApi.Game.ImageCollection} ImageCollection
                 */
                ImageCollection.fromObject = function fromObject(object) {
                    if (object instanceof $root.openGameApi.Game.ImageCollection)
                        return object;
                    var message = new $root.openGameApi.Game.ImageCollection();
                    if (object.link_500x500 != null)
                        message.link_500x500 = String(object.link_500x500);
                    if (object.link_300x300 != null)
                        message.link_300x300 = String(object.link_300x300);
                    if (object.link_300x188 != null)
                        message.link_300x188 = String(object.link_300x188);
                    if (object.link_40x40 != null)
                        message.link_40x40 = String(object.link_40x40);
                    return message;
                };
    
                /**
                 * Creates a plain object from an ImageCollection message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof openGameApi.Game.ImageCollection
                 * @static
                 * @param {openGameApi.Game.ImageCollection} message ImageCollection
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ImageCollection.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.link_500x500 = "";
                        object.link_300x300 = "";
                        object.link_300x188 = "";
                        object.link_40x40 = "";
                    }
                    if (message.link_500x500 != null && message.hasOwnProperty("link_500x500"))
                        object.link_500x500 = message.link_500x500;
                    if (message.link_300x300 != null && message.hasOwnProperty("link_300x300"))
                        object.link_300x300 = message.link_300x300;
                    if (message.link_300x188 != null && message.hasOwnProperty("link_300x188"))
                        object.link_300x188 = message.link_300x188;
                    if (message.link_40x40 != null && message.hasOwnProperty("link_40x40"))
                        object.link_40x40 = message.link_40x40;
                    return object;
                };
    
                /**
                 * Converts this ImageCollection to JSON.
                 * @function toJSON
                 * @memberof openGameApi.Game.ImageCollection
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ImageCollection.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return ImageCollection;
            })();
    
            Game.SpinGameDescription = (function() {
    
                /**
                 * Properties of a SpinGameDescription.
                 * @memberof openGameApi.Game
                 * @interface ISpinGameDescription
                 * @property {Array.<number>|null} [lines] SpinGameDescription lines
                 * @property {Array.<number>|null} [bets] SpinGameDescription bets
                 * @property {openGameApi.Game.SpinGameDescription.IFreeSpins|null} [freeSpins] SpinGameDescription freeSpins
                 */
    
                /**
                 * Constructs a new SpinGameDescription.
                 * @memberof openGameApi.Game
                 * @classdesc Represents a SpinGameDescription.
                 * @implements ISpinGameDescription
                 * @constructor
                 * @param {openGameApi.Game.ISpinGameDescription=} [properties] Properties to set
                 */
                function SpinGameDescription(properties) {
                    this.lines = [];
                    this.bets = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * SpinGameDescription lines.
                 * @member {Array.<number>} lines
                 * @memberof openGameApi.Game.SpinGameDescription
                 * @instance
                 */
                SpinGameDescription.prototype.lines = $util.emptyArray;
    
                /**
                 * SpinGameDescription bets.
                 * @member {Array.<number>} bets
                 * @memberof openGameApi.Game.SpinGameDescription
                 * @instance
                 */
                SpinGameDescription.prototype.bets = $util.emptyArray;
    
                /**
                 * SpinGameDescription freeSpins.
                 * @member {openGameApi.Game.SpinGameDescription.IFreeSpins|null|undefined} freeSpins
                 * @memberof openGameApi.Game.SpinGameDescription
                 * @instance
                 */
                SpinGameDescription.prototype.freeSpins = null;
    
                /**
                 * Creates a new SpinGameDescription instance using the specified properties.
                 * @function create
                 * @memberof openGameApi.Game.SpinGameDescription
                 * @static
                 * @param {openGameApi.Game.ISpinGameDescription=} [properties] Properties to set
                 * @returns {openGameApi.Game.SpinGameDescription} SpinGameDescription instance
                 */
                SpinGameDescription.create = function create(properties) {
                    return new SpinGameDescription(properties);
                };
    
                /**
                 * Encodes the specified SpinGameDescription message. Does not implicitly {@link openGameApi.Game.SpinGameDescription.verify|verify} messages.
                 * @function encode
                 * @memberof openGameApi.Game.SpinGameDescription
                 * @static
                 * @param {openGameApi.Game.ISpinGameDescription} message SpinGameDescription message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SpinGameDescription.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.lines != null && message.lines.length) {
                        writer.uint32(/* id 1, wireType 2 =*/10).fork();
                        for (var i = 0; i < message.lines.length; ++i)
                            writer.uint32(message.lines[i]);
                        writer.ldelim();
                    }
                    if (message.bets != null && message.bets.length) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork();
                        for (var i = 0; i < message.bets.length; ++i)
                            writer.uint32(message.bets[i]);
                        writer.ldelim();
                    }
                    if (message.freeSpins != null && Object.hasOwnProperty.call(message, "freeSpins"))
                        $root.openGameApi.Game.SpinGameDescription.FreeSpins.encode(message.freeSpins, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified SpinGameDescription message, length delimited. Does not implicitly {@link openGameApi.Game.SpinGameDescription.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof openGameApi.Game.SpinGameDescription
                 * @static
                 * @param {openGameApi.Game.ISpinGameDescription} message SpinGameDescription message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SpinGameDescription.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a SpinGameDescription message from the specified reader or buffer.
                 * @function decode
                 * @memberof openGameApi.Game.SpinGameDescription
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {openGameApi.Game.SpinGameDescription} SpinGameDescription
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SpinGameDescription.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.Game.SpinGameDescription();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.lines && message.lines.length))
                                message.lines = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.lines.push(reader.uint32());
                            } else
                                message.lines.push(reader.uint32());
                            break;
                        case 2:
                            if (!(message.bets && message.bets.length))
                                message.bets = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.bets.push(reader.uint32());
                            } else
                                message.bets.push(reader.uint32());
                            break;
                        case 3:
                            message.freeSpins = $root.openGameApi.Game.SpinGameDescription.FreeSpins.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a SpinGameDescription message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof openGameApi.Game.SpinGameDescription
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {openGameApi.Game.SpinGameDescription} SpinGameDescription
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SpinGameDescription.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a SpinGameDescription message.
                 * @function verify
                 * @memberof openGameApi.Game.SpinGameDescription
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SpinGameDescription.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.lines != null && message.hasOwnProperty("lines")) {
                        if (!Array.isArray(message.lines))
                            return "lines: array expected";
                        for (var i = 0; i < message.lines.length; ++i)
                            if (!$util.isInteger(message.lines[i]))
                                return "lines: integer[] expected";
                    }
                    if (message.bets != null && message.hasOwnProperty("bets")) {
                        if (!Array.isArray(message.bets))
                            return "bets: array expected";
                        for (var i = 0; i < message.bets.length; ++i)
                            if (!$util.isInteger(message.bets[i]))
                                return "bets: integer[] expected";
                    }
                    if (message.freeSpins != null && message.hasOwnProperty("freeSpins")) {
                        var error = $root.openGameApi.Game.SpinGameDescription.FreeSpins.verify(message.freeSpins);
                        if (error)
                            return "freeSpins." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a SpinGameDescription message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof openGameApi.Game.SpinGameDescription
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {openGameApi.Game.SpinGameDescription} SpinGameDescription
                 */
                SpinGameDescription.fromObject = function fromObject(object) {
                    if (object instanceof $root.openGameApi.Game.SpinGameDescription)
                        return object;
                    var message = new $root.openGameApi.Game.SpinGameDescription();
                    if (object.lines) {
                        if (!Array.isArray(object.lines))
                            throw TypeError(".openGameApi.Game.SpinGameDescription.lines: array expected");
                        message.lines = [];
                        for (var i = 0; i < object.lines.length; ++i)
                            message.lines[i] = object.lines[i] >>> 0;
                    }
                    if (object.bets) {
                        if (!Array.isArray(object.bets))
                            throw TypeError(".openGameApi.Game.SpinGameDescription.bets: array expected");
                        message.bets = [];
                        for (var i = 0; i < object.bets.length; ++i)
                            message.bets[i] = object.bets[i] >>> 0;
                    }
                    if (object.freeSpins != null) {
                        if (typeof object.freeSpins !== "object")
                            throw TypeError(".openGameApi.Game.SpinGameDescription.freeSpins: object expected");
                        message.freeSpins = $root.openGameApi.Game.SpinGameDescription.FreeSpins.fromObject(object.freeSpins);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a SpinGameDescription message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof openGameApi.Game.SpinGameDescription
                 * @static
                 * @param {openGameApi.Game.SpinGameDescription} message SpinGameDescription
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SpinGameDescription.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.lines = [];
                        object.bets = [];
                    }
                    if (options.defaults)
                        object.freeSpins = null;
                    if (message.lines && message.lines.length) {
                        object.lines = [];
                        for (var j = 0; j < message.lines.length; ++j)
                            object.lines[j] = message.lines[j];
                    }
                    if (message.bets && message.bets.length) {
                        object.bets = [];
                        for (var j = 0; j < message.bets.length; ++j)
                            object.bets[j] = message.bets[j];
                    }
                    if (message.freeSpins != null && message.hasOwnProperty("freeSpins"))
                        object.freeSpins = $root.openGameApi.Game.SpinGameDescription.FreeSpins.toObject(message.freeSpins, options);
                    return object;
                };
    
                /**
                 * Converts this SpinGameDescription to JSON.
                 * @function toJSON
                 * @memberof openGameApi.Game.SpinGameDescription
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SpinGameDescription.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                SpinGameDescription.FreeSpins = (function() {
    
                    /**
                     * Properties of a FreeSpins.
                     * @memberof openGameApi.Game.SpinGameDescription
                     * @interface IFreeSpins
                     * @property {boolean|null} [enabled] FreeSpins enabled
                     * @property {Array.<number>|null} [lines] FreeSpins lines
                     * @property {Array.<number>|null} [bets] FreeSpins bets
                     * @property {Array.<number>|null} [multipliers] FreeSpins multipliers
                     */
    
                    /**
                     * Constructs a new FreeSpins.
                     * @memberof openGameApi.Game.SpinGameDescription
                     * @classdesc Represents a FreeSpins.
                     * @implements IFreeSpins
                     * @constructor
                     * @param {openGameApi.Game.SpinGameDescription.IFreeSpins=} [properties] Properties to set
                     */
                    function FreeSpins(properties) {
                        this.lines = [];
                        this.bets = [];
                        this.multipliers = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * FreeSpins enabled.
                     * @member {boolean} enabled
                     * @memberof openGameApi.Game.SpinGameDescription.FreeSpins
                     * @instance
                     */
                    FreeSpins.prototype.enabled = false;
    
                    /**
                     * FreeSpins lines.
                     * @member {Array.<number>} lines
                     * @memberof openGameApi.Game.SpinGameDescription.FreeSpins
                     * @instance
                     */
                    FreeSpins.prototype.lines = $util.emptyArray;
    
                    /**
                     * FreeSpins bets.
                     * @member {Array.<number>} bets
                     * @memberof openGameApi.Game.SpinGameDescription.FreeSpins
                     * @instance
                     */
                    FreeSpins.prototype.bets = $util.emptyArray;
    
                    /**
                     * FreeSpins multipliers.
                     * @member {Array.<number>} multipliers
                     * @memberof openGameApi.Game.SpinGameDescription.FreeSpins
                     * @instance
                     */
                    FreeSpins.prototype.multipliers = $util.emptyArray;
    
                    /**
                     * Creates a new FreeSpins instance using the specified properties.
                     * @function create
                     * @memberof openGameApi.Game.SpinGameDescription.FreeSpins
                     * @static
                     * @param {openGameApi.Game.SpinGameDescription.IFreeSpins=} [properties] Properties to set
                     * @returns {openGameApi.Game.SpinGameDescription.FreeSpins} FreeSpins instance
                     */
                    FreeSpins.create = function create(properties) {
                        return new FreeSpins(properties);
                    };
    
                    /**
                     * Encodes the specified FreeSpins message. Does not implicitly {@link openGameApi.Game.SpinGameDescription.FreeSpins.verify|verify} messages.
                     * @function encode
                     * @memberof openGameApi.Game.SpinGameDescription.FreeSpins
                     * @static
                     * @param {openGameApi.Game.SpinGameDescription.IFreeSpins} message FreeSpins message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FreeSpins.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
                            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.enabled);
                        if (message.lines != null && message.lines.length) {
                            writer.uint32(/* id 2, wireType 2 =*/18).fork();
                            for (var i = 0; i < message.lines.length; ++i)
                                writer.uint32(message.lines[i]);
                            writer.ldelim();
                        }
                        if (message.bets != null && message.bets.length) {
                            writer.uint32(/* id 3, wireType 2 =*/26).fork();
                            for (var i = 0; i < message.bets.length; ++i)
                                writer.uint32(message.bets[i]);
                            writer.ldelim();
                        }
                        if (message.multipliers != null && message.multipliers.length) {
                            writer.uint32(/* id 4, wireType 2 =*/34).fork();
                            for (var i = 0; i < message.multipliers.length; ++i)
                                writer.uint32(message.multipliers[i]);
                            writer.ldelim();
                        }
                        return writer;
                    };
    
                    /**
                     * Encodes the specified FreeSpins message, length delimited. Does not implicitly {@link openGameApi.Game.SpinGameDescription.FreeSpins.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof openGameApi.Game.SpinGameDescription.FreeSpins
                     * @static
                     * @param {openGameApi.Game.SpinGameDescription.IFreeSpins} message FreeSpins message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FreeSpins.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a FreeSpins message from the specified reader or buffer.
                     * @function decode
                     * @memberof openGameApi.Game.SpinGameDescription.FreeSpins
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {openGameApi.Game.SpinGameDescription.FreeSpins} FreeSpins
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FreeSpins.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.Game.SpinGameDescription.FreeSpins();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.enabled = reader.bool();
                                break;
                            case 2:
                                if (!(message.lines && message.lines.length))
                                    message.lines = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.lines.push(reader.uint32());
                                } else
                                    message.lines.push(reader.uint32());
                                break;
                            case 3:
                                if (!(message.bets && message.bets.length))
                                    message.bets = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.bets.push(reader.uint32());
                                } else
                                    message.bets.push(reader.uint32());
                                break;
                            case 4:
                                if (!(message.multipliers && message.multipliers.length))
                                    message.multipliers = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.multipliers.push(reader.uint32());
                                } else
                                    message.multipliers.push(reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a FreeSpins message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof openGameApi.Game.SpinGameDescription.FreeSpins
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {openGameApi.Game.SpinGameDescription.FreeSpins} FreeSpins
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FreeSpins.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a FreeSpins message.
                     * @function verify
                     * @memberof openGameApi.Game.SpinGameDescription.FreeSpins
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FreeSpins.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.enabled != null && message.hasOwnProperty("enabled"))
                            if (typeof message.enabled !== "boolean")
                                return "enabled: boolean expected";
                        if (message.lines != null && message.hasOwnProperty("lines")) {
                            if (!Array.isArray(message.lines))
                                return "lines: array expected";
                            for (var i = 0; i < message.lines.length; ++i)
                                if (!$util.isInteger(message.lines[i]))
                                    return "lines: integer[] expected";
                        }
                        if (message.bets != null && message.hasOwnProperty("bets")) {
                            if (!Array.isArray(message.bets))
                                return "bets: array expected";
                            for (var i = 0; i < message.bets.length; ++i)
                                if (!$util.isInteger(message.bets[i]))
                                    return "bets: integer[] expected";
                        }
                        if (message.multipliers != null && message.hasOwnProperty("multipliers")) {
                            if (!Array.isArray(message.multipliers))
                                return "multipliers: array expected";
                            for (var i = 0; i < message.multipliers.length; ++i)
                                if (!$util.isInteger(message.multipliers[i]))
                                    return "multipliers: integer[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a FreeSpins message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof openGameApi.Game.SpinGameDescription.FreeSpins
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {openGameApi.Game.SpinGameDescription.FreeSpins} FreeSpins
                     */
                    FreeSpins.fromObject = function fromObject(object) {
                        if (object instanceof $root.openGameApi.Game.SpinGameDescription.FreeSpins)
                            return object;
                        var message = new $root.openGameApi.Game.SpinGameDescription.FreeSpins();
                        if (object.enabled != null)
                            message.enabled = Boolean(object.enabled);
                        if (object.lines) {
                            if (!Array.isArray(object.lines))
                                throw TypeError(".openGameApi.Game.SpinGameDescription.FreeSpins.lines: array expected");
                            message.lines = [];
                            for (var i = 0; i < object.lines.length; ++i)
                                message.lines[i] = object.lines[i] >>> 0;
                        }
                        if (object.bets) {
                            if (!Array.isArray(object.bets))
                                throw TypeError(".openGameApi.Game.SpinGameDescription.FreeSpins.bets: array expected");
                            message.bets = [];
                            for (var i = 0; i < object.bets.length; ++i)
                                message.bets[i] = object.bets[i] >>> 0;
                        }
                        if (object.multipliers) {
                            if (!Array.isArray(object.multipliers))
                                throw TypeError(".openGameApi.Game.SpinGameDescription.FreeSpins.multipliers: array expected");
                            message.multipliers = [];
                            for (var i = 0; i < object.multipliers.length; ++i)
                                message.multipliers[i] = object.multipliers[i] >>> 0;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a FreeSpins message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof openGameApi.Game.SpinGameDescription.FreeSpins
                     * @static
                     * @param {openGameApi.Game.SpinGameDescription.FreeSpins} message FreeSpins
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FreeSpins.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.lines = [];
                            object.bets = [];
                            object.multipliers = [];
                        }
                        if (options.defaults)
                            object.enabled = false;
                        if (message.enabled != null && message.hasOwnProperty("enabled"))
                            object.enabled = message.enabled;
                        if (message.lines && message.lines.length) {
                            object.lines = [];
                            for (var j = 0; j < message.lines.length; ++j)
                                object.lines[j] = message.lines[j];
                        }
                        if (message.bets && message.bets.length) {
                            object.bets = [];
                            for (var j = 0; j < message.bets.length; ++j)
                                object.bets[j] = message.bets[j];
                        }
                        if (message.multipliers && message.multipliers.length) {
                            object.multipliers = [];
                            for (var j = 0; j < message.multipliers.length; ++j)
                                object.multipliers[j] = message.multipliers[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this FreeSpins to JSON.
                     * @function toJSON
                     * @memberof openGameApi.Game.SpinGameDescription.FreeSpins
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FreeSpins.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return FreeSpins;
                })();
    
                return SpinGameDescription;
            })();
    
            Game.BetDescription = (function() {
    
                /**
                 * Properties of a BetDescription.
                 * @memberof openGameApi.Game
                 * @interface IBetDescription
                 * @property {Array.<number>|null} [bets] BetDescription bets
                 * @property {openGameApi.Game.BetDescription.IFreeBets|null} [freeBets] BetDescription freeBets
                 */
    
                /**
                 * Constructs a new BetDescription.
                 * @memberof openGameApi.Game
                 * @classdesc Represents a BetDescription.
                 * @implements IBetDescription
                 * @constructor
                 * @param {openGameApi.Game.IBetDescription=} [properties] Properties to set
                 */
                function BetDescription(properties) {
                    this.bets = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * BetDescription bets.
                 * @member {Array.<number>} bets
                 * @memberof openGameApi.Game.BetDescription
                 * @instance
                 */
                BetDescription.prototype.bets = $util.emptyArray;
    
                /**
                 * BetDescription freeBets.
                 * @member {openGameApi.Game.BetDescription.IFreeBets|null|undefined} freeBets
                 * @memberof openGameApi.Game.BetDescription
                 * @instance
                 */
                BetDescription.prototype.freeBets = null;
    
                /**
                 * Creates a new BetDescription instance using the specified properties.
                 * @function create
                 * @memberof openGameApi.Game.BetDescription
                 * @static
                 * @param {openGameApi.Game.IBetDescription=} [properties] Properties to set
                 * @returns {openGameApi.Game.BetDescription} BetDescription instance
                 */
                BetDescription.create = function create(properties) {
                    return new BetDescription(properties);
                };
    
                /**
                 * Encodes the specified BetDescription message. Does not implicitly {@link openGameApi.Game.BetDescription.verify|verify} messages.
                 * @function encode
                 * @memberof openGameApi.Game.BetDescription
                 * @static
                 * @param {openGameApi.Game.IBetDescription} message BetDescription message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BetDescription.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.bets != null && message.bets.length) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork();
                        for (var i = 0; i < message.bets.length; ++i)
                            writer.uint32(message.bets[i]);
                        writer.ldelim();
                    }
                    if (message.freeBets != null && Object.hasOwnProperty.call(message, "freeBets"))
                        $root.openGameApi.Game.BetDescription.FreeBets.encode(message.freeBets, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified BetDescription message, length delimited. Does not implicitly {@link openGameApi.Game.BetDescription.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof openGameApi.Game.BetDescription
                 * @static
                 * @param {openGameApi.Game.IBetDescription} message BetDescription message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BetDescription.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a BetDescription message from the specified reader or buffer.
                 * @function decode
                 * @memberof openGameApi.Game.BetDescription
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {openGameApi.Game.BetDescription} BetDescription
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BetDescription.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.Game.BetDescription();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2:
                            if (!(message.bets && message.bets.length))
                                message.bets = [];
                            if ((tag & 7) === 2) {
                                var end2 = reader.uint32() + reader.pos;
                                while (reader.pos < end2)
                                    message.bets.push(reader.uint32());
                            } else
                                message.bets.push(reader.uint32());
                            break;
                        case 3:
                            message.freeBets = $root.openGameApi.Game.BetDescription.FreeBets.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a BetDescription message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof openGameApi.Game.BetDescription
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {openGameApi.Game.BetDescription} BetDescription
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BetDescription.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a BetDescription message.
                 * @function verify
                 * @memberof openGameApi.Game.BetDescription
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BetDescription.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.bets != null && message.hasOwnProperty("bets")) {
                        if (!Array.isArray(message.bets))
                            return "bets: array expected";
                        for (var i = 0; i < message.bets.length; ++i)
                            if (!$util.isInteger(message.bets[i]))
                                return "bets: integer[] expected";
                    }
                    if (message.freeBets != null && message.hasOwnProperty("freeBets")) {
                        var error = $root.openGameApi.Game.BetDescription.FreeBets.verify(message.freeBets);
                        if (error)
                            return "freeBets." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a BetDescription message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof openGameApi.Game.BetDescription
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {openGameApi.Game.BetDescription} BetDescription
                 */
                BetDescription.fromObject = function fromObject(object) {
                    if (object instanceof $root.openGameApi.Game.BetDescription)
                        return object;
                    var message = new $root.openGameApi.Game.BetDescription();
                    if (object.bets) {
                        if (!Array.isArray(object.bets))
                            throw TypeError(".openGameApi.Game.BetDescription.bets: array expected");
                        message.bets = [];
                        for (var i = 0; i < object.bets.length; ++i)
                            message.bets[i] = object.bets[i] >>> 0;
                    }
                    if (object.freeBets != null) {
                        if (typeof object.freeBets !== "object")
                            throw TypeError(".openGameApi.Game.BetDescription.freeBets: object expected");
                        message.freeBets = $root.openGameApi.Game.BetDescription.FreeBets.fromObject(object.freeBets);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a BetDescription message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof openGameApi.Game.BetDescription
                 * @static
                 * @param {openGameApi.Game.BetDescription} message BetDescription
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BetDescription.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.bets = [];
                    if (options.defaults)
                        object.freeBets = null;
                    if (message.bets && message.bets.length) {
                        object.bets = [];
                        for (var j = 0; j < message.bets.length; ++j)
                            object.bets[j] = message.bets[j];
                    }
                    if (message.freeBets != null && message.hasOwnProperty("freeBets"))
                        object.freeBets = $root.openGameApi.Game.BetDescription.FreeBets.toObject(message.freeBets, options);
                    return object;
                };
    
                /**
                 * Converts this BetDescription to JSON.
                 * @function toJSON
                 * @memberof openGameApi.Game.BetDescription
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BetDescription.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                BetDescription.FreeBets = (function() {
    
                    /**
                     * Properties of a FreeBets.
                     * @memberof openGameApi.Game.BetDescription
                     * @interface IFreeBets
                     * @property {boolean|null} [enabled] FreeBets enabled
                     * @property {Array.<number>|null} [bets] FreeBets bets
                     * @property {Array.<number>|null} [multipliers] FreeBets multipliers
                     */
    
                    /**
                     * Constructs a new FreeBets.
                     * @memberof openGameApi.Game.BetDescription
                     * @classdesc Represents a FreeBets.
                     * @implements IFreeBets
                     * @constructor
                     * @param {openGameApi.Game.BetDescription.IFreeBets=} [properties] Properties to set
                     */
                    function FreeBets(properties) {
                        this.bets = [];
                        this.multipliers = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }
    
                    /**
                     * FreeBets enabled.
                     * @member {boolean} enabled
                     * @memberof openGameApi.Game.BetDescription.FreeBets
                     * @instance
                     */
                    FreeBets.prototype.enabled = false;
    
                    /**
                     * FreeBets bets.
                     * @member {Array.<number>} bets
                     * @memberof openGameApi.Game.BetDescription.FreeBets
                     * @instance
                     */
                    FreeBets.prototype.bets = $util.emptyArray;
    
                    /**
                     * FreeBets multipliers.
                     * @member {Array.<number>} multipliers
                     * @memberof openGameApi.Game.BetDescription.FreeBets
                     * @instance
                     */
                    FreeBets.prototype.multipliers = $util.emptyArray;
    
                    /**
                     * Creates a new FreeBets instance using the specified properties.
                     * @function create
                     * @memberof openGameApi.Game.BetDescription.FreeBets
                     * @static
                     * @param {openGameApi.Game.BetDescription.IFreeBets=} [properties] Properties to set
                     * @returns {openGameApi.Game.BetDescription.FreeBets} FreeBets instance
                     */
                    FreeBets.create = function create(properties) {
                        return new FreeBets(properties);
                    };
    
                    /**
                     * Encodes the specified FreeBets message. Does not implicitly {@link openGameApi.Game.BetDescription.FreeBets.verify|verify} messages.
                     * @function encode
                     * @memberof openGameApi.Game.BetDescription.FreeBets
                     * @static
                     * @param {openGameApi.Game.BetDescription.IFreeBets} message FreeBets message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FreeBets.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.enabled != null && Object.hasOwnProperty.call(message, "enabled"))
                            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.enabled);
                        if (message.bets != null && message.bets.length) {
                            writer.uint32(/* id 3, wireType 2 =*/26).fork();
                            for (var i = 0; i < message.bets.length; ++i)
                                writer.uint32(message.bets[i]);
                            writer.ldelim();
                        }
                        if (message.multipliers != null && message.multipliers.length) {
                            writer.uint32(/* id 4, wireType 2 =*/34).fork();
                            for (var i = 0; i < message.multipliers.length; ++i)
                                writer.uint32(message.multipliers[i]);
                            writer.ldelim();
                        }
                        return writer;
                    };
    
                    /**
                     * Encodes the specified FreeBets message, length delimited. Does not implicitly {@link openGameApi.Game.BetDescription.FreeBets.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof openGameApi.Game.BetDescription.FreeBets
                     * @static
                     * @param {openGameApi.Game.BetDescription.IFreeBets} message FreeBets message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FreeBets.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };
    
                    /**
                     * Decodes a FreeBets message from the specified reader or buffer.
                     * @function decode
                     * @memberof openGameApi.Game.BetDescription.FreeBets
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {openGameApi.Game.BetDescription.FreeBets} FreeBets
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FreeBets.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.Game.BetDescription.FreeBets();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.enabled = reader.bool();
                                break;
                            case 3:
                                if (!(message.bets && message.bets.length))
                                    message.bets = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.bets.push(reader.uint32());
                                } else
                                    message.bets.push(reader.uint32());
                                break;
                            case 4:
                                if (!(message.multipliers && message.multipliers.length))
                                    message.multipliers = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.multipliers.push(reader.uint32());
                                } else
                                    message.multipliers.push(reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };
    
                    /**
                     * Decodes a FreeBets message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof openGameApi.Game.BetDescription.FreeBets
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {openGameApi.Game.BetDescription.FreeBets} FreeBets
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FreeBets.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };
    
                    /**
                     * Verifies a FreeBets message.
                     * @function verify
                     * @memberof openGameApi.Game.BetDescription.FreeBets
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FreeBets.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.enabled != null && message.hasOwnProperty("enabled"))
                            if (typeof message.enabled !== "boolean")
                                return "enabled: boolean expected";
                        if (message.bets != null && message.hasOwnProperty("bets")) {
                            if (!Array.isArray(message.bets))
                                return "bets: array expected";
                            for (var i = 0; i < message.bets.length; ++i)
                                if (!$util.isInteger(message.bets[i]))
                                    return "bets: integer[] expected";
                        }
                        if (message.multipliers != null && message.hasOwnProperty("multipliers")) {
                            if (!Array.isArray(message.multipliers))
                                return "multipliers: array expected";
                            for (var i = 0; i < message.multipliers.length; ++i)
                                if (!$util.isInteger(message.multipliers[i]))
                                    return "multipliers: integer[] expected";
                        }
                        return null;
                    };
    
                    /**
                     * Creates a FreeBets message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof openGameApi.Game.BetDescription.FreeBets
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {openGameApi.Game.BetDescription.FreeBets} FreeBets
                     */
                    FreeBets.fromObject = function fromObject(object) {
                        if (object instanceof $root.openGameApi.Game.BetDescription.FreeBets)
                            return object;
                        var message = new $root.openGameApi.Game.BetDescription.FreeBets();
                        if (object.enabled != null)
                            message.enabled = Boolean(object.enabled);
                        if (object.bets) {
                            if (!Array.isArray(object.bets))
                                throw TypeError(".openGameApi.Game.BetDescription.FreeBets.bets: array expected");
                            message.bets = [];
                            for (var i = 0; i < object.bets.length; ++i)
                                message.bets[i] = object.bets[i] >>> 0;
                        }
                        if (object.multipliers) {
                            if (!Array.isArray(object.multipliers))
                                throw TypeError(".openGameApi.Game.BetDescription.FreeBets.multipliers: array expected");
                            message.multipliers = [];
                            for (var i = 0; i < object.multipliers.length; ++i)
                                message.multipliers[i] = object.multipliers[i] >>> 0;
                        }
                        return message;
                    };
    
                    /**
                     * Creates a plain object from a FreeBets message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof openGameApi.Game.BetDescription.FreeBets
                     * @static
                     * @param {openGameApi.Game.BetDescription.FreeBets} message FreeBets
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FreeBets.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.bets = [];
                            object.multipliers = [];
                        }
                        if (options.defaults)
                            object.enabled = false;
                        if (message.enabled != null && message.hasOwnProperty("enabled"))
                            object.enabled = message.enabled;
                        if (message.bets && message.bets.length) {
                            object.bets = [];
                            for (var j = 0; j < message.bets.length; ++j)
                                object.bets[j] = message.bets[j];
                        }
                        if (message.multipliers && message.multipliers.length) {
                            object.multipliers = [];
                            for (var j = 0; j < message.multipliers.length; ++j)
                                object.multipliers[j] = message.multipliers[j];
                        }
                        return object;
                    };
    
                    /**
                     * Converts this FreeBets to JSON.
                     * @function toJSON
                     * @memberof openGameApi.Game.BetDescription.FreeBets
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FreeBets.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };
    
                    return FreeBets;
                })();
    
                return BetDescription;
            })();
    
            /**
             * GameType enum.
             * @name openGameApi.Game.GameType
             * @enum {number}
             * @property {number} SPIN=0 SPIN value
             * @property {number} BET=1 BET value
             */
            Game.GameType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SPIN"] = 0;
                values[valuesById[1] = "BET"] = 1;
                return values;
            })();
    
            return Game;
        })();
    
        openGameApi.CreateProvider = (function() {
    
            /**
             * Properties of a CreateProvider.
             * @memberof openGameApi
             * @interface ICreateProvider
             */
    
            /**
             * Constructs a new CreateProvider.
             * @memberof openGameApi
             * @classdesc Represents a CreateProvider.
             * @implements ICreateProvider
             * @constructor
             * @param {openGameApi.ICreateProvider=} [properties] Properties to set
             */
            function CreateProvider(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new CreateProvider instance using the specified properties.
             * @function create
             * @memberof openGameApi.CreateProvider
             * @static
             * @param {openGameApi.ICreateProvider=} [properties] Properties to set
             * @returns {openGameApi.CreateProvider} CreateProvider instance
             */
            CreateProvider.create = function create(properties) {
                return new CreateProvider(properties);
            };
    
            /**
             * Encodes the specified CreateProvider message. Does not implicitly {@link openGameApi.CreateProvider.verify|verify} messages.
             * @function encode
             * @memberof openGameApi.CreateProvider
             * @static
             * @param {openGameApi.ICreateProvider} message CreateProvider message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateProvider.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified CreateProvider message, length delimited. Does not implicitly {@link openGameApi.CreateProvider.verify|verify} messages.
             * @function encodeDelimited
             * @memberof openGameApi.CreateProvider
             * @static
             * @param {openGameApi.ICreateProvider} message CreateProvider message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateProvider.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes a CreateProvider message from the specified reader or buffer.
             * @function decode
             * @memberof openGameApi.CreateProvider
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {openGameApi.CreateProvider} CreateProvider
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateProvider.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.CreateProvider();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes a CreateProvider message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof openGameApi.CreateProvider
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {openGameApi.CreateProvider} CreateProvider
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateProvider.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies a CreateProvider message.
             * @function verify
             * @memberof openGameApi.CreateProvider
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateProvider.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates a CreateProvider message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof openGameApi.CreateProvider
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {openGameApi.CreateProvider} CreateProvider
             */
            CreateProvider.fromObject = function fromObject(object) {
                if (object instanceof $root.openGameApi.CreateProvider)
                    return object;
                return new $root.openGameApi.CreateProvider();
            };
    
            /**
             * Creates a plain object from a CreateProvider message. Also converts values to other types if specified.
             * @function toObject
             * @memberof openGameApi.CreateProvider
             * @static
             * @param {openGameApi.CreateProvider} message CreateProvider
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateProvider.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this CreateProvider to JSON.
             * @function toJSON
             * @memberof openGameApi.CreateProvider
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateProvider.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            CreateProvider.Req = (function() {
    
                /**
                 * Properties of a Req.
                 * @memberof openGameApi.CreateProvider
                 * @interface IReq
                 * @property {string|null} [name] Req name
                 * @property {string|null} [prefix] Req prefix
                 * @property {boolean|null} [isProd] Req isProd
                 */
    
                /**
                 * Constructs a new Req.
                 * @memberof openGameApi.CreateProvider
                 * @classdesc Represents a Req.
                 * @implements IReq
                 * @constructor
                 * @param {openGameApi.CreateProvider.IReq=} [properties] Properties to set
                 */
                function Req(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Req name.
                 * @member {string} name
                 * @memberof openGameApi.CreateProvider.Req
                 * @instance
                 */
                Req.prototype.name = "";
    
                /**
                 * Req prefix.
                 * @member {string} prefix
                 * @memberof openGameApi.CreateProvider.Req
                 * @instance
                 */
                Req.prototype.prefix = "";
    
                /**
                 * Req isProd.
                 * @member {boolean} isProd
                 * @memberof openGameApi.CreateProvider.Req
                 * @instance
                 */
                Req.prototype.isProd = false;
    
                /**
                 * Creates a new Req instance using the specified properties.
                 * @function create
                 * @memberof openGameApi.CreateProvider.Req
                 * @static
                 * @param {openGameApi.CreateProvider.IReq=} [properties] Properties to set
                 * @returns {openGameApi.CreateProvider.Req} Req instance
                 */
                Req.create = function create(properties) {
                    return new Req(properties);
                };
    
                /**
                 * Encodes the specified Req message. Does not implicitly {@link openGameApi.CreateProvider.Req.verify|verify} messages.
                 * @function encode
                 * @memberof openGameApi.CreateProvider.Req
                 * @static
                 * @param {openGameApi.CreateProvider.IReq} message Req message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Req.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.prefix != null && Object.hasOwnProperty.call(message, "prefix"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.prefix);
                    if (message.isProd != null && Object.hasOwnProperty.call(message, "isProd"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isProd);
                    return writer;
                };
    
                /**
                 * Encodes the specified Req message, length delimited. Does not implicitly {@link openGameApi.CreateProvider.Req.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof openGameApi.CreateProvider.Req
                 * @static
                 * @param {openGameApi.CreateProvider.IReq} message Req message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Req.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Req message from the specified reader or buffer.
                 * @function decode
                 * @memberof openGameApi.CreateProvider.Req
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {openGameApi.CreateProvider.Req} Req
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Req.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.CreateProvider.Req();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.name = reader.string();
                            break;
                        case 2:
                            message.prefix = reader.string();
                            break;
                        case 3:
                            message.isProd = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Req message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof openGameApi.CreateProvider.Req
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {openGameApi.CreateProvider.Req} Req
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Req.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Req message.
                 * @function verify
                 * @memberof openGameApi.CreateProvider.Req
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Req.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.prefix != null && message.hasOwnProperty("prefix"))
                        if (!$util.isString(message.prefix))
                            return "prefix: string expected";
                    if (message.isProd != null && message.hasOwnProperty("isProd"))
                        if (typeof message.isProd !== "boolean")
                            return "isProd: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a Req message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof openGameApi.CreateProvider.Req
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {openGameApi.CreateProvider.Req} Req
                 */
                Req.fromObject = function fromObject(object) {
                    if (object instanceof $root.openGameApi.CreateProvider.Req)
                        return object;
                    var message = new $root.openGameApi.CreateProvider.Req();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.prefix != null)
                        message.prefix = String(object.prefix);
                    if (object.isProd != null)
                        message.isProd = Boolean(object.isProd);
                    return message;
                };
    
                /**
                 * Creates a plain object from a Req message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof openGameApi.CreateProvider.Req
                 * @static
                 * @param {openGameApi.CreateProvider.Req} message Req
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Req.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.prefix = "";
                        object.isProd = false;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.prefix != null && message.hasOwnProperty("prefix"))
                        object.prefix = message.prefix;
                    if (message.isProd != null && message.hasOwnProperty("isProd"))
                        object.isProd = message.isProd;
                    return object;
                };
    
                /**
                 * Converts this Req to JSON.
                 * @function toJSON
                 * @memberof openGameApi.CreateProvider.Req
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Req.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Req;
            })();
    
            CreateProvider.Resp = (function() {
    
                /**
                 * Properties of a Resp.
                 * @memberof openGameApi.CreateProvider
                 * @interface IResp
                 * @property {boolean|null} [ok] Resp ok
                 * @property {string|null} [contentProviderId] Resp contentProviderId
                 * @property {string|null} [secret] Resp secret
                 * @property {string|null} [apiEndpoint] Resp apiEndpoint
                 * @property {openGameApi.CreateProvider.ITestUser|null} [testUserForProvider] Resp testUserForProvider
                 * @property {openGameApi.CreateProvider.ITestUser|null} [testUserForQa] Resp testUserForQa
                 * @property {boolean|null} [alreadyExists] Resp alreadyExists
                 */
    
                /**
                 * Constructs a new Resp.
                 * @memberof openGameApi.CreateProvider
                 * @classdesc Represents a Resp.
                 * @implements IResp
                 * @constructor
                 * @param {openGameApi.CreateProvider.IResp=} [properties] Properties to set
                 */
                function Resp(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Resp ok.
                 * @member {boolean} ok
                 * @memberof openGameApi.CreateProvider.Resp
                 * @instance
                 */
                Resp.prototype.ok = false;
    
                /**
                 * Resp contentProviderId.
                 * @member {string} contentProviderId
                 * @memberof openGameApi.CreateProvider.Resp
                 * @instance
                 */
                Resp.prototype.contentProviderId = "";
    
                /**
                 * Resp secret.
                 * @member {string} secret
                 * @memberof openGameApi.CreateProvider.Resp
                 * @instance
                 */
                Resp.prototype.secret = "";
    
                /**
                 * Resp apiEndpoint.
                 * @member {string} apiEndpoint
                 * @memberof openGameApi.CreateProvider.Resp
                 * @instance
                 */
                Resp.prototype.apiEndpoint = "";
    
                /**
                 * Resp testUserForProvider.
                 * @member {openGameApi.CreateProvider.ITestUser|null|undefined} testUserForProvider
                 * @memberof openGameApi.CreateProvider.Resp
                 * @instance
                 */
                Resp.prototype.testUserForProvider = null;
    
                /**
                 * Resp testUserForQa.
                 * @member {openGameApi.CreateProvider.ITestUser|null|undefined} testUserForQa
                 * @memberof openGameApi.CreateProvider.Resp
                 * @instance
                 */
                Resp.prototype.testUserForQa = null;
    
                /**
                 * Resp alreadyExists.
                 * @member {boolean} alreadyExists
                 * @memberof openGameApi.CreateProvider.Resp
                 * @instance
                 */
                Resp.prototype.alreadyExists = false;
    
                /**
                 * Creates a new Resp instance using the specified properties.
                 * @function create
                 * @memberof openGameApi.CreateProvider.Resp
                 * @static
                 * @param {openGameApi.CreateProvider.IResp=} [properties] Properties to set
                 * @returns {openGameApi.CreateProvider.Resp} Resp instance
                 */
                Resp.create = function create(properties) {
                    return new Resp(properties);
                };
    
                /**
                 * Encodes the specified Resp message. Does not implicitly {@link openGameApi.CreateProvider.Resp.verify|verify} messages.
                 * @function encode
                 * @memberof openGameApi.CreateProvider.Resp
                 * @static
                 * @param {openGameApi.CreateProvider.IResp} message Resp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Resp.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ok != null && Object.hasOwnProperty.call(message, "ok"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.ok);
                    if (message.contentProviderId != null && Object.hasOwnProperty.call(message, "contentProviderId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.contentProviderId);
                    if (message.secret != null && Object.hasOwnProperty.call(message, "secret"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.secret);
                    if (message.apiEndpoint != null && Object.hasOwnProperty.call(message, "apiEndpoint"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.apiEndpoint);
                    if (message.testUserForProvider != null && Object.hasOwnProperty.call(message, "testUserForProvider"))
                        $root.openGameApi.CreateProvider.TestUser.encode(message.testUserForProvider, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.testUserForQa != null && Object.hasOwnProperty.call(message, "testUserForQa"))
                        $root.openGameApi.CreateProvider.TestUser.encode(message.testUserForQa, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.alreadyExists != null && Object.hasOwnProperty.call(message, "alreadyExists"))
                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.alreadyExists);
                    return writer;
                };
    
                /**
                 * Encodes the specified Resp message, length delimited. Does not implicitly {@link openGameApi.CreateProvider.Resp.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof openGameApi.CreateProvider.Resp
                 * @static
                 * @param {openGameApi.CreateProvider.IResp} message Resp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Resp.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Resp message from the specified reader or buffer.
                 * @function decode
                 * @memberof openGameApi.CreateProvider.Resp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {openGameApi.CreateProvider.Resp} Resp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Resp.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.CreateProvider.Resp();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.ok = reader.bool();
                            break;
                        case 2:
                            message.contentProviderId = reader.string();
                            break;
                        case 3:
                            message.secret = reader.string();
                            break;
                        case 4:
                            message.apiEndpoint = reader.string();
                            break;
                        case 5:
                            message.testUserForProvider = $root.openGameApi.CreateProvider.TestUser.decode(reader, reader.uint32());
                            break;
                        case 6:
                            message.testUserForQa = $root.openGameApi.CreateProvider.TestUser.decode(reader, reader.uint32());
                            break;
                        case 7:
                            message.alreadyExists = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Resp message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof openGameApi.CreateProvider.Resp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {openGameApi.CreateProvider.Resp} Resp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Resp.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Resp message.
                 * @function verify
                 * @memberof openGameApi.CreateProvider.Resp
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Resp.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ok != null && message.hasOwnProperty("ok"))
                        if (typeof message.ok !== "boolean")
                            return "ok: boolean expected";
                    if (message.contentProviderId != null && message.hasOwnProperty("contentProviderId"))
                        if (!$util.isString(message.contentProviderId))
                            return "contentProviderId: string expected";
                    if (message.secret != null && message.hasOwnProperty("secret"))
                        if (!$util.isString(message.secret))
                            return "secret: string expected";
                    if (message.apiEndpoint != null && message.hasOwnProperty("apiEndpoint"))
                        if (!$util.isString(message.apiEndpoint))
                            return "apiEndpoint: string expected";
                    if (message.testUserForProvider != null && message.hasOwnProperty("testUserForProvider")) {
                        var error = $root.openGameApi.CreateProvider.TestUser.verify(message.testUserForProvider);
                        if (error)
                            return "testUserForProvider." + error;
                    }
                    if (message.testUserForQa != null && message.hasOwnProperty("testUserForQa")) {
                        var error = $root.openGameApi.CreateProvider.TestUser.verify(message.testUserForQa);
                        if (error)
                            return "testUserForQa." + error;
                    }
                    if (message.alreadyExists != null && message.hasOwnProperty("alreadyExists"))
                        if (typeof message.alreadyExists !== "boolean")
                            return "alreadyExists: boolean expected";
                    return null;
                };
    
                /**
                 * Creates a Resp message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof openGameApi.CreateProvider.Resp
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {openGameApi.CreateProvider.Resp} Resp
                 */
                Resp.fromObject = function fromObject(object) {
                    if (object instanceof $root.openGameApi.CreateProvider.Resp)
                        return object;
                    var message = new $root.openGameApi.CreateProvider.Resp();
                    if (object.ok != null)
                        message.ok = Boolean(object.ok);
                    if (object.contentProviderId != null)
                        message.contentProviderId = String(object.contentProviderId);
                    if (object.secret != null)
                        message.secret = String(object.secret);
                    if (object.apiEndpoint != null)
                        message.apiEndpoint = String(object.apiEndpoint);
                    if (object.testUserForProvider != null) {
                        if (typeof object.testUserForProvider !== "object")
                            throw TypeError(".openGameApi.CreateProvider.Resp.testUserForProvider: object expected");
                        message.testUserForProvider = $root.openGameApi.CreateProvider.TestUser.fromObject(object.testUserForProvider);
                    }
                    if (object.testUserForQa != null) {
                        if (typeof object.testUserForQa !== "object")
                            throw TypeError(".openGameApi.CreateProvider.Resp.testUserForQa: object expected");
                        message.testUserForQa = $root.openGameApi.CreateProvider.TestUser.fromObject(object.testUserForQa);
                    }
                    if (object.alreadyExists != null)
                        message.alreadyExists = Boolean(object.alreadyExists);
                    return message;
                };
    
                /**
                 * Creates a plain object from a Resp message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof openGameApi.CreateProvider.Resp
                 * @static
                 * @param {openGameApi.CreateProvider.Resp} message Resp
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Resp.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.ok = false;
                        object.contentProviderId = "";
                        object.secret = "";
                        object.apiEndpoint = "";
                        object.testUserForProvider = null;
                        object.testUserForQa = null;
                        object.alreadyExists = false;
                    }
                    if (message.ok != null && message.hasOwnProperty("ok"))
                        object.ok = message.ok;
                    if (message.contentProviderId != null && message.hasOwnProperty("contentProviderId"))
                        object.contentProviderId = message.contentProviderId;
                    if (message.secret != null && message.hasOwnProperty("secret"))
                        object.secret = message.secret;
                    if (message.apiEndpoint != null && message.hasOwnProperty("apiEndpoint"))
                        object.apiEndpoint = message.apiEndpoint;
                    if (message.testUserForProvider != null && message.hasOwnProperty("testUserForProvider"))
                        object.testUserForProvider = $root.openGameApi.CreateProvider.TestUser.toObject(message.testUserForProvider, options);
                    if (message.testUserForQa != null && message.hasOwnProperty("testUserForQa"))
                        object.testUserForQa = $root.openGameApi.CreateProvider.TestUser.toObject(message.testUserForQa, options);
                    if (message.alreadyExists != null && message.hasOwnProperty("alreadyExists"))
                        object.alreadyExists = message.alreadyExists;
                    return object;
                };
    
                /**
                 * Converts this Resp to JSON.
                 * @function toJSON
                 * @memberof openGameApi.CreateProvider.Resp
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Resp.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Resp;
            })();
    
            CreateProvider.TestUser = (function() {
    
                /**
                 * Properties of a TestUser.
                 * @memberof openGameApi.CreateProvider
                 * @interface ITestUser
                 * @property {string|null} [authUrl] TestUser authUrl
                 */
    
                /**
                 * Constructs a new TestUser.
                 * @memberof openGameApi.CreateProvider
                 * @classdesc Represents a TestUser.
                 * @implements ITestUser
                 * @constructor
                 * @param {openGameApi.CreateProvider.ITestUser=} [properties] Properties to set
                 */
                function TestUser(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * TestUser authUrl.
                 * @member {string} authUrl
                 * @memberof openGameApi.CreateProvider.TestUser
                 * @instance
                 */
                TestUser.prototype.authUrl = "";
    
                /**
                 * Creates a new TestUser instance using the specified properties.
                 * @function create
                 * @memberof openGameApi.CreateProvider.TestUser
                 * @static
                 * @param {openGameApi.CreateProvider.ITestUser=} [properties] Properties to set
                 * @returns {openGameApi.CreateProvider.TestUser} TestUser instance
                 */
                TestUser.create = function create(properties) {
                    return new TestUser(properties);
                };
    
                /**
                 * Encodes the specified TestUser message. Does not implicitly {@link openGameApi.CreateProvider.TestUser.verify|verify} messages.
                 * @function encode
                 * @memberof openGameApi.CreateProvider.TestUser
                 * @static
                 * @param {openGameApi.CreateProvider.ITestUser} message TestUser message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TestUser.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.authUrl != null && Object.hasOwnProperty.call(message, "authUrl"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.authUrl);
                    return writer;
                };
    
                /**
                 * Encodes the specified TestUser message, length delimited. Does not implicitly {@link openGameApi.CreateProvider.TestUser.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof openGameApi.CreateProvider.TestUser
                 * @static
                 * @param {openGameApi.CreateProvider.ITestUser} message TestUser message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TestUser.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a TestUser message from the specified reader or buffer.
                 * @function decode
                 * @memberof openGameApi.CreateProvider.TestUser
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {openGameApi.CreateProvider.TestUser} TestUser
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TestUser.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.CreateProvider.TestUser();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.authUrl = reader.string();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a TestUser message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof openGameApi.CreateProvider.TestUser
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {openGameApi.CreateProvider.TestUser} TestUser
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TestUser.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a TestUser message.
                 * @function verify
                 * @memberof openGameApi.CreateProvider.TestUser
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TestUser.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.authUrl != null && message.hasOwnProperty("authUrl"))
                        if (!$util.isString(message.authUrl))
                            return "authUrl: string expected";
                    return null;
                };
    
                /**
                 * Creates a TestUser message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof openGameApi.CreateProvider.TestUser
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {openGameApi.CreateProvider.TestUser} TestUser
                 */
                TestUser.fromObject = function fromObject(object) {
                    if (object instanceof $root.openGameApi.CreateProvider.TestUser)
                        return object;
                    var message = new $root.openGameApi.CreateProvider.TestUser();
                    if (object.authUrl != null)
                        message.authUrl = String(object.authUrl);
                    return message;
                };
    
                /**
                 * Creates a plain object from a TestUser message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof openGameApi.CreateProvider.TestUser
                 * @static
                 * @param {openGameApi.CreateProvider.TestUser} message TestUser
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TestUser.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.authUrl = "";
                    if (message.authUrl != null && message.hasOwnProperty("authUrl"))
                        object.authUrl = message.authUrl;
                    return object;
                };
    
                /**
                 * Converts this TestUser to JSON.
                 * @function toJSON
                 * @memberof openGameApi.CreateProvider.TestUser
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TestUser.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return TestUser;
            })();
    
            return CreateProvider;
        })();
    
        openGameApi.AccountComments = (function() {
    
            /**
             * Properties of an AccountComments.
             * @memberof openGameApi
             * @interface IAccountComments
             */
    
            /**
             * Constructs a new AccountComments.
             * @memberof openGameApi
             * @classdesc Represents an AccountComments.
             * @implements IAccountComments
             * @constructor
             * @param {openGameApi.IAccountComments=} [properties] Properties to set
             */
            function AccountComments(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }
    
            /**
             * Creates a new AccountComments instance using the specified properties.
             * @function create
             * @memberof openGameApi.AccountComments
             * @static
             * @param {openGameApi.IAccountComments=} [properties] Properties to set
             * @returns {openGameApi.AccountComments} AccountComments instance
             */
            AccountComments.create = function create(properties) {
                return new AccountComments(properties);
            };
    
            /**
             * Encodes the specified AccountComments message. Does not implicitly {@link openGameApi.AccountComments.verify|verify} messages.
             * @function encode
             * @memberof openGameApi.AccountComments
             * @static
             * @param {openGameApi.IAccountComments} message AccountComments message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AccountComments.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };
    
            /**
             * Encodes the specified AccountComments message, length delimited. Does not implicitly {@link openGameApi.AccountComments.verify|verify} messages.
             * @function encodeDelimited
             * @memberof openGameApi.AccountComments
             * @static
             * @param {openGameApi.IAccountComments} message AccountComments message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AccountComments.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };
    
            /**
             * Decodes an AccountComments message from the specified reader or buffer.
             * @function decode
             * @memberof openGameApi.AccountComments
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {openGameApi.AccountComments} AccountComments
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AccountComments.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.AccountComments();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };
    
            /**
             * Decodes an AccountComments message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof openGameApi.AccountComments
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {openGameApi.AccountComments} AccountComments
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AccountComments.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };
    
            /**
             * Verifies an AccountComments message.
             * @function verify
             * @memberof openGameApi.AccountComments
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AccountComments.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };
    
            /**
             * Creates an AccountComments message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof openGameApi.AccountComments
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {openGameApi.AccountComments} AccountComments
             */
            AccountComments.fromObject = function fromObject(object) {
                if (object instanceof $root.openGameApi.AccountComments)
                    return object;
                return new $root.openGameApi.AccountComments();
            };
    
            /**
             * Creates a plain object from an AccountComments message. Also converts values to other types if specified.
             * @function toObject
             * @memberof openGameApi.AccountComments
             * @static
             * @param {openGameApi.AccountComments} message AccountComments
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AccountComments.toObject = function toObject() {
                return {};
            };
    
            /**
             * Converts this AccountComments to JSON.
             * @function toJSON
             * @memberof openGameApi.AccountComments
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AccountComments.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };
    
            AccountComments.AccountCommentsData = (function() {
    
                /**
                 * Properties of an AccountCommentsData.
                 * @memberof openGameApi.AccountComments
                 * @interface IAccountCommentsData
                 * @property {number|null} [accountId] AccountCommentsData accountId
                 * @property {string|null} [comments] AccountCommentsData comments
                 * @property {string|null} [providerName] AccountCommentsData providerName
                 * @property {boolean|null} [isTest] AccountCommentsData isTest
                 */
    
                /**
                 * Constructs a new AccountCommentsData.
                 * @memberof openGameApi.AccountComments
                 * @classdesc Represents an AccountCommentsData.
                 * @implements IAccountCommentsData
                 * @constructor
                 * @param {openGameApi.AccountComments.IAccountCommentsData=} [properties] Properties to set
                 */
                function AccountCommentsData(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * AccountCommentsData accountId.
                 * @member {number} accountId
                 * @memberof openGameApi.AccountComments.AccountCommentsData
                 * @instance
                 */
                AccountCommentsData.prototype.accountId = 0;
    
                /**
                 * AccountCommentsData comments.
                 * @member {string} comments
                 * @memberof openGameApi.AccountComments.AccountCommentsData
                 * @instance
                 */
                AccountCommentsData.prototype.comments = "";
    
                /**
                 * AccountCommentsData providerName.
                 * @member {string} providerName
                 * @memberof openGameApi.AccountComments.AccountCommentsData
                 * @instance
                 */
                AccountCommentsData.prototype.providerName = "";
    
                /**
                 * AccountCommentsData isTest.
                 * @member {boolean} isTest
                 * @memberof openGameApi.AccountComments.AccountCommentsData
                 * @instance
                 */
                AccountCommentsData.prototype.isTest = false;
    
                /**
                 * Creates a new AccountCommentsData instance using the specified properties.
                 * @function create
                 * @memberof openGameApi.AccountComments.AccountCommentsData
                 * @static
                 * @param {openGameApi.AccountComments.IAccountCommentsData=} [properties] Properties to set
                 * @returns {openGameApi.AccountComments.AccountCommentsData} AccountCommentsData instance
                 */
                AccountCommentsData.create = function create(properties) {
                    return new AccountCommentsData(properties);
                };
    
                /**
                 * Encodes the specified AccountCommentsData message. Does not implicitly {@link openGameApi.AccountComments.AccountCommentsData.verify|verify} messages.
                 * @function encode
                 * @memberof openGameApi.AccountComments.AccountCommentsData
                 * @static
                 * @param {openGameApi.AccountComments.IAccountCommentsData} message AccountCommentsData message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AccountCommentsData.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.accountId != null && Object.hasOwnProperty.call(message, "accountId"))
                        writer.uint32(/* id 1, wireType 0 =*/8).uint32(message.accountId);
                    if (message.comments != null && Object.hasOwnProperty.call(message, "comments"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.comments);
                    if (message.providerName != null && Object.hasOwnProperty.call(message, "providerName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.providerName);
                    if (message.isTest != null && Object.hasOwnProperty.call(message, "isTest"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isTest);
                    return writer;
                };
    
                /**
                 * Encodes the specified AccountCommentsData message, length delimited. Does not implicitly {@link openGameApi.AccountComments.AccountCommentsData.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof openGameApi.AccountComments.AccountCommentsData
                 * @static
                 * @param {openGameApi.AccountComments.IAccountCommentsData} message AccountCommentsData message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AccountCommentsData.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes an AccountCommentsData message from the specified reader or buffer.
                 * @function decode
                 * @memberof openGameApi.AccountComments.AccountCommentsData
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {openGameApi.AccountComments.AccountCommentsData} AccountCommentsData
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AccountCommentsData.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.AccountComments.AccountCommentsData();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.accountId = reader.uint32();
                            break;
                        case 2:
                            message.comments = reader.string();
                            break;
                        case 3:
                            message.providerName = reader.string();
                            break;
                        case 4:
                            message.isTest = reader.bool();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes an AccountCommentsData message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof openGameApi.AccountComments.AccountCommentsData
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {openGameApi.AccountComments.AccountCommentsData} AccountCommentsData
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AccountCommentsData.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies an AccountCommentsData message.
                 * @function verify
                 * @memberof openGameApi.AccountComments.AccountCommentsData
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AccountCommentsData.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.accountId != null && message.hasOwnProperty("accountId"))
                        if (!$util.isInteger(message.accountId))
                            return "accountId: integer expected";
                    if (message.comments != null && message.hasOwnProperty("comments"))
                        if (!$util.isString(message.comments))
                            return "comments: string expected";
                    if (message.providerName != null && message.hasOwnProperty("providerName"))
                        if (!$util.isString(message.providerName))
                            return "providerName: string expected";
                    if (message.isTest != null && message.hasOwnProperty("isTest"))
                        if (typeof message.isTest !== "boolean")
                            return "isTest: boolean expected";
                    return null;
                };
    
                /**
                 * Creates an AccountCommentsData message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof openGameApi.AccountComments.AccountCommentsData
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {openGameApi.AccountComments.AccountCommentsData} AccountCommentsData
                 */
                AccountCommentsData.fromObject = function fromObject(object) {
                    if (object instanceof $root.openGameApi.AccountComments.AccountCommentsData)
                        return object;
                    var message = new $root.openGameApi.AccountComments.AccountCommentsData();
                    if (object.accountId != null)
                        message.accountId = object.accountId >>> 0;
                    if (object.comments != null)
                        message.comments = String(object.comments);
                    if (object.providerName != null)
                        message.providerName = String(object.providerName);
                    if (object.isTest != null)
                        message.isTest = Boolean(object.isTest);
                    return message;
                };
    
                /**
                 * Creates a plain object from an AccountCommentsData message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof openGameApi.AccountComments.AccountCommentsData
                 * @static
                 * @param {openGameApi.AccountComments.AccountCommentsData} message AccountCommentsData
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AccountCommentsData.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.accountId = 0;
                        object.comments = "";
                        object.providerName = "";
                        object.isTest = false;
                    }
                    if (message.accountId != null && message.hasOwnProperty("accountId"))
                        object.accountId = message.accountId;
                    if (message.comments != null && message.hasOwnProperty("comments"))
                        object.comments = message.comments;
                    if (message.providerName != null && message.hasOwnProperty("providerName"))
                        object.providerName = message.providerName;
                    if (message.isTest != null && message.hasOwnProperty("isTest"))
                        object.isTest = message.isTest;
                    return object;
                };
    
                /**
                 * Converts this AccountCommentsData to JSON.
                 * @function toJSON
                 * @memberof openGameApi.AccountComments.AccountCommentsData
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AccountCommentsData.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return AccountCommentsData;
            })();
    
            AccountComments.Req = (function() {
    
                /**
                 * Properties of a Req.
                 * @memberof openGameApi.AccountComments
                 * @interface IReq
                 * @property {openGameApi.AccountComments.IAccountCommentsData|null} [data] Req data
                 */
    
                /**
                 * Constructs a new Req.
                 * @memberof openGameApi.AccountComments
                 * @classdesc Represents a Req.
                 * @implements IReq
                 * @constructor
                 * @param {openGameApi.AccountComments.IReq=} [properties] Properties to set
                 */
                function Req(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Req data.
                 * @member {openGameApi.AccountComments.IAccountCommentsData|null|undefined} data
                 * @memberof openGameApi.AccountComments.Req
                 * @instance
                 */
                Req.prototype.data = null;
    
                /**
                 * Creates a new Req instance using the specified properties.
                 * @function create
                 * @memberof openGameApi.AccountComments.Req
                 * @static
                 * @param {openGameApi.AccountComments.IReq=} [properties] Properties to set
                 * @returns {openGameApi.AccountComments.Req} Req instance
                 */
                Req.create = function create(properties) {
                    return new Req(properties);
                };
    
                /**
                 * Encodes the specified Req message. Does not implicitly {@link openGameApi.AccountComments.Req.verify|verify} messages.
                 * @function encode
                 * @memberof openGameApi.AccountComments.Req
                 * @static
                 * @param {openGameApi.AccountComments.IReq} message Req message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Req.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                        $root.openGameApi.AccountComments.AccountCommentsData.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Req message, length delimited. Does not implicitly {@link openGameApi.AccountComments.Req.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof openGameApi.AccountComments.Req
                 * @static
                 * @param {openGameApi.AccountComments.IReq} message Req message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Req.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Req message from the specified reader or buffer.
                 * @function decode
                 * @memberof openGameApi.AccountComments.Req
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {openGameApi.AccountComments.Req} Req
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Req.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.AccountComments.Req();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.data = $root.openGameApi.AccountComments.AccountCommentsData.decode(reader, reader.uint32());
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Req message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof openGameApi.AccountComments.Req
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {openGameApi.AccountComments.Req} Req
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Req.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Req message.
                 * @function verify
                 * @memberof openGameApi.AccountComments.Req
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Req.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.data != null && message.hasOwnProperty("data")) {
                        var error = $root.openGameApi.AccountComments.AccountCommentsData.verify(message.data);
                        if (error)
                            return "data." + error;
                    }
                    return null;
                };
    
                /**
                 * Creates a Req message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof openGameApi.AccountComments.Req
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {openGameApi.AccountComments.Req} Req
                 */
                Req.fromObject = function fromObject(object) {
                    if (object instanceof $root.openGameApi.AccountComments.Req)
                        return object;
                    var message = new $root.openGameApi.AccountComments.Req();
                    if (object.data != null) {
                        if (typeof object.data !== "object")
                            throw TypeError(".openGameApi.AccountComments.Req.data: object expected");
                        message.data = $root.openGameApi.AccountComments.AccountCommentsData.fromObject(object.data);
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Req message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof openGameApi.AccountComments.Req
                 * @static
                 * @param {openGameApi.AccountComments.Req} message Req
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Req.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.data = null;
                    if (message.data != null && message.hasOwnProperty("data"))
                        object.data = $root.openGameApi.AccountComments.AccountCommentsData.toObject(message.data, options);
                    return object;
                };
    
                /**
                 * Converts this Req to JSON.
                 * @function toJSON
                 * @memberof openGameApi.AccountComments.Req
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Req.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Req;
            })();
    
            AccountComments.Resp = (function() {
    
                /**
                 * Properties of a Resp.
                 * @memberof openGameApi.AccountComments
                 * @interface IResp
                 * @property {boolean|null} [ok] Resp ok
                 * @property {Array.<openGameApi.AccountComments.IAccountCommentsData>|null} [accounts] Resp accounts
                 */
    
                /**
                 * Constructs a new Resp.
                 * @memberof openGameApi.AccountComments
                 * @classdesc Represents a Resp.
                 * @implements IResp
                 * @constructor
                 * @param {openGameApi.AccountComments.IResp=} [properties] Properties to set
                 */
                function Resp(properties) {
                    this.accounts = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }
    
                /**
                 * Resp ok.
                 * @member {boolean} ok
                 * @memberof openGameApi.AccountComments.Resp
                 * @instance
                 */
                Resp.prototype.ok = false;
    
                /**
                 * Resp accounts.
                 * @member {Array.<openGameApi.AccountComments.IAccountCommentsData>} accounts
                 * @memberof openGameApi.AccountComments.Resp
                 * @instance
                 */
                Resp.prototype.accounts = $util.emptyArray;
    
                /**
                 * Creates a new Resp instance using the specified properties.
                 * @function create
                 * @memberof openGameApi.AccountComments.Resp
                 * @static
                 * @param {openGameApi.AccountComments.IResp=} [properties] Properties to set
                 * @returns {openGameApi.AccountComments.Resp} Resp instance
                 */
                Resp.create = function create(properties) {
                    return new Resp(properties);
                };
    
                /**
                 * Encodes the specified Resp message. Does not implicitly {@link openGameApi.AccountComments.Resp.verify|verify} messages.
                 * @function encode
                 * @memberof openGameApi.AccountComments.Resp
                 * @static
                 * @param {openGameApi.AccountComments.IResp} message Resp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Resp.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ok != null && Object.hasOwnProperty.call(message, "ok"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.ok);
                    if (message.accounts != null && message.accounts.length)
                        for (var i = 0; i < message.accounts.length; ++i)
                            $root.openGameApi.AccountComments.AccountCommentsData.encode(message.accounts[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };
    
                /**
                 * Encodes the specified Resp message, length delimited. Does not implicitly {@link openGameApi.AccountComments.Resp.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof openGameApi.AccountComments.Resp
                 * @static
                 * @param {openGameApi.AccountComments.IResp} message Resp message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Resp.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };
    
                /**
                 * Decodes a Resp message from the specified reader or buffer.
                 * @function decode
                 * @memberof openGameApi.AccountComments.Resp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {openGameApi.AccountComments.Resp} Resp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Resp.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.openGameApi.AccountComments.Resp();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.ok = reader.bool();
                            break;
                        case 2:
                            if (!(message.accounts && message.accounts.length))
                                message.accounts = [];
                            message.accounts.push($root.openGameApi.AccountComments.AccountCommentsData.decode(reader, reader.uint32()));
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };
    
                /**
                 * Decodes a Resp message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof openGameApi.AccountComments.Resp
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {openGameApi.AccountComments.Resp} Resp
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Resp.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };
    
                /**
                 * Verifies a Resp message.
                 * @function verify
                 * @memberof openGameApi.AccountComments.Resp
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Resp.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ok != null && message.hasOwnProperty("ok"))
                        if (typeof message.ok !== "boolean")
                            return "ok: boolean expected";
                    if (message.accounts != null && message.hasOwnProperty("accounts")) {
                        if (!Array.isArray(message.accounts))
                            return "accounts: array expected";
                        for (var i = 0; i < message.accounts.length; ++i) {
                            var error = $root.openGameApi.AccountComments.AccountCommentsData.verify(message.accounts[i]);
                            if (error)
                                return "accounts." + error;
                        }
                    }
                    return null;
                };
    
                /**
                 * Creates a Resp message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof openGameApi.AccountComments.Resp
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {openGameApi.AccountComments.Resp} Resp
                 */
                Resp.fromObject = function fromObject(object) {
                    if (object instanceof $root.openGameApi.AccountComments.Resp)
                        return object;
                    var message = new $root.openGameApi.AccountComments.Resp();
                    if (object.ok != null)
                        message.ok = Boolean(object.ok);
                    if (object.accounts) {
                        if (!Array.isArray(object.accounts))
                            throw TypeError(".openGameApi.AccountComments.Resp.accounts: array expected");
                        message.accounts = [];
                        for (var i = 0; i < object.accounts.length; ++i) {
                            if (typeof object.accounts[i] !== "object")
                                throw TypeError(".openGameApi.AccountComments.Resp.accounts: object expected");
                            message.accounts[i] = $root.openGameApi.AccountComments.AccountCommentsData.fromObject(object.accounts[i]);
                        }
                    }
                    return message;
                };
    
                /**
                 * Creates a plain object from a Resp message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof openGameApi.AccountComments.Resp
                 * @static
                 * @param {openGameApi.AccountComments.Resp} message Resp
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Resp.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.accounts = [];
                    if (options.defaults)
                        object.ok = false;
                    if (message.ok != null && message.hasOwnProperty("ok"))
                        object.ok = message.ok;
                    if (message.accounts && message.accounts.length) {
                        object.accounts = [];
                        for (var j = 0; j < message.accounts.length; ++j)
                            object.accounts[j] = $root.openGameApi.AccountComments.AccountCommentsData.toObject(message.accounts[j], options);
                    }
                    return object;
                };
    
                /**
                 * Converts this Resp to JSON.
                 * @function toJSON
                 * @memberof openGameApi.AccountComments.Resp
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Resp.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };
    
                return Resp;
            })();
    
            return AccountComments;
        })();
    
        return openGameApi;
    })();

    return $root;
});
