import React, {ReactNode} from 'react';
import {Logo} from "../../components-base/Logo";
import {UserAccount} from "./UserAccount";
import {useNavigate} from "react-router-dom";
// import {Button} from "../../components-base/Button";
// import {BUTTON_TYPES} from "../../components-base/Button/interfaces";

interface IHeaderProps {
    buttons?: ReactNode;
}
export const Header: React.FC<IHeaderProps> = ({ buttons }) => {
    const navigate = useNavigate();
    return (
        <header>
            <div className="left" onClick={() => navigate('/')}>
                <Logo />
                <h3>Fruits Management</h3>
            </div>
            <div className="right">
                {buttons}
                <UserAccount />
            </div>
        </header>
    );
}
