import React from 'react';
import {Header} from "../Header";
import {Button} from "../../components-base/Button";
import {BUTTON_TYPES} from "../../components-base/Button/interfaces";
import {PageTitle} from "../../components-base/PageTitle";
import {Input} from "../../components-base/Input";
import {Select} from "../../components-base/Select";
import {SelectDate} from "../../components-base/SelectDate";

const breadcrumbs = [
    { name: 'Game Catalog', link: '/catalog'},
    { name: 'Add Game', link: '/add-game'}
];

export const AddGame: React.FC = () => {
    return (
        <div className={'add-game-container'}>
            <Header />
            <div className={`container`}>
               <PageTitle title={'Add game'} breadcrumbs={breadcrumbs}/>
               <div className="add-game-form">
                   <Input placeholder={'Game name'} handlerChange={(value) => console.log(value)}/>
                   <Select title={'pk_job'} />
                   <Select title={'pk_job'} />
                   <Select title={'Brand'} />
                   <Select title={'Type'} />
                   <Select title={'Status'} />
                   <SelectDate title={'Release Date'} />
               </div>
                <div className="add-game-buttons">
                    <Button name={'Add game'} type={BUTTON_TYPES.PRIMARY} />
                    <Button name={'Cancel'} type={BUTTON_TYPES.DEFAULT} />
                </div>
            </div>
        </div>
    );
};