import React, {ReactNode} from 'react';
import {IconArrowRight} from "../Icons/IconArrowRight";
import {IconArrowLeft} from "../Icons/IconArrowLeft";

export const DEFAULT_PAGE_SIZE = 30;

interface IPaginationProps {
    page: number;
    onChange: (page: number) => void;
    count: number;
}

export const Pagination: React.FC<IPaginationProps> = ({ page, onChange, count }) => {
    const pages = Math.ceil(count / DEFAULT_PAGE_SIZE);
    const renderItems = () => {
      const items: ReactNode[] = [];
      for (let i = page - 3; i <= page + 3; i++) {
          if (i > 1 && i < pages) {
              items.push(<div className={`pagination-item ${i === page ? `active` : ''}`} onClick={() => onChange(i)}>{i}</div>)
          }
      }
      return items;
    };
    return pages > 0
        ? (
        <div className={'pagination-container'}>
            <div className="active-page">
                PAGE {page} OF {pages}
            </div>
            <div className="pagination">
                <div className="pagination-item previous" onClick={() => onChange(page - 1)}>
                    <IconArrowLeft/>
                </div>
                <div className={`pagination-item ${page === 1 ? 'active' : ''}`} onClick={() => onChange(1)}>1</div>
                {page > 5 && <div className="pagination-item separator">...</div>}
                {renderItems()}
                {page < pages-5 && <div className="pagination-item separator">...</div>}
                {pages > 0 ? <div className={`pagination-item ${page === pages ? 'active' : ''}`} onClick={() => onChange(pages)}>{pages}</div> : '' }
                <div className="pagination-item next" onClick={() => onChange(page + 1 <= pages ? page + 1 : pages)}>
                    <IconArrowRight/>
                </div>
            </div>
        </div>
    ) : <></>;
};

