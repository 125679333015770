import React, {ReactNode, lazy, useMemo, useState} from 'react';
import {TableFilter} from "../../../components-base/TableFilter";
import {IconSortTop} from "../../../components-base/Icons/IconSortTop";
import {IconSortBottom} from "../../../components-base/Icons/IconSortBottom";
import {SearchInput} from "../../../components-base/SearchInput";
import {ICatalogTableColumn, IProvidersGame, ITableFilterOption, ITableFilterValue} from "../../../interfaces";
import {PageTitle} from "../../../components-base/PageTitle";
import {CatalogAppliedFilters} from "../CatalogAppliedFilters";
import {FILTERS} from "../../FilterPanel";
import {DEFAULT_PAGE_SIZE, Pagination} from "../../../components-base/Pagination";

const Image = lazy(() => import('../../../components-base/Image'));

interface ICatalogTableProps {
    filterPanelActive: boolean;
    setFilterPanelActive: (isActive: boolean) => void;
    resource: IProvidersGame[];
    filters: ITableFilterValue;
    changeFilter: (name: string, value: any) => void,
    activePage: number,
    setActivePage: (page: number) => void;
    types: ITableFilterOption[];
    brands: ITableFilterOption[];
}
       
const numberSortHandler = (a: any, b: any, type: 'ASC' | 'DESC') => {
    return type === 'ASC' ? a - b : b - a;
};

const stringSortHandler = (a: any, b: any, type: 'ASC' | 'DESC') => {
    return type === 'ASC' ? a.localeCompare(b) : b.localeCompare(a);
};

const sortItems = (typeSort: 'STRING' | 'NUMBER') => typeSort === 'STRING'
    ? stringSortHandler
    : numberSortHandler;

const getColumns = (types: ITableFilterOption[], brands: ITableFilterOption[], filters: ITableFilterValue, changeFilter: (name: string, value: any) => void): ICatalogTableColumn[] => [
    {
        id: 'id',
        title: '#',
        sorter: 'NUMBER',
        // filter: <SearchInput value={filters[FILTERS.ID][0]} handlerChange={(value) => changeFilter(FILTERS.ID, [value])} />,
    },
    {
        id: 'name',
        title: 'Game Name',
        filter: <SearchInput value={filters[FILTERS.NAME][0]} handlerChange={(value) => changeFilter(FILTERS.NAME, [value])} />,
        sorter: 'STRING',
    },
    {
        id: 'icon',
        title: 'Icon',
        render: (item) => (<Image src={item.icon} alt={item.name}/>),
    },
    // {
    //     id: 'math',
    //     title: 'Math',
    //     filter: <TableFilter options={optionsTestString}/>,
    // },
    {
        id: 'brand',
        title: 'Brand',
        filter: <TableFilter options={brands} values={filters[FILTERS.BRANDS]} handlerChange={(values: string[]) => changeFilter(FILTERS.BRANDS, values)}/>,
        sorter: 'STRING',
    },
    {
        id: 'tags',
        title: 'Types',
        filter: <TableFilter options={types} values={filters[FILTERS.TAGS]} handlerChange={(values: string[]) => changeFilter(FILTERS.TAGS, values)}/>,
        render: (item) => (
            <span>{item.tags.join(', ')}</span>
        ),
        sorter: 'STRING',
    },
    // {
    //     id: 'lock_it_link',
    //     title: 'Lock it link',
    //     filter: <TableFilter options={optionsTestString}/>,
    //     render: (item) => (
    //         <span>{item.lock_it_link ? 'Yes' : '-'}</span>
    //     ),
    // },
    // {
    //     id: 'graphic',
    //     title: 'Graphic',
    //     filter: <TableFilter options={optionsTestString}/>,
    //     render: (item) => (
    //         <Link to={'/catalog'}>{item.graphic}</Link>
    //     ),
    // },
    // {
    //     id: 'status',
    //     title: 'Status',
    //     filter: <TableFilter options={optionsTestBadges}/>,
    //     render: (item) => (
    //         <Badge type={item.status as BADGE_TYPES}  label={'Released'} withDropdown/>
    //     )
    // },
    // {
    //     id: 'release_date',
    //     title: 'Release date',
    //     filter: <DateFilter />,
    // },
];

const calculatePagination = (activePage: number): { min: number, max: number } => ({ min: (activePage - 1) * DEFAULT_PAGE_SIZE, max: activePage * DEFAULT_PAGE_SIZE });

const filterData = (resource: IProvidersGame[], filters: ITableFilterValue): IProvidersGame[] => {
    let filteredData: IProvidersGame[] = [...resource];

    if (filters[FILTERS.TAGS]?.length > 0) {
        filteredData = filteredData.filter((item) => item?.tags?.some((tag) => filters[FILTERS.TAGS].includes(tag)));
    }

    if (filters[FILTERS.BRANDS]?.length > 0) {
        filteredData = filteredData.filter((item) => filters[FILTERS.BRANDS].includes(item.brand));
    }

    if (filters[FILTERS.NAME]?.length > 0) {
        filteredData = filteredData.filter((item) => item?.name?.indexOf(filters[FILTERS.NAME][0]) !== -1);
    }

    if (filters[FILTERS.ID]?.length > 0) {
        filteredData = filteredData.filter((item) => String(item?.id)?.indexOf(filters[FILTERS.ID][0]) !== -1);
    }

    return filteredData;
};

export const CatalogTable: React.FC<ICatalogTableProps> = (
    {
        filterPanelActive,
        setFilterPanelActive,
        resource,
        filters,
        changeFilter,
        activePage,
        setActivePage,
        types,
        brands,
    }) => {
    const columns = getColumns(types, brands, filters, changeFilter);
    const filteredData = useMemo(() => filterData(resource, filters), [resource, filters]);
    const [sortHandler, setSortHandler] = useState<{ column: string, type: 'ASC' | 'DESC' | null, typeSort: 'STRING' | 'NUMBER' | undefined}>({ column: '', type: null, typeSort: undefined });
    const data: ReactNode[] = useMemo(() => {
        const { min, max } = calculatePagination(activePage);
        const paginatedData = filteredData.sort((a: any, b: any) => {
                                                if (!sortHandler.typeSort) return 1;
                                                const handlerSort = sortItems(sortHandler.typeSort);
                                                return sortHandler.column === 'tags'
                                                    ? handlerSort(a[sortHandler.column].join(', '), b[sortHandler.column].join(', '), sortHandler.type || 'ASC')
                                                    : handlerSort(a[sortHandler.column], b[sortHandler.column], sortHandler.type || 'ASC');
                                            })
                                           .filter((item, index) => (index + 1) > min && (index + 1) <= max );
        return paginatedData.map((item, index) => (
            <tr key={item.id + String(item?.name) + index + item.brand}>
                {columns.map((column) => (
                    <td key={column.id + item.id + index + item.brand}>{column.render ? column.render(item, index) : item[column.id as keyof IProvidersGame]}</td>
                ))}
            </tr>
        ));
    }, [activePage, columns, filteredData, sortHandler]);
    const pagination = <Pagination page={activePage} onChange={(page: number) => page >= 1 && setActivePage(page)} count={filteredData.length}/>;

    const onSetSortHandler = (columnId: string, typeSort: 'STRING' | 'NUMBER' | undefined) => {
        const { column, type } = sortHandler;
        if (column === columnId) setSortHandler({ type: type ? (type === 'ASC' ? 'DESC' : null) : 'ASC', column: columnId, typeSort });
        else setSortHandler({ type: 'ASC', column: columnId, typeSort });
    }

    return (
        <div className={`catalog-table-container transition ${filterPanelActive}`}>
            <PageTitle title={'Game Catalog'} subtitle={filteredData.length > 0 ? `(${filteredData.length} items)` : ''} extra={pagination}/>
            <CatalogAppliedFilters filterPanelActive={filterPanelActive} setFilterPanelActive={setFilterPanelActive} filters={filters} changeFilter={changeFilter}/>
            <table className={'catalog-table'} cellSpacing={0} cellPadding={0}>
                <thead>
                <tr>
                    {columns.map((item, index) => (
                        <th key={item.id + item.title + index}>
                            <div className="table-columns-title">
                                {item.title}
                                {item.sorter && (
                                    <div className="table-sort-arrows" onClick={() => onSetSortHandler(item.id, item.sorter)}>
                                        <IconSortTop isActive={sortHandler.column === item.id && sortHandler.type === 'ASC'}/>
                                        <IconSortBottom  isActive={sortHandler.column === item.id && sortHandler.type === 'DESC'}/>
                                    </div>
                                )}
                            </div>
                        </th>
                    ))}
                </tr>
                <tr className={'table-filter-row'}>
                    {columns.map((item) => (
                        <th key={'filter_' + item.id + item.title}>
                            {item.filter}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {data.length > 0 ? data : (
                    <tr>
                        <td colSpan={columns.length} style={{ textAlign: 'center' }}>No matches were found for this query</td>
                    </tr>
                )}
                </tbody>
            </table>
            <div className="catalog-table-footer">
                {pagination}
            </div>
        </div>
    );
};

// const optionsTestString: ITableFilterOption[]  = [
//     { id: 1, label: 'pk_job'},
//     { id: 2, label: 'pk_jp'},
//     { id: 3, label: 'rg_bs'},
//     { id: 4, label: 'rg_sc'},
// ];
//
// const optionsTestBadges: ITableFilterOption[]  = [
//     { id: 1, label: <Badge type={BADGE_TYPES.RELEASED} label={'Released'} />},
//     { id: 2, label: <Badge type={BADGE_TYPES.READY} label={'Ready'} />},
//     { id: 3, label: <Badge type={BADGE_TYPES.TEST} label={'Test'} />},
//     { id: 4, label: <Badge type={BADGE_TYPES.IN_PROGRESS} label={'In progress'} />},
//     { id: 5, label: <Badge type={BADGE_TYPES.REMOVED} label={'Removed'} />},
//     { id: 6, label: <Badge type={BADGE_TYPES.BACKLOG} label={'Backlog'} />},
// ];

// const resource: Record<string, any>[] = [
//     {
//         id: 1,
//         name: 'Video Poker Jacks or Better',
//         slug: 'pk_job',
//         match: 'pk_job',
//         brand: 'gmz',
//         type: 'poker',
//         lock_it_link: false,
//         graphic: 'CupidWings',
//         status: 'released',
//         release_date: '19.09.2019',
//     },
//     {
//         id: 2,
//         name: 'Video Poker Jacks or Better',
//         slug: 'pk_job',
//         match: 'pk_job',
//         brand: 'gmz',
//         type: 'poker',
//         lock_it_link: false,
//         graphic: 'CupidWings',
//         status: 'released',
//         release_date: '19.09.2019',
//     },
//     {
//         id: 3,
//         name: 'Video Poker Jacks or Better',
//         slug: 'pk_job',
//         match: 'pk_job',
//         brand: 'gmz',
//         type: 'poker',
//         lock_it_link: false,
//         graphic: 'CupidWings',
//         status: 'released',
//         release_date: '19.09.2019',
//     },
//     {
//         id: 4,
//         name: 'Video Poker Jacks or Better',
//         slug: 'pk_job',
//         match: 'pk_job',
//         brand: 'gmz',
//         type: 'poker',
//         lock_it_link: true,
//         graphic: 'CupidWings',
//         status: 'released',
//         release_date: '19.09.2019',
//     },
//     {
//         id: 5,
//         name: 'Video Poker Jacks or Better',
//         slug: 'pk_job',
//         match: 'pk_job',
//         brand: 'gmz',
//         type: 'poker',
//         lock_it_link: false,
//         graphic: 'CupidWings',
//         status: 'released',
//         release_date: '19.09.2019',
//     },
//     {
//         id: 6,
//         name: 'Video Poker Jacks or Better',
//         slug: 'pk_job',
//         match: 'pk_job',
//         brand: 'gmz',
//         type: 'poker',
//         lock_it_link: false,
//         graphic: 'CupidWings',
//         status: 'released',
//         release_date: '19.09.2019',
//     },
//     {
//         id: 7,
//         name: 'Video Poker Jacks or Better',
//         slug: 'pk_job',
//         match: 'pk_job',
//         brand: 'gmz',
//         type: 'poker',
//         lock_it_link: false,
//         graphic: 'CupidWings',
//         status: 'released',
//         release_date: '19.09.2019',
//     },
//     {
//         id: 8,
//         name: 'Video Poker Jacks or Better',
//         slug: 'pk_job',
//         match: 'pk_job',
//         brand: 'gmz',
//         type: 'poker',
//         lock_it_link: false,
//         graphic: 'CupidWings',
//         status: 'released',
//         release_date: '19.09.2019',
//     },
//     {
//         id: 10,
//         name: 'Video Poker Jacks or Better',
//         slug: 'pk_job',
//         match: 'pk_job',
//         brand: 'gmz',
//         type: 'poker',
//         lock_it_link: false,
//         graphic: 'CupidWings',
//         status: 'released',
//         release_date: '19.09.2019',
//     },{
//         id: 15,
//         name: 'Video Poker Jacks or Better',
//         slug: 'pk_job',
//         match: 'pk_job',
//         brand: 'gmz',
//         type: 'poker',
//         lock_it_link: false,
//         graphic: 'CupidWings',
//         status: 'released',
//         release_date: '19.09.2019',
//     }
//
// ];
