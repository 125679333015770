import {openGameApi} from "./proto/proto";
// import IGame = openGameApi.IGame;
import {ICatalogGame, IProvidersGame} from "../interfaces";

const { ListProvidersData } = openGameApi;

export const getGames = async (): Promise<IProvidersGame[]> => {
    const request = new ListProvidersData.Req({
        // ...fields
    });
    const body = ListProvidersData.Req.encode(request).finish();
    const response = await fetch('https://api.lostgame.tech/6cf2b5b4-e515-4b48-85cc-36f3bbce160e/open-api-games/v1/providers', {
        body,
        headers: {
            Cache: "no-cache",
            "Content-Type": "application/x-protobuf",
        },
        method: "POST",
    });

    const buffer = new Uint8Array(await response.arrayBuffer());
    const providers = openGameApi.ListProvidersData.Resp.decode(buffer);

    console.log(providers);

    let games: ICatalogGame[] = [];
    providers.providers.forEach((provider) => {
        const accounts = provider?.accounts;
        if (accounts) {
            accounts.forEach((account) => {
                const providerGames = account.games?.map((game) => ({ ...game, providerName: provider.providerName}));
                if(providerGames) {
                    games = [...games, ...providerGames as ICatalogGame[]]
                }
            });
        }
    });

    return games.map((game, index) => ({
        id: String(index + 1),
        name: game?.defaultName,
        brand: game?.providerName,
        tags: game?.tags,
        icon: game?.icons?.link_40x40,
    }));
};
