import React from 'react';
import logo from '../../assets/images/logo.svg';

interface ILogoProps {
    width?: number;
}

export const Logo: React.FC<ILogoProps> = ({ width }) => {
    return (
        <div className={'logo-container'}>
            <img src={logo} alt={'Fruits Management'} width={width || 32}/>
        </div>
    );
}