import React from 'react';
import {IBreadcrumbsItem} from "../../interfaces";

interface IBreadcrumbsProps {
    items: IBreadcrumbsItem[];
}

export const Breadcrumbs: React.FC<IBreadcrumbsProps> = () => {
    return (
        <div className="breadcrumbs">
            <div className="breadcrumbs-item">
                Game Catalog
            </div>
            {/*<div className="breadcrumbs-separator">*/}
            {/*    /*/}
            {/*</div>*/}
            <div className="breadcrumbs-item active">
                Add game
            </div>
        </div>
    );
}