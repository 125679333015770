import React from 'react';

interface IIconArrowDownProps {
    color?: string;
    style?: Record<string, string>
}

export const IconArrowDown: React.FC<IIconArrowDownProps> = ({ color, style }) => {
    return (
        <svg className={'app-icon'} width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path
                d="M4.93934 6.43934L1.06066 2.56066C0.115713 1.61571 0.784964 0 2.12132 0H9.87868C11.215 0 11.8843 1.61571 10.9393 2.56066L7.06066 6.43934C6.47487 7.02513 5.52513 7.02513 4.93934 6.43934Z"
                fill={`#${color || '9D9D9D'}`}/>
        </svg>
    );
};
