import React from "react";
import {IconFilterDelete} from "../../../components-base/Icons/IconFilterDelete";

interface IAppliedFilterProps {
    name: string;
    value: string;
    handlerClick: () => void;
}

export const AppliedFilter: React.FC<IAppliedFilterProps> = ({ name, value, handlerClick }) => {
    return (
        <div className={'applied-filter-item'}>
                <span>
                    <b>{name}</b> is <b>{value}</b>
                </span>
            <IconFilterDelete handlerClick={() => handlerClick()}/>
        </div>
    );
};