import React from 'react';
import { IconArrowDown } from '../../components-base/Icons/IconArrowDown';

export const UserAccount: React.FC = () => {
    return (
        <div className={'user-account'}>
            <span>tailor.wei@gmail.com</span>
            <IconArrowDown/>
        </div>
    );
};
