import React from 'react';
import {IconCalendar} from "../Icons/IconCalendar";

interface ISelectDateProps {
    title: string;
}

export const SelectDate: React.FC<ISelectDateProps> = ({ title }) => {
    return (
        <div className="app-select">
            <div className="app-select-box">
                <span>{title}</span>
                <IconCalendar/>
            </div>
        </div>
    );
}