import React from 'react';

export const IconFilter: React.FC = () => {
    return (
        <svg className={'app-icon'} width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="12" height="1" rx="0.5" fill="#3C3C3C"/>
            <rect x="2" y="3" width="8" height="1" rx="0.5" fill="#3C3C3C"/>
            <rect x="4" y="6" width="4" height="1" rx="0.5" fill="#3C3C3C"/>
        </svg>
    );
};
