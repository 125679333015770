import React, {ReactNode} from 'react';

interface ICheckboxProps {
    label?: string | ReactNode;
    name?: string;
    handlerChange: () => void;
    checked?: boolean;
}
export const Checkbox: React.FC<ICheckboxProps> = ({ label, checked, handlerChange }) => {
    return (
        <span className={'app-checkbox'} onClick={handlerChange}>
            <input type="checkbox" name={'sdfsdf'} checked={checked} />
            <span className={'app-checkbox-pseudo'}> </span>
            {label && <span className={'app-checkbox-title'}>{label}</span>}
        </span>
    );
}