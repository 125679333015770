import React from 'react';
import {IconSearch} from "../Icons/IconSearch";

interface ISearchInputProps {
    placeholder?: string
    value?: string
    handlerChange: (value: string) => void;
}

export const SearchInput: React.FC<ISearchInputProps> = ({ placeholder = 'Search', value, handlerChange }) => {
    debugger
    return (
        <div className={'search-input-container'}>
            <IconSearch />
            <input placeholder={placeholder} value={value || ''} defaultValue={''} onChange={(event) => handlerChange(event?.currentTarget?.value || '') }/>
        </div>
    );
}