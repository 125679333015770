import React, {ReactNode} from 'react';
import {IBreadcrumbsItem} from "../../interfaces";
import {Breadcrumbs} from "../Breadcrumbs";

interface IPageTitleProps {
    title: string;
    breadcrumbs?: IBreadcrumbsItem[];
    subtitle?: string;
    extra?: ReactNode;
}
export const PageTitle: React.FC<IPageTitleProps> = ({ title, breadcrumbs, subtitle, extra }) => {
    return (
        <div className={'page-title-container'}>
            <div className="page-title-inner">
                {breadcrumbs && <Breadcrumbs items={breadcrumbs}/>}
                <h1>{title} {subtitle && <small className={'page-title-subtitle'}>{subtitle}</small>}</h1>
            </div>
            <div className="page-title-inner">
                {extra}
            </div>
        </div>
    );
}