import React from 'react';
import {Route, Routes} from "react-router-dom";
import {AddGame} from "./AddGame";
import {Catalog} from "./Catalog";
import {Login} from "./Login";

const isAuth = localStorage.getItem('storage-auth-status') === 'auth';

function App() {
  return (
    <main className="main-wrapper">
        {isAuth ? (
            <Routes>
                <Route path="/add-game" element={<AddGame />} />
                <Route path="/catalog" element={<Catalog />} />
                <Route path="/" element={<Catalog />} />
            </Routes>
        ) : <Login />}
    </main>
  );
}

export default App;

// TODO: Filter Panel - закривати по будь-якому кліку зовні
// TODO: Filter Panel - Clear - при кліку не розгортати/згортати сам фільтр
// TODO: скроли під Firefox пофіксити