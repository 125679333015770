import React from 'react';

interface IIconSortTopProps {
    opacity?: number;
    onClick?: () => void;
    isActive?: boolean;
}

export const IconSortTop: React.FC<IIconSortTopProps> = ({ opacity, onClick, isActive }) => {
    return (
        <svg className={'app-icon'} width="11" height="5" viewBox="0 0 11 5" fill="none"
             xmlns="http://www.w3.org/2000/svg" onClick={onClick}
        >
            <g opacity={opacity || '0.5'}>
                <path
                    d="M6.56429 0.707108L9.15007 3.29289C9.78004 3.92286 9.33387 5 8.44296 5L3.27139 5C2.38049 5 1.93432 3.92286 2.56429 3.29289L5.15007 0.707107C5.5406 0.316583 6.17376 0.316584 6.56429 0.707108Z"
                    fill={isActive ? '#3972E1' : '#3C3C3C'}/>
            </g>
        </svg>
    );
};
