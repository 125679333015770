import React, {useEffect, useState} from 'react';
import {Header} from "../Header";
import {BUTTON_TYPES} from "../../components-base/Button/interfaces";
import {Button} from "../../components-base/Button";
import {FilterPanel, FILTERS} from "../FilterPanel";
import {CatalogTable} from "./CatalogTable";
import {getGames} from "../../api/api";
import {IProvidersGame, ITableFilterOption, ITableFilterValue} from "../../interfaces";

const getOptions = (resource: IProvidersGame[], key: keyof IProvidersGame, isArray?: boolean): ITableFilterOption[] => {
    const data = resource.reduce(
        (
            acc,
            currentValue,
        ) => {
            const values: string[] = (isArray ? currentValue[key] : [currentValue[key]]) as string[] || [];
            return [...acc, ...values]
        }, [] as string[]);
    return Array.from(new Set(data)).map((option) => ({ id: option, label: option }));
};

export const Catalog: React.FC = () => {
    const [activePage, setActivePage] = useState<number>(1);
    const [filterPanelActive, setFilterPanelActive] = useState<boolean>(true);
    const [games, setGames] = useState<IProvidersGame[]>([]);
    const [filters, setFilters] = useState<ITableFilterValue>({
        [FILTERS.TAGS]: [],
        [FILTERS.BRANDS]: [],
        [FILTERS.NAME]: [''],
        [FILTERS.ID]: [''],
    });
    const gameTypes = getOptions(games, 'tags', true);
    const gameBrands = getOptions(games, 'brand');

    const changeFilter = (name: string, value: any) => {
        setActivePage(1);
        setFilters({...filters, [name]: value });
    };

    useEffect(() => {
        getGames().then((res) => setGames(res));
    }, []);

    return (
        <div className={'catalog-container'}>
            <Header buttons={<Button name={'Add game'} type={BUTTON_TYPES.ADD}/>}/>
            <div className={`container ${filterPanelActive && 'open-filters'}`}>
                <FilterPanel isActive={filterPanelActive}
                             setIsActive={setFilterPanelActive}
                             filters={filters}
                             changeFilter={changeFilter}
                             gameTypes={gameTypes}
                             gameBrands={gameBrands}
                />
                <CatalogTable filterPanelActive={filterPanelActive}
                              setFilterPanelActive={setFilterPanelActive}
                              resource={games}
                              filters={filters}
                              activePage={activePage}
                              setActivePage={setActivePage}
                              changeFilter={changeFilter}
                              types={gameTypes}
                              brands={gameBrands}
                />
            </div>
        </div>
    );
};