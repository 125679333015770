import React from 'react';

interface IIconSortBottomProps {
    opacity?: number;
    onClick?: () => void;
    isActive?: boolean;
}

export const IconSortBottom: React.FC<IIconSortBottomProps> = ({ opacity, onClick, isActive }) => {
    return (
        <svg className={'app-icon '} width="11" height="5" viewBox="0 0 11 5" fill="none"
             xmlns="http://www.w3.org/2000/svg" onClick={onClick}
        >
            <g opacity={opacity || '0.5'}>
                <path
                    d="M6.56428 4.29289L9.15007 1.70711C9.78004 1.07714 9.33387 -1.26163e-07 8.44296 -1.99949e-07L3.27139 -6.28267e-07C2.38049 -7.02053e-07 1.93432 1.07714 2.56429 1.70711L5.15007 4.29289C5.54059 4.68342 6.17376 4.68342 6.56428 4.29289Z"
                    fill={isActive ? '#3972E1' : '#3C3C3C'}/>
            </g>
        </svg>
    );
};
