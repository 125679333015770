import React, {ReactNode} from 'react';

interface IInputProps {
    placeholder?: string;
    width?: number;
    className?: string;
    value?: string;
    handlerChange: (value: string) => void;
    icon?: ReactNode;
}

export const Input: React.FC<IInputProps> = (
    {
        width,
        placeholder,
        className,
        value,
        handlerChange,
        icon
    }) => {
    return (
        <div className={`form-item ${icon && 'with-icon'}`}>
            {icon}
            <input type={'text'}
                   value={value}
                   className={className}
                   name={''}
                   onChange={(event) => handlerChange(event.currentTarget.value)}
                   placeholder={placeholder}
            />
        </div>
    );
}