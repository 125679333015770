import React, {ReactNode} from 'react';
import {AppliedFilter} from "./AppliedFilter";
import {FilterPanelButton} from "./FilterPanelButton";
import {ITableFilterValue} from "../../../interfaces";

interface ICatalogAppliedFiltersProps {
    filterPanelActive: boolean;
    setFilterPanelActive: (isActive: boolean) => void;
    changeFilter: (name: string, value: any) => void,
    filters: ITableFilterValue;
}

export const CatalogAppliedFilters: React.FC<ICatalogAppliedFiltersProps> = ({ filterPanelActive, setFilterPanelActive, filters, changeFilter }) => {
    const appliedFilters: ReactNode[] = Object.entries(filters).reduce((acc, element) => {
        const [key, values] = element;
        return [...acc, ...values.filter((val) => val !== '').map((val) => val && <AppliedFilter key={key + val} name={key} value={val} handlerClick={() => changeFilter(key,values.filter((e) => e !== val))}/>)]
    }, [] as ReactNode[]);

    return (
        <div className={'catalog-active-filters-container'}>
            <FilterPanelButton isActive={filterPanelActive} setIsActive={setFilterPanelActive} count={appliedFilters.length}/>
            {appliedFilters}
        </div>
    );
};
