import React from "react";
import {IconFilter} from "../../../components-base/Icons/IconFilter";

interface IFilterPanelButton {
    isActive: boolean;
    setIsActive: (isActive: boolean) => void;
    count: number;
}

export const FilterPanelButton: React.FC<IFilterPanelButton> = ({ isActive, setIsActive, count }) => {
    return (
        <div className={`filters-panel-button ${isActive && 'active'}`} onClick={() => setIsActive(!isActive)} id={'filter-panel-open-button'}>
            <IconFilter/>
            <div className={'filters-panel-button-title'}>Filter</div>
            <div className={'count-box'}>{count}</div>
        </div>
    );
}