import {ReactNode} from "react";
import {openGameApi} from "./api/proto/proto";

type IGame = openGameApi.IGame;

export enum BADGE_TYPES {
    RELEASED = 'released',
    READY = 'ready',
    IN_PROGRESS = 'in_progress',
    TEST = 'test',
    REMOVED = 'removed',
    BACKLOG = 'backlog',
}

export enum FILTER_PANEL_ITEM_TYPES {
    LIST = 'list',
    DATE = 'date',
}

export interface ITableFilterOption {
    id: string,
    label: string | ReactNode;
}

export interface IBreadcrumbsItem {
    name: string;
    link: string
}

export interface ICatalogGame extends IGame {
    providerName?: string;
}

export interface ICatalogTableColumn {
    id: string;
    title: string;
    filter?: ReactNode;
    sorter?: 'NUMBER' | 'STRING';
    render?: (item: Record<string, any>, index: number) => ReactNode;
}

export interface IProvidersGame {
    name: string | null | undefined;
    icon: string | null | undefined;
    id: string | null | undefined;
    brand: string | undefined;
    tags: string[] | null | undefined
}

export type ITableFilterValue = Record<string, any[]>;
